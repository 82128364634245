import { ClusterDetailsTab } from 'contexts/ClusterDetailsTabContext';
import { ClusterDetailsFilterView } from 'utils/filters';

export enum ClusterViewsTooltip {
  EXAMPLE = 'Show data from your environment',
  REAL = 'Show example data',
}

export type ClusterViews = keyof typeof ClusterViewsTooltip;

type ClusterFiltersType = 'status' | 'providers' | 'environments' | 'versions';

export type ClusterFilters = {
  [key in ClusterFiltersType]?: string[];
};

export enum ClustersViewLayout {
  GRID = 'grid',
  LIST = 'list',
}

export type ClustersViewLayoutValues =
  ClustersViewLayout[keyof ClustersViewLayout];

export type NeedAttentionCountByCategory = {
  defects_count: number;
  misconfigurations_count: number;
  system_requirements_count: number;
  deprecations_count: number;
  unsupported_versions_count: number;
  version_incompatibilities_count: number;
  totalNeedAttention: number;
};

export type ClusterTabsType = {
  key: ClusterDetailsTab;
  id: string;
  title: JSX.Element;
  trackerEvent: string;
  content: JSX.Element | undefined;
  icon: string;
}[];

export type CATEGORY_DETAILS_TABS_TYPE = {
  key: string;
  id: ClusterDetailsFilterView;
  title: string;
  trackerEvent: string;
}[];
