'use client';

import * as Sentry from '@sentry/react';
import * as SubframeCore from '@subframe/core';
import { Icon } from '@subframe/core';
import { useListIntegrations } from 'api/frontend';
import { IntegrationType } from 'api/models';
import BreadCrumbsHeader from 'components/BreadCrumbsHeader';
import Page from 'components/Page';
import { RouterLink } from 'components/RouterLink';
import { AtomicTooltip } from 'components/design-system';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import useLocalStorage from 'hooks/useLocalStorage';
import useUserAccountState from 'hooks/useUserAccountState';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import {
  Button,
  CtaCard,
  IconWithBackground,
  Loader,
  StepBase,
} from 'subframe/index';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { getInstallState, getOrCreateIntegration } from '../utils';
import SuccessScreen from './SuccessScreen';

function IntegrationSlack() {
  const { account } = useUserAccountState();
  const { enqueueSnackbar } = useSnackbar();
  const [showMarkAsDone, setShowMarkAsDone] = useLocalStorage<boolean>(
    'integration-slack-step-2',
    true,
  );

  const [installRedirectLoading, setInstallRedirectLoading] = useState(false);

  const path = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/integrations/',
  );
  const { logEvent } = AnalyticsEventLogger();

  const {
    data: integrationList,
    isLoading: integrationListLoading,
    error: integrationListError,
    isValidating: integrationListIsValidating,
  } = useListIntegrations({
    request: {
      headers: { authorization: 'Bearer ' + account?.token },
    },
    swr: { revalidateOnFocus: true },
  });

  const slackIntegration =
    integrationList?.data?.filter(
      (value) =>
        IntegrationType.slack_app === value.integration_type &&
        value.slack_app_configuration !== undefined,
    ) || [];

  const isInstalled = slackIntegration
    ? getInstallState(slackIntegration)
    : false;

  if (!showMarkAsDone && !isInstalled && !integrationListLoading)
    setShowMarkAsDone(true);

  if (integrationListError) {
    Sentry.captureException(integrationListError);
  }

  const openRedirectLink = async () => {
    setInstallRedirectLoading(true);

    const redirectLink = await getOrCreateIntegration(
      account,
      'slackApp',
      IntegrationType.slack_app,
      `${import.meta.env.VITE_SLACK_APP_URL}/slack/install`,
      enqueueSnackbar,
    );
    if (redirectLink !== undefined) {
      window.open(redirectLink, '_blank', 'popup=yes');
    }
    setInstallRedirectLoading(false);
  };

  return (
    <Page title="Slack">
      <div className="flex h-full flex-col items-start gap-4 pt-8 pb-0 px-3 bg-default-background ml-8">
        <div className="flex w-full flex-col items-start">
          <div className="flex w-full flex-col items-start gap-1">
            <BreadCrumbsHeader
              rootPath={path}
              rootName={'Integrations'}
            ></BreadCrumbsHeader>
            {integrationListLoading ? (
              <div className="flex self-stretch flex-col items-center gap-2">
                <Loader size={'large'} />
              </div>
            ) : (
              <>
                {isInstalled && !showMarkAsDone ? (
                  <SuccessScreen lastUpdated={slackIntegration[0]?.updated} />
                ) : (
                  <div className="flex w-full flex-col items-center justify-center gap-6">
                    <div className="flex w-full flex-col items-center justify-center gap-6">
                      <div className="flex w-full flex-col items-start justify-center gap-4">
                        <div className="flex w-full items-center gap-2 px-0 py-1">
                          <Icon name="FeatherSlack" />
                          <span className="text-subheader text-default-font">
                            Install Chkk App in your Slack Workspace
                          </span>
                        </div>
                        <StepBase
                          stepTitle="Request permission and install Chkk Slack App"
                          stepBody=""
                          variant={isInstalled ? 'success' : 'default'}
                          stepNumber="1"
                          defaultOpen={true}
                        >
                          <span className="text-body text-default-font">
                            Request permission from your Slack Workspace Admin
                            to install Chkk Slack App.
                          </span>
                          <span className="text-body text-default-font">
                            When your Slack Admin approves the app, it will be
                            added to your workspace’s app directory. You will
                            also receive a confirmation message from Slackbot
                            confirming that your request has been approved.
                          </span>
                          <span className="text-body text-default-font">
                            Click on the button below to add the Chkk App to
                            your Slack workspace. Make sure you are logged into
                            the correct workspace.
                          </span>
                          <Button
                            className="justify-self-start"
                            disabled={
                              !!isInstalled ||
                              installRedirectLoading ||
                              integrationListLoading ||
                              integrationListIsValidating
                            }
                            onClick={() => {
                              openRedirectLink();
                              logEvent(
                                'integrations-slack-app-install-clicked',
                              );
                            }}
                            loading={
                              installRedirectLoading ||
                              integrationListLoading ||
                              integrationListIsValidating
                            }
                          >
                            Install Slack App
                          </Button>
                          {installRedirectLoading && (
                            <div className="flex w-full items-center gap-2">
                              <span className="text-body-bold text-default-font">
                                Setting up the Slack App
                              </span>
                              <Loader />
                              <span className="text-label text-subtext-color">
                                This step can take a couple of mins
                              </span>
                            </div>
                          )}
                          {isInstalled && (
                            <div className="flex w-full items-center gap-2">
                              <SubframeCore.Icon
                                className="text-body-bold text-success-700"
                                name="FeatherCheck"
                              />
                              <span className="text-body-bold text-default-font">
                                Slack App Installed successfully
                              </span>
                            </div>
                          )}
                          {integrationListError && (
                            <div className="flex w-full items-center gap-2">
                              <SubframeCore.Icon
                                className="text-body-bold text-error-700"
                                name="FeatherAlertTriangle"
                              />
                              <span className="text-body-bold text-default-font">
                                Something went wrong
                              </span>
                              <span className="text-label text-subtext-color">
                                Slack integration failed. Retry and escalate to
                                Chkk Support if the problem persists.
                              </span>
                            </div>
                          )}
                        </StepBase>

                        <StepBase
                          stepTitle="Add App to a Slack channel "
                          stepBody=""
                          variant={!showMarkAsDone ? 'success' : 'default'}
                          stepNumber="2"
                          lastStep={true}
                          defaultOpen={true}
                          actionButtons={
                            <AtomicTooltip
                              tooltipContent={
                                isInstalled
                                  ? undefined
                                  : 'Complete Step 1 first'
                              }
                            >
                              {showMarkAsDone ? (
                                <Button
                                  disabled={!isInstalled}
                                  variant="brand-secondary"
                                  size="medium"
                                  icon="FeatherCornerDownRight"
                                  onClick={() => setShowMarkAsDone(false)}
                                >
                                  Mark as Done
                                </Button>
                              ) : (
                                <Button
                                  disabled={!isInstalled}
                                  variant="brand-secondary"
                                  size="medium"
                                  icon="FeatherRedo"
                                  onClick={() => setShowMarkAsDone(true)}
                                >
                                  Redo
                                </Button>
                              )}
                            </AtomicTooltip>
                          }
                        >
                          <span className="text-body text-default-font">
                            Add the App to a notifications channel. We recommend
                            creating a new, dedicated channel (e.g. #notif-chkk)
                            and adding the Chkk app to that channel.
                          </span>
                        </StepBase>
                        {isInstalled && (
                          <RouterLink
                            to={path}
                            className="contents"
                            onClick={() =>
                              logEvent('integrations-slack-app-footer-clicked')
                            }
                          >
                            <CtaCard
                              className="h-auto w-full flex-none"
                              leftSlot={
                                <IconWithBackground
                                  variant="success"
                                  size="medium"
                                />
                              }
                            >
                              <span className="text-subheader font-subheader text-default-font">
                                Slack integration succeeded
                              </span>
                              <span className="text-body font-body text-default-font">
                                You should now see an installed badge on
                                Integrations &gt; Slack
                              </span>
                            </CtaCard>
                          </RouterLink>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </Page>
  );
}

export default IntegrationSlack;
