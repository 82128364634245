// @subframe/sync-disable
// TODO: Add changes to Subframe or create a custom component on Diff Component
// 1. Copy timer functionality
// 2. onCopy method
// 3. Custom styling for overflow
// 4. Icon Change

// custom props/logic added with "// custom code" comments

'use client';
/*
 * Documentation:
 * Diff — https://app.subframe.com/cd4f5307efe3/library?component=Diff_811885df-ff3c-4fd6-9326-58068a320c89
 * Copy to clipboard button — https://app.subframe.com/cd4f5307efe3/library?component=Copy+to+clipboard+button_e8c76626-6462-4f2f-b595-38d530d427e8
 */

import React, { useEffect, useRef, useState } from 'react';
import * as SubframeCore from '@subframe/core';
import { CopyToClipboardButton } from './CopyToClipboardButton';
import { useSnackbar } from 'notistack';

interface LineProps extends React.HTMLAttributes<HTMLDivElement> {
  text?: string;
  variant?: 'default' | 'added' | 'deleted';
  className?: string;
}

const TIME_TO_RESET = 2000; // custom code

const Line = React.forwardRef<HTMLElement, LineProps>(function Line(
  { text, variant = 'default', className, ...otherProps }: LineProps,
  ref,
) {
  return (
    <div
      className={SubframeCore.twClassNames(
        'group/68a55ab6 flex w-full items-start',
        {
          'bg-error-50': variant === 'deleted',
          'bg-success-50': variant === 'added',
        },
        className,
      )}
      ref={ref as any}
      {...otherProps}
    >
      <div className="flex w-6 flex-none items-start justify-center select-none">
        <span
          className={SubframeCore.twClassNames(
            'hidden whitespace-pre-wrap text-monospace-body font-monospace-body text-error-700',
            { inline: variant === 'deleted' },
          )}
        >
          {'-'}
        </span>
        <span
          className={SubframeCore.twClassNames(
            'hidden whitespace-pre-wrap text-monospace-body font-monospace-body text-success-700',
            { inline: variant === 'added' },
          )}
        >
          {'+'}
        </span>
      </div>
      {text ? (
        <span
          className={SubframeCore.twClassNames(
            'grow shrink-0 basis-0 whitespace-pre-wrap text-monospace-body font-monospace-body text-subtext-color',
            {
              'text-error-700': variant === 'deleted',
              'text-success-700': variant === 'added',
            },
          )}
        >
          {text}
        </span>
      ) : null}
    </div>
  );
});

interface DiffRootProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  clipboardText?: string;
  className?: string;
}

const DiffRoot = React.forwardRef<HTMLElement, DiffRootProps>(function DiffRoot(
  // custom code - onCopy prop
  { children, clipboardText, onCopy, className, ...otherProps }: DiffRootProps,
  ref,
) {
  // custom code start
  const { enqueueSnackbar } = useSnackbar();
  const [copied, setCopied] = useState(false);
  const copiedTimer = useRef<number | null>(null);

  useEffect(() => {
    return () => {
      if (copiedTimer.current) {
        window.clearTimeout(copiedTimer.current);
      }
    };
  }, []);
  // custom code end
  return (
    <div
      // custom code start
      onClick={() => {
        setCopied(true);
        if (copiedTimer.current) {
          window.clearTimeout(copiedTimer.current);
        }
        copiedTimer.current = window.setTimeout(() => {
          setCopied(false);
        }, TIME_TO_RESET);
        navigator.clipboard.writeText(clipboardText!);
        enqueueSnackbar('Copied to clipboard', {
          variant: 'success',
        });
      }}
      // custom code end
      className={SubframeCore.twClassNames(
        'flex w-full flex-col items-start gap-4 overflow-hidden',
        className,
      )}
      ref={ref as any}
      {...otherProps}
    >
      {/* custom code - styling */}
      <div className="relative flex w-full max-h-[480px] flex-none items-start gap-0.5 rounded-md border border-solid border-neutral-border bg-neutral-50 px-0.5 py-0.5 overflow-auto [overflow-wrap:anywhere] [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-track]:bg-transparent [&::-webkit-scrollbar-thumb]:rounded-[20px] [&::-webkit-scrollbar-thumb]:border-4 [&::-webkit-scrollbar-thumb]:border-solid [&::-webkit-scrollbar-thumb]:border-transparent [&::-webkit-scrollbar-thumb]:bg-[rgba(255,255,255,0.2)] [&::-webkit-scrollbar-thumb]:bg-clip-border">
        {children ? (
          <div className="flex grow shrink-0 basis-0 flex-col items-start py-0.5">
            {children}
          </div>
        ) : null}
        <CopyToClipboardButton
          className="sticky top-0 right-0" // custom code
          clipboardText={clipboardText}
          tooltipText="Copy to clipboard"
          icon={copied ? 'FeatherCheck' : 'FeatherClipboard'} // custom code
        />
      </div>
    </div>
  );
});

export const Diff = Object.assign(DiffRoot, {
  Line,
});
