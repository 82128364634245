'use client';

import { Alert } from 'subframe/components/Alert';
import Page from 'components/Page';
import ClusterDetailsSectionHeader from './components/ClusterDetailsSectionHeader';

//hooks
import { useEffect, useMemo, useState } from 'react';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { useRescan } from 'hooks/useRescan';

//components
import ClusterProperties from 'components/ClusterProperties';

import ClusterScannedSignatures from './components/ClusterScannedSignatures';
import Centered from 'components/Centered';
import MessageBox from 'components/MessageBox';
import { ClusterDetailsTab } from 'contexts/ClusterDetailsTabContext';

//constants
import { formatDistanceToNow, fromUnixTime } from 'date-fns';

// helpers
import isExampleCluster from 'utils/isExampleCluster';
import {
  getClusterExpiryStateBySupportLevel,
  getCurrentlyApplicableSupportLevel,
} from 'utils/clusterSupportLevelAndEol';
import { extractMajorMinor } from 'utils/extractMajorMinor';
import { getTimeRemaining } from 'utils/getTimeRemaining';
import ClusterARSigsOverview from './view_clusterarsigsoverview';
import { useGetClusterLARsStatus } from 'hooks/useGetClusterLARsStatus';
import ClusterPropertiesSkeleton from 'components/Skeletons/ClusterPropertiesSkeleton';
import { K8sConnectorVersion } from 'utils/K8sConnectorVersion';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import { useGetCluster, useListClusterScans } from 'api/frontend';
import useUserAccountState from 'hooks/useUserAccountState';
import { ClusterScan } from 'api/models';
import * as Sentry from '@sentry/browser';
import { EnumEolState } from 'utils/types';
import { useGate } from 'statsig-react';
import { Button, Loader } from 'subframe/index';
import { TabsWithContent } from 'components/design-system/Tabs';
import { ClusterTabsType } from './models';
import Iconify from 'components/Iconify';

// ---------------------------
function FirstScanProcessing() {
  return (
    <div className="flex min-h-[320px] w-full grow shrink-0 basis-0 flex-col items-center justify-center gap-2 px-1 py-1">
      <div className="flex flex-col items-center justify-center gap-6">
        <Loader size="large" />
        <span className="text-subheader font-subheader text-default-font">
          Scan In Progress...
        </span>
      </div>
    </div>
  );
}

function RiskLedgerClustersView() {
  const { account } = useUserAccountState();

  const [currentTab, setCurrentTab] = useState('arsigs-overview-tab');

  const { clusterId } = useParams<{
    clusterId: string;
  }>();
  const showSystemRequirements = useGate('show_system_requirements');

  const [lastScanTime, setLastScanTime] = useState<string>('');
  const [lastScan, setLastScan] = useState<ClusterScan>();

  const location = useLocation();

  useEffect(() => {
    // Extract the query parameter from the location object
    const searchParams = new URLSearchParams(location.search);
    const paramValue = searchParams.get('view');

    // Change Tab to the selected category based on the query parameter
    setCurrentTab(
      paramValue ? paramValue + '-tab' : 'arsigs-overview' + '-tab',
    );
  }, [location]);

  const {
    data: scan,
    error: scanError,
    isLoading: loading,
    mutate: getScan,
  } = useListClusterScans(
    clusterId || '',
    { page_size: 2 },
    {
      swr: {
        revalidateOnFocus: true,
        enabled: !!clusterId,
      },
      request: {
        headers: {
          authorization: `Bearer ${account?.token}`,
        },
      },
    },
  );

  const {
    data: cluster,
    error: clusterError,
    mutate: clusterMutate,
  } = useGetCluster(clusterId!, {
    request: { headers: { authorization: 'Bearer ' + account?.token } },
    swr: { refreshInterval: 5 * 1000 },
  });

  useEffect(() => {
    if (scan?.data?.length) {
      const latestScan = scan.data[0];
      const secondLatestScan = scan.data[1];
      if (latestScan.status === 'complete') {
        setLastScan(latestScan);
      } else if (secondLatestScan) {
        if (secondLatestScan.status === 'complete') {
          setLastScan(secondLatestScan);
        } else {
          Sentry.captureException(new Error('Multiple scans in pending'), {
            level: 'log',
            extra: {
              clusterId: clusterId,
              accountId: account?.accountId,
              latestScanId: latestScan?.id,
              secondLatestScanId: secondLatestScan.id,
            },
          });
        }
      }
    }
  }, [scan]);
  const { logEvent } = AnalyticsEventLogger();
  const { triggerRescan, rescanComplete } = useRescan(
    clusterId,
    account?.token,
  );

  const [getCluster, setCluster] = useState({} as any);

  const onClusterNameEnvironmentChange = (
    name: string,
    environment: string,
  ) => {
    setCluster({ ...getCluster, name, environment });
  };

  useEffect(() => {
    setCluster(cluster);
  }, [cluster]);

  const {
    statusList,
    unignoredLars,
    defects,
    defectsLARs,
    unignoredDefects,
    ignoredDefects,
    misconfigurations,
    misconfigurationsLARs,
    unignoredMisconfigurations,
    ignoredMisconfigurations,
    systemRequirements,
    systemRequirementsLARs,
    unignoredSystemRequirements,
    ignoredSystemRequirements,
    deprecations,
    deprecationsLARs,
    unignoredDeprecations,
    ignoredDeprecations,
    unsupportedVersions,
    unsupportedVersionsLARs,
    unignoredUnsupportedVersions,
    ignoredUnsupportedVersions,
    versionIncompatibilities,
    versionIncompatibilitiesLARs,
    unignoredVersionIncompatibilities,
    ignoredVersionIncompatibilities,
    bestPractices,
    bestPracticesLARs,
    unignoredBestPractices,
    ignoredBestPractices,
    rawLarsDataLoading,
    rawSigsDataLoading,
  } = useGetClusterLARsStatus(clusterId, lastScan, scan, account?.token, false);

  useEffect(() => {
    if (rescanComplete !== null) {
      if (rescanComplete) {
        getScan();
      }
    }
  }, [rescanComplete, getScan]);

  useEffect(() => {
    if (scan?.data?.length && lastScan) {
      const s = lastScan;
      const t = Number(s?.created);

      const m =
        t === 0
          ? '-'
          : formatDistanceToNow(fromUnixTime(t), {
              addSuffix: true,
            });
      setLastScanTime(m);
    }
  }, [scan, lastScan]);

  const updateTabValue = (tab: ClusterDetailsTab) => {
    setCurrentTab(tab + '-tab');
    const url = new URL(`${window.location}`);
    url.searchParams.set('view', tab);
    window.history.pushState(null, '', url.toString());
  };

  const versionToShow = extractMajorMinor(cluster?.version || '');

  const guideLink = useAccountIdRoute(
    `/orgs/:orgId/accounts/:accountId/upgrades/templates/new?cluster_id=${clusterId}`,
  );

  const clusterAgentUpdatePath = useAccountIdRoute(
    `/orgs/:orgId/accounts/:accountId/clusters/${clusterId}/update-k8s-connector`,
  );

  const currSupportLevel = getCurrentlyApplicableSupportLevel(cluster);
  const timeLeft = getTimeRemaining(currSupportLevel?.end_date);
  let isClusterAgentOutdated = false;
  if (cluster && cluster.internal_k8s_ref != 'example-cluster-ref') {
    isClusterAgentOutdated = K8sConnectorVersion().check(
      cluster.chkk_metadata.version,
    );
  }
  const showLoading = () =>
    loading ||
    !scan ||
    unignoredDefects === undefined ||
    rawLarsDataLoading ||
    rawSigsDataLoading;

  const showNotFound =
    !showLoading() &&
    ((clusterError && clusterError?.response?.status === 404) ||
      scan?.__status === 404);

  const showError = () =>
    !showLoading() &&
    !showNotFound &&
    (clusterError || scanError) &&
    !cluster &&
    !scan;

  const showNetworkError =
    !showLoading() &&
    !showNotFound &&
    (clusterError?.code?.includes('ERR_NETWORK') ||
      scanError?.code?.includes('ERR_NETWORK')) &&
    (!cluster || !scan);
  const showClusterDetails = () =>
    !showNotFound && !showError() && !showNetworkError;

  const loadingState = showLoading();

  const isFirstScanProcessing =
    !loading && !loadingState && (!getCluster || !scan || !lastScan);

  const CLUSTER_TABS: ClusterTabsType = [
    {
      key: 'arsigs-overview',
      id: 'arsigs-overview-tab',
      title: (
        <div className="flex items-center gap-2">
          <Iconify icon={'ic:trending-up'} />
          Overview
        </div>
      ),
      icon: 'ic:trending-up',
      trackerEvent: 'cluster-arsigs-overview-tab',
      content: (
        <>
          {isFirstScanProcessing ? (
            <FirstScanProcessing />
          ) : (
            <ClusterARSigsOverview
              DefectsCount={defectsLARs?.length}
              MisconfigurationsCount={misconfigurationsLARs?.length}
              SystemRequirementsCount={systemRequirementsLARs?.length}
              DeprecationsCount={deprecationsLARs?.length}
              UnsupportedVersionsCount={unsupportedVersionsLARs?.length}
              VersionIncompatibilitiesCount={
                versionIncompatibilitiesLARs?.length
              }
              BestPracticesCount={bestPracticesLARs?.length}
              UnignoredDefects={unignoredDefects}
              UnignoredMisconfigurations={unignoredMisconfigurations}
              UnignoredSystemRequirements={unignoredSystemRequirements}
              UnignoredDeprecations={unignoredDeprecations}
              UnignoredUnsupportedVersions={unignoredUnsupportedVersions}
              UnignoredVersionIncompatibilities={
                unignoredVersionIncompatibilities
              }
              UnignoredBestPractices={unignoredBestPractices}
              loading={loadingState}
            />
          )}
        </>
      ),
    },
    {
      key: 'unsupported-versions',
      id: 'unsupported-versions-tab',
      title: (
        <div className="flex items-center gap-2">
          <Iconify icon={'mdi:layers-remove'} />
          Unsupported Versions
        </div>
      ),
      icon: 'mdi:layers-remove',
      trackerEvent: 'cluster-unsupported-versions-tab',
      content: (
        <>
          <ClusterScannedSignatures
            scan={lastScan}
            cluster={getCluster}
            ARSigs={unsupportedVersions}
            unignoredLARs={unignoredUnsupportedVersions}
            ignoredLARs={ignoredUnsupportedVersions}
            title={'Unsupported Versions'}
            hideSeverity={true}
            larStatus={statusList}
            skeletonLoading={loading || loadingState}
          />
          {isFirstScanProcessing && <FirstScanProcessing />}
        </>
      ),
    },
    {
      key: 'defects',
      id: 'defects-tab',
      title: (
        <div className="flex items-center gap-2">
          <Iconify icon={'fluent-mdl2:defect-solid'} />
          Defects
        </div>
      ),
      icon: 'fluent-mdl2:defect-solid',
      trackerEvent: 'cluster-defects-tab',
      content: (
        <>
          <ClusterScannedSignatures
            cluster={getCluster}
            scan={lastScan}
            ARSigs={defects}
            unignoredLARs={unignoredDefects}
            ignoredLARs={ignoredDefects}
            title={'Defects'}
            larStatus={statusList}
            skeletonLoading={loadingState}
          />

          {isFirstScanProcessing && <FirstScanProcessing />}
        </>
      ),
    },
    {
      key: 'deprecations',
      id: 'deprecations-tab',
      title: (
        <div className="flex items-center gap-2">
          <Iconify icon={'carbon:in-progress-error'} />
          Deprecations
        </div>
      ),
      icon: 'carbon:in-progress-error',
      trackerEvent: 'cluster-deprecations-tab',
      content: (
        <>
          <ClusterScannedSignatures
            scan={lastScan}
            cluster={getCluster}
            ARSigs={deprecations}
            unignoredLARs={unignoredDeprecations}
            ignoredLARs={ignoredDeprecations}
            title={'Deprecations'}
            hideSeverity={true}
            larStatus={statusList}
            skeletonLoading={loadingState}
          />
          {isFirstScanProcessing && <FirstScanProcessing />}
        </>
      ),
    },
    {
      key: 'version-incompatibilities',
      id: 'version-incompatibilities-tab',
      title: (
        <div className="flex items-center gap-2">
          <Iconify icon={'teenyicons:layers-difference-solid'} />
          Version Incompatibilities
        </div>
      ),
      icon: 'teenyicons:layers-difference-solid',
      trackerEvent: 'cluster-version-incompatibilities-tab',
      content: (
        <>
          <ClusterScannedSignatures
            scan={lastScan}
            cluster={getCluster}
            ARSigs={versionIncompatibilities}
            unignoredLARs={unignoredVersionIncompatibilities}
            ignoredLARs={ignoredVersionIncompatibilities}
            title={'Version Incompatibilities'}
            hideSeverity={true}
            larStatus={statusList}
            skeletonLoading={loadingState}
          />
          {isFirstScanProcessing && <FirstScanProcessing />}
        </>
      ),
    },
    {
      key: 'misconfigurations',
      id: 'misconfigurations-tab',
      title: (
        <div className="flex items-center gap-2">
          <Iconify icon={'fluent:error-circle-settings-20-regular'} />
          Misconfigurations
        </div>
      ),
      icon: 'fluent:error-circle-settings-20-regular',
      trackerEvent: 'cluster-misconfigurations-tab',
      content: (
        <>
          <ClusterScannedSignatures
            cluster={getCluster}
            scan={lastScan}
            ARSigs={misconfigurations}
            unignoredLARs={unignoredMisconfigurations}
            ignoredLARs={ignoredMisconfigurations}
            title={'Misconfigurations'}
            larStatus={statusList}
            skeletonLoading={loadingState}
          />
          {isFirstScanProcessing && <FirstScanProcessing />}
        </>
      ),
    },
    ...(showSystemRequirements?.value
      ? [
          {
            key: 'system-requirements' as ClusterDetailsTab,
            id: 'system-requirements-tab',
            title: (
              <div className="flex items-center gap-2">
                <Iconify icon={'pajamas:requirements'} />
                System Requirements
              </div>
            ),
            icon: 'pajamas:requirements',
            trackerEvent: 'cluster-system-requirements-tab',
            content: (
              <>
                <ClusterScannedSignatures
                  cluster={getCluster}
                  scan={lastScan}
                  ARSigs={systemRequirements}
                  unignoredLARs={unignoredSystemRequirements}
                  ignoredLARs={ignoredSystemRequirements}
                  title={'System Requirements'}
                  hideSeverity={true}
                  larStatus={statusList}
                  skeletonLoading={loadingState}
                />

                {isFirstScanProcessing && <FirstScanProcessing />}
              </>
            ),
          },
        ]
      : []),
    {
      key: 'guardrails',
      id: 'guardrails-tab',
      title: (
        <div className="flex items-center gap-2">
          <Iconify icon={'mingcute:certificate-fill'} />
          Guardrails
        </div>
      ),
      icon: 'mingcute:certificate-fill',
      trackerEvent: 'cluster-guardrails-tab',
      content: (
        <>
          <ClusterScannedSignatures
            cluster={getCluster}
            scan={lastScan}
            ARSigs={bestPractices}
            unignoredLARs={unignoredBestPractices}
            ignoredLARs={ignoredBestPractices}
            title={'Guardrails'}
            hideSeverity={true}
            larStatus={statusList}
            skeletonLoading={loadingState}
          />

          {isFirstScanProcessing && <FirstScanProcessing />}
        </>
      ),
    },
    {
      key: 'properties',
      id: 'properties-tab',
      title: (
        <div className="flex items-center gap-2">
          <Iconify icon={'heroicons-outline:cog'} />
          Properties
        </div>
      ),
      icon: 'heroicons-outline:cog',
      trackerEvent: 'cluster-properties-tab',
      content: (
        <>
          {loadingState || isFirstScanProcessing ? (
            <ClusterPropertiesSkeleton />
          ) : (
            <ClusterProperties
              properties={getCluster}
              scan={lastScan}
              filteredLARs={unignoredLars}
              onClusterNameEnvironmentChange={onClusterNameEnvironmentChange}
              mutateCluster={clusterMutate}
            />
          )}
        </>
      ),
    },
  ];

  const rescanDisabled = useMemo(
    () =>
      !lastScan ||
      rescanComplete === false ||
      (getCluster && isExampleCluster(getCluster)),
    [rescanComplete, getCluster, lastScan],
  );

  const handlePageRefresh = () => {
    window.location.reload(); // Reloads the entire page
  };
  return (
    <Page title={'Cluster'} className="ml-8">
      {showNotFound && (
        <Centered>
          <MessageBox mode={'info'} message={'Cluster not found'} />
        </Centered>
      )}

      {showNetworkError ? (
        <>
          <div className="flex w-full grow shrink-0 basis-0 flex-col items-center justify-center gap-4">
            <Loader />
            <span className="text-subheader font-subheader text-default-font">
              Connection Lost
            </span>
            <span className="text-body font-body text-subtext-color">
              It appears your connection is unstable.
            </span>
            <Button
              icon="FeatherRefreshCw"
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                handlePageRefresh();
              }}
            >
              Refresh
            </Button>
          </div>
        </>
      ) : (
        <>
          {showError() && (
            <Centered>
              <MessageBox
                mode={'error'}
                message={'Error fetching cluster details'}
              />
            </Centered>
          )}
        </>
      )}

      {showClusterDetails() && (
        <div className="flex h-full w-full flex-col items-start gap-[24px] bg-default-background px-3 pt-8">
          <ClusterDetailsSectionHeader
            isRescanButtonDisabled={rescanDisabled as boolean}
            title={getCluster?.name}
            clusterId={cluster?.id || ''}
            lastScanText={lastScanTime ? `Last scan ${lastScanTime}` : ''}
            rescanComplete={rescanComplete}
            triggerRescan={triggerRescan}
            clusterStatus={cluster?.status || ''}
          />
          <div className="flex flex-col w-full gap-1">
            {cluster && isExampleCluster(cluster) && (
              <Alert
                variant="warning"
                title="You are currently viewing example data"
                description=""
                style={{ marginTop: '8px' }}
              />
            )}

            {cluster &&
              !isExampleCluster(cluster) &&
              (getClusterExpiryStateBySupportLevel(cluster) ===
                EnumEolState.Warning ||
                getClusterExpiryStateBySupportLevel(cluster) ===
                  EnumEolState.Error) &&
              !showLoading() && (
                <div
                  className="flex w-full"
                  style={{ padding: '0px 8px 0px 8px' }}
                >
                  <Alert
                    variant={
                      getClusterExpiryStateBySupportLevel(cluster) ===
                      EnumEolState.Warning
                        ? 'warning'
                        : 'error'
                    }
                    icon={'FeatherAlertTriangle'}
                    title={
                      timeLeft.eosMessage(
                        versionToShow,
                        currSupportLevel?.name || '',
                      )[0]
                    }
                    description={
                      <span
                        id="chkk-cluster-agent-update-banner"
                        className="text-label font-label"
                      >
                        {
                          <RouterLink to={guideLink} className="hoverable-link">
                            {
                              timeLeft.eosMessage(
                                versionToShow,
                                currSupportLevel?.name || '',
                                true,
                              )[1]
                            }
                          </RouterLink>
                        }
                      </span>
                    }
                  />
                </div>
              )}

            {isClusterAgentOutdated && (
              <div
                className="flex w-full"
                style={{ padding: '8px 8px 0px 8px' }}
              >
                <Alert
                  variant="neutral"
                  title="You are using an outdated version of the K8s Connector"
                  description={
                    <span
                      id="chkk-cluster-agent-update-banner"
                      className="text-label font-label"
                    >
                      Follow the instructions{' '}
                      {
                        <RouterLink
                          to={clusterAgentUpdatePath}
                          className="hoverable-link"
                        >
                          here
                        </RouterLink>
                      }{' '}
                      to upgrade to the latest supported version
                    </span>
                  }
                />
              </div>
            )}
          </div>
          <div className="flex w-full flex-col items-start gap-4">
            <TabsWithContent
              id="cluster-details-tabs"
              initialTabId={currentTab || 'arsigs-overview-tab'}
              onTabChange={(newTabId) => {
                setCurrentTab(newTabId);
                const newTab = CLUSTER_TABS.find((t) => t.id === newTabId);
                updateTabValue(newTab?.key || 'arsigs-overview');
              }}
              tabs={CLUSTER_TABS}
            />
          </div>
        </div>
      )}
    </Page>
  );
}

export default RiskLedgerClustersView;
