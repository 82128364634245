'use client';

import { useState } from 'react';
import { LearnMoreAccordion } from 'subframe/components/LearnMoreAccordion';
import { Tabs } from 'subframe/components/Tabs';
import styles from './AddClusterTerraformTab.module.scss';
import Page from 'components/Page';
import LoadingScreen from 'components/LoadingScreen';
import useUserAccountState from 'hooks/useUserAccountState';
import { useListIngestionTokens } from 'api/frontend';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { RouterLink } from 'components/RouterLink';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import { AtomicTooltip, Divider } from 'components/design-system';
import { Icon } from '@subframe/core';
import { AddNewHelmCluster } from './tabs/AddNewHelmCluster';
import { AddNewManifestCluster } from './tabs/AddNewManifestCluster';
import { AddNewTerraformCluster } from './tabs/AddNewTerraformCluster';
import { ChkkOperatorInfo } from './tabs/ChkkOperatorInfo';
import { Breadcrumbs } from 'subframe/index';
import { getUnixTime } from 'date-fns';
import { AddNewOpenshift } from 'pages/add_cluster/tabs/AddNewOpenshiftCluster';

function AddCluster() {
  const { account } = useUserAccountState();
  const { data: tokens } = useListIngestionTokens('default', {
    request: { headers: { Authorization: 'Bearer ' + account?.token } },
    swr: {
      revalidateOnFocus: false,
    },
  });
  const [currentTab, setCurrentTab] = useState<
    'helm' | 'manifest' | 'terraform'
  >('helm');
  const { logEvent } = AnalyticsEventLogger();

  const activeTokens = tokens?.data.filter((token) =>
    token.revoked
      ? token.revoked == 0 || token.revoked > getUnixTime(new Date())
      : true,
  );

  const CLUSTER_TABS = [
    {
      key: 'helm',
      title: 'Helm',
      trackerEvent: 'addcluster-helm-tab',
      component: <AddNewHelmCluster tokens={activeTokens!} />,
    },
    {
      key: 'manifest',
      title: 'K8s YAML',
      trackerEvent: 'addcluster-k8s-manifest-tab',
      component: <AddNewManifestCluster tokens={activeTokens!} />,
    },
    {
      key: 'terraform',
      title: 'Terraform',
      trackerEvent: 'addcluster-terraform-tab',
      component: <AddNewTerraformCluster tokens={activeTokens!} />,
    },
    {
      key: 'openshift',
      title: 'OpenShift',
      trackerEvent: 'addcluster-openshift-tab',
      component: <AddNewOpenshift tokens={activeTokens!} />,
    },
  ];
  const clustersPath = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/clusters',
  );
  const addClusterPath = useAccountIdRoute(
    `/orgs/:orgId/accounts/:accountId/clusters/new`,
  );

  const logEventWithDetails = (type: string) => {
    logEvent('breadcrumbs-navigation', { type });
  };

  if (tokens === undefined) {
    return <LoadingScreen />;
  }

  return (
    <Page title="Add Cluster" id="add-cluster-page" data-cy="add-cluster-page">
      <div
        className={styles['pageContents']}
        style={{ marginLeft: '32px' }}
        id="add-cluster-container"
        data-cy="add-cluster-container"
      >
        <Breadcrumbs>
          <Breadcrumbs.Item>Risk Ledger</Breadcrumbs.Item>
          <Breadcrumbs.Divider />
          <RouterLink
            to={clustersPath}
            onClick={() => {
              logEventWithDetails('clusters-view-page');
            }}
          >
            <Breadcrumbs.Item active={true}>Clusters</Breadcrumbs.Item>{' '}
          </RouterLink>

          <Breadcrumbs.Divider />
          <RouterLink to={addClusterPath}>
            <Breadcrumbs.Item
              active={true}
              id="cluster-name"
              onClick={() => {
                logEventWithDetails('add-cluster-page');
              }}
            >
              Add Cluster
            </Breadcrumbs.Item>
          </RouterLink>
        </Breadcrumbs>
        <div className={styles['stack']}>
          <span className={styles['text-aeae8b00']}>
            You can onboard your cluster to Chkk by deploying the Chkk K8s
            Connector{' '}
            <AtomicTooltip
              tooltipContent={
                'K8s Connector runs in your Kubernetes cluster and gathers metadata required by Chkk to build Upgrade Plans and to scan your cluster for relevant Availability Risks.'
              }
            >
              <Icon
                name={'FeatherInfo'}
                style={{
                  display: 'inline-block',
                  verticalAlign: 'text-top',
                }}
              />
            </AtomicTooltip>{' '}
            via Operator. By using the Operator, you can use a single Custom
            Resource Definition (CRD) to deploy the Chkk K8s Connector and its
            configurations. The Operator can be installed via Helm, K8s YAML or
            Terraform.
          </span>
          <LearnMoreAccordion
            title="Learn more about Chkk Operator"
            data-cy="chkk-operator-info-button"
          >
            <ChkkOperatorInfo />
          </LearnMoreAccordion>
          <Divider variant="neutral" className={styles['divider']} />

          <Tabs data-cy="add-cluster-tabs" className="h-auto w-auto flex-none">
            {CLUSTER_TABS.map((tab) => (
              <Tabs.Item
                id={tab.key + '-tab'}
                key={tab.key}
                active={tab.key === currentTab}
                onClick={() => {
                  logEvent(tab.trackerEvent);
                  setCurrentTab(tab.key);
                }}
              >
                {tab.title}
              </Tabs.Item>
            ))}
          </Tabs>
          {CLUSTER_TABS.map((tab) => {
            const isMatched = tab.key === currentTab;
            return (
              isMatched && (
                <div
                  key={tab.key}
                  style={{
                    display: 'contents',
                    width: '100%',
                  }}
                >
                  {tab.component}
                </div>
              )
            );
          })}
        </div>
      </div>
    </Page>
  );
}

export default AddCluster;
