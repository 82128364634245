import * as Sentry from '@sentry/browser';
import * as SubframeCore from '@subframe/core';
import { Icon } from '@subframe/core';
import {
  updateUpgradePlan,
  useListClusters,
  useListSubscriptions,
  useListUpgradePlans,
} from 'api/frontend';
import { useListAddonInstancesInfinite } from 'api/frontend-infinite';
import { AddonInstance, ListClustersResponse, UpgradePlan } from 'api/models';
import { AtomicTooltip } from 'components/design-system';
import { moreTooltipContent } from 'components/design-system/AtomicTooltip';
import BaseTable from 'components/design-system/Table/BaseTable';
import { ConsoleLoader } from 'components/Loader';
import Page from 'components/Page';
import { RouterLink } from 'components/RouterLink';

import { somethingWentWrong, toastAutoHideDuration } from 'constants/toasts';
import { differenceInDays } from 'date-fns';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import { useAddonIdToNameMap } from 'hooks/useAddonIdToNameMap';
import { useClusterIdToNameMap } from 'hooks/useClusterIdToNameMap';
import useLocalStorage from 'hooks/useLocalStorage';
import useUserAccountState from 'hooks/useUserAccountState';
import { useSnackbar } from 'notistack';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useDebounce } from 'react-use';
import { UPCOMING_ADDON_UPGRADES, UPGRADES } from 'src/data/upgrades';
import { UpcomingUpgradeInstance } from 'src/data/upgradeTypes';
import {
  ADDONS,
  getHumanReadableImageTag,
} from 'src/data/version_register_addons';
import { Button } from 'subframe/components/Button';
import { InformationalHeader } from 'subframe/components/InformationalHeader';
import { Switch } from 'subframe/components/Switch';
import { Alert, Breadcrumbs } from 'subframe/index';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { flattenPages } from 'utils/arrays';
import styles from '../AcmeUpgradesClusters.module.scss';
import {
  getTooltipForUpgradeCompletedBy,
  getTooltipForUpgradeRequestedBy,
  showCancel,
  statusBadge,
} from '../helpers/UpgradesHelpers';
import { Searchbar } from 'components/design-system/Searchbar';
import classNames from 'classnames';
import { CommentTooltip } from 'components/design-system/CommentTooltip';
import MarkUpgradePlanAsCompletedDialog from '../components/MarkUpgradePlanAsCompletedDialog';
import UpgradesMoreOptions from '../components/UpgradesMoreOptions';

function createUpcomingUpgrades(
  plannedUpgrades: UpgradePlan[],
  addonIdToNameMap: Map<string, string>,
  addonInstances: AddonInstance[] | undefined,
  activeClustersIds: string[],
): UpcomingUpgradeInstance[] {
  if (addonInstances === undefined) {
    return [];
  }

  const plannedAddonInstanceIds = new Set(
    plannedUpgrades
      .map((u) => (u.resources.addon_instances || []).map((ai) => ai.id))
      .reduce((accumulator, value) => accumulator.concat(value), []),
  );

  return addonInstances
    .filter(
      (ai) =>
        !plannedAddonInstanceIds.has(ai.id) &&
        activeClustersIds.includes(ai.cluster_id) &&
        (ai.eol_date || 0) > 0 &&
        differenceInDays(new Date((ai.eol_date || 0) * 1000), new Date()) < 90,
    )
    .map((instance) => ({
      Id: instance.id,
      Name: addonIdToNameMap.get(instance.addon_id) || instance.addon_id,
      Environment: '',
      CurrentVersions: [
        getHumanReadableImageTag(instance.primary_component?.image),
      ],
      ClusterIds: [instance.cluster_id],
      DueDate: Math.min(0, instance.eol_date || 0),
    }));
}

export default function UpgradesAddonIndex() {
  const { logEvent } = AnalyticsEventLogger();
  const { account, currentOrganization } = useUserAccountState();
  const basePath = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/upgrades',
  );
  const newClusterPath = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/clusters/new',
  );

  const { enqueueSnackbar } = useSnackbar();

  const [showExampleData, setShowExampleData] = useLocalStorage<boolean>(
    'example-data',
    true,
  );
  const showCreateExamplePlanToast = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    const examplePlanCreateToast =
      'No new Upgrade Plans can be created from example data';
    event.stopPropagation();
    event.preventDefault();
    enqueueSnackbar(examplePlanCreateToast, {
      variant: 'warning',
      autoHideDuration: toastAutoHideDuration,
    });
  };

  useEffect(() => {
    logEvent('upgrades-addon-listing-viewed', {
      showExampleData,
    });
  }, [showExampleData]);

  const addonIdToNameMap = useAddonIdToNameMap({
    token: account.token,
  });
  const { data: addonData } = useListAddonInstancesInfinite(
    {},
    { request: { headers: { Authorization: `Bearer ${account.token}` } } },
  );
  const { data: upgradesData, mutate } = useListUpgradePlans(
    {},
    { request: { headers: { Authorization: `Bearer ${account.token}` } } },
  );
  const { data: Subscriptions } = useListSubscriptions(
    currentOrganization.slug,
    {},
    {
      request: { headers: { Authorization: `Bearer ${account.token}` } },
    },
  );

  // todo: optimize to use same list clusters call for view & clusterNameMap
  const { data: clusterData } = useListClusters(
    {},
    {
      request: { headers: { Authorization: `Bearer ${account.token}` } },
    },
  );

  const realClusters =
    clusterData === undefined
      ? []
      : ((clusterData as ListClustersResponse).data || []).filter(
          (c) => c.internal_k8s_ref !== 'example-cluster-ref',
        );
  const activeClustersIds = realClusters
    .filter((c) => {
      return c.status === 'active';
    })
    .map(function (c) {
      return c.id;
    });

  useEffect(() => {
    if (clusterData && localStorage.getItem('example-data') === null) {
      setShowExampleData(!realClusters.length);
    }
  }, [clusterData]);
  // by default show lock is true
  const showLock = Subscriptions
    ? !Subscriptions?.total_entitlement?.features?.includes(
        'request_upgrade_plan',
      )
    : true;

  const clusterNameMap = useClusterIdToNameMap({
    token: account.token,
    useExampleData: showExampleData,
  });

  const addonInstanceIdToAddonNameMap = showExampleData
    ? new Map(
        ADDONS?.map((a) => {
          return [
            a.id,
            `${addonIdToNameMap.get(a.addon_id) || a.addon_id} ${
              a.version || ''
            }`,
          ];
        }),
      )
    : new Map(
        flattenPages(addonData)?.map((a) => {
          return [
            a.id,
            `${addonIdToNameMap.get(a.addon_id) || a.addon_id} ${
              a.version || ''
            }`,
          ];
        }),
      );

  const addonInstanceIdToClusterNameMap = showExampleData
    ? new Map(
        ADDONS?.map((a) => {
          return [a.id, `${clusterNameMap.get(a.cluster_id) || a.cluster_id} `];
        }),
      )
    : new Map(
        flattenPages(addonData)?.map((a) => {
          return [a.id, `${clusterNameMap.get(a.cluster_id) || a.cluster_id} `];
        }),
      );

  const upgrades = showExampleData
    ? UPGRADES
    : upgradesData?.data?.sort((a, b) => {
        return a.created > b.created ? -1 : 1; // Latest requested Upgrade Plan should appear on top
      });
  const addonUpgrades =
    upgrades === undefined
      ? undefined
      : upgrades.filter(
          (u) =>
            (u.resources.addons || []).length +
              (u.resources.addon_instances || []).length >
            0,
        );

  const [searchUpgrade, setSearchUpgrade] = useState('');

  const handleUpgradesOnChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: string,
  ) => {
    setSearchUpgrade(newValue);
  };

  useDebounce(
    () => {
      if (searchUpgrade) {
        logEvent('search-upgrades', { searchUpgrade });
      }
    },
    1000,
    [searchUpgrade],
  );

  const upgradesToShow = searchUpgrade
    ? addonUpgrades?.filter((item) =>
        item?.name.toLowerCase().includes(searchUpgrade.toLowerCase()),
      )
    : addonUpgrades;

  const upcoming = showExampleData
    ? UPCOMING_ADDON_UPGRADES
    : createUpcomingUpgrades(
        (addonUpgrades || []).filter(
          (u) => u.status === 'pending' || u.status === 'in-progress',
        ),
        addonIdToNameMap,
        flattenPages(addonData),
        activeClustersIds,
      );

  return (
    <Page title="Add-on Upgrades">
      <div
        className={styles['pageContents']}
        style={{ marginLeft: '32px', width: 'calc(100% - 32px' }}
      >
        <Breadcrumbs>
          <Breadcrumbs.Item>Upgrade Copilot</Breadcrumbs.Item>
          <Breadcrumbs.Divider name="FeatherChevronRight" />
          <Breadcrumbs.Item active={true}>Add-on Upgrades</Breadcrumbs.Item>
        </Breadcrumbs>
        <div className={styles['header']}>
          <div className={styles['stack']}>
            <span className={styles['sectionHeaderText']}>
              Add-on Upgrade Plans
            </span>
          </div>
          <div className={styles['stack-a1f5a859']}>
            <span className={styles['text-79dfdadc']}>
              In Progress and Completed Add-on Upgrade Plans
            </span>
            <div className={styles['stack-a4c06eb0']}>
              <span className={styles['text-af9ab9cb']}>Show example data</span>
              <AtomicTooltip
                data-cy="show-example-data-tooltip"
                tooltipContent={
                  showExampleData
                    ? 'Show results from your onboarded clusters'
                    : 'Show example data'
                }
              >
                <Switch
                  data-cy="switch"
                  checked={showExampleData}
                  onCheckedChange={() => {
                    setShowExampleData(!showExampleData);
                    setSearchUpgrade('');
                  }}
                />
              </AtomicTooltip>
            </div>
          </div>
        </div>
        {showExampleData && (
          <Alert
            variant="warning"
            title="You are currently viewing example data"
            description={`To view results from your onboarded clusters, toggle the "Show example data" button`}
          />
        )}
        {addonData === undefined && <ConsoleLoader />}
        {!showExampleData &&
          addonData !== undefined &&
          addonData[0].data?.length < 1 &&
          addonIdToNameMap.size > 0 &&
          clusterNameMap.size === 0 && (
            <div className={styles['stack-435f1a02']}>
              <span className={styles['bodyText']}>
                No clusters onboarded so far. Click here to onboard your first
                cluster
              </span>
              <RouterLink to={newClusterPath}>
                <Button
                  size="small"
                  icon="FeatherPlus"
                  onClick={() =>
                    logEvent('upgrades-addon-onboard-cluster-btn-click')
                  }
                >
                  Add Cluster
                </Button>
              </RouterLink>
            </div>
          )}
        {!showExampleData &&
          addonData !== undefined &&
          addonData[0].data?.length < 1 &&
          addonIdToNameMap.size > 0 &&
          clusterNameMap.size > 0 && (
            <div className={styles['stack-45320231']}>
              <span className={styles['bodyText']}>
                We have not found any Add-ons in your clusters so far.
              </span>
            </div>
          )}
        {addonIdToNameMap.size > 0 &&
          (showExampleData ||
            (addonData !== undefined && addonData[0].data?.length > 0)) && (
            <>
              <div className={styles['stack-3227bc77']}>
                <span className={styles['subheaderText']}>Executions</span>
                {addonUpgrades !== undefined && addonUpgrades.length < 1 && (
                  <div className={styles['stack-f4735681']}>
                    <span className={styles['text-0b94c931']}>
                      No Upgrade Plans executed so far.
                    </span>
                  </div>
                )}
                {(addonUpgrades || []).length > 0 && (
                  <>
                    <div className={styles['executionsHeader']}>
                      <InformationalHeader>
                        <InformationalHeader.Item
                          data-cy="total-count"
                          title="Total"
                          bodySlot={
                            <span className="text-body-bold font-body-bold text-default-font">
                              {(addonUpgrades || []).length}
                            </span>
                          }
                        />
                        {(addonUpgrades || []).filter(
                          (upgr) => upgr.status === 'pending',
                        ).length > 0 && (
                          <InformationalHeader.Item
                            data-cy="pending-count"
                            title="Waiting for Plan"
                            bodySlot={
                              <span className="text-body-bold font-body-bold text-default-font">
                                {
                                  (addonUpgrades || []).filter(
                                    (upgr) => upgr.status === 'pending',
                                  ).length
                                }
                              </span>
                            }
                          />
                        )}
                        {(addonUpgrades || []).filter(
                          (upgr) => upgr.status === 'in-progress',
                        ).length > 0 && (
                          <InformationalHeader.Item
                            data-cy="inprogress-count"
                            title="In Progress"
                            bodySlot={
                              <span className="text-body-bold font-body-bold text-default-font">
                                {
                                  (addonUpgrades || []).filter(
                                    (upgr) => upgr.status === 'in-progress',
                                  ).length
                                }
                              </span>
                            }
                          />
                        )}
                        {(addonUpgrades || []).filter(
                          (upgr) => upgr.status === 'skipped',
                        ).length > 0 && (
                          <InformationalHeader.Item
                            data-cy="skipped-count"
                            title="Skipped"
                            bodySlot={
                              <span className="text-body-bold font-body-bold text-default-font">
                                {
                                  (addonUpgrades || []).filter(
                                    (upgr) => upgr.status === 'skipped',
                                  ).length
                                }
                              </span>
                            }
                          />
                        )}
                        {(addonUpgrades || []).filter(
                          (upgr) => upgr.status === 'outdated',
                        ).length > 0 && (
                          <InformationalHeader.Item
                            title="Outdated"
                            bodySlot={
                              <span className="text-body-bold font-body-bold text-default-font">
                                {
                                  (addonUpgrades || []).filter(
                                    (upgr) => upgr.status === 'outdated',
                                  ).length
                                }
                              </span>
                            }
                          />
                        )}
                        {(addonUpgrades || []).filter(
                          (upgr) => upgr.status === 'update-in-progress',
                        ).length > 0 && (
                          <InformationalHeader.Item
                            title="Refreshing Plan"
                            bodySlot={
                              <span className="text-body-bold font-body-bold text-default-font">
                                {
                                  (addonUpgrades || []).filter(
                                    (upgr) =>
                                      upgr.status === 'update-in-progress',
                                  ).length
                                }
                              </span>
                            }
                          />
                        )}
                        {(addonUpgrades || []).filter(
                          (upgr) => upgr.status === 'completed',
                        ).length > 0 && (
                          <InformationalHeader.Item
                            data-cy="completed-count"
                            title="Completed"
                            bodySlot={
                              <span className="text-body-bold font-body-bold text-default-font">
                                {
                                  (addonUpgrades || []).filter(
                                    (upgr) => upgr.status === 'completed',
                                  ).length
                                }
                              </span>
                            }
                          />
                        )}
                        {(addonUpgrades || []).filter(
                          (upgr) => upgr.status === 'cancelled',
                        ).length > 0 && (
                          <InformationalHeader.Item
                            data-cy="cancelled-count"
                            title="Canceled"
                            bodySlot={
                              <span className="text-body-bold font-body-bold text-default-font">
                                {
                                  (addonUpgrades || []).filter(
                                    (upgr) => upgr.status === 'cancelled',
                                  ).length
                                }
                              </span>
                            }
                          />
                        )}
                        {(addonUpgrades || []).filter(
                          (upgr) => upgr.status === 'failed',
                        ).length > 0 && (
                          <InformationalHeader.Item
                            data-cy="failed-count"
                            title="Failed"
                            bodySlot={
                              <span className="text-body-bold font-body-bold text-default-font">
                                {
                                  (addonUpgrades || []).filter(
                                    (upgr) => upgr.status === 'failed',
                                  ).length
                                }
                              </span>
                            }
                          />
                        )}
                        {(addonUpgrades || []).filter(
                          (upgr) => upgr.status === 'rolled-back',
                        ).length > 0 && (
                          <InformationalHeader.Item
                            data-cy="rolledback-count"
                            title="Rolled Back"
                            bodySlot={
                              <span className="text-body-bold font-body-bold text-default-font">
                                {
                                  (addonUpgrades || []).filter(
                                    (upgr) => upgr.status === 'rolled-back',
                                  ).length
                                }
                              </span>
                            }
                          />
                        )}
                      </InformationalHeader>

                      {addonUpgrades?.length ? (
                        <Searchbar
                          onChange={handleUpgradesOnChange}
                          view={showExampleData ? 'example' : 'real'}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className={styles['stack-1b1228c7']}>
                      <BaseTable
                        settings={{
                          localStorageKey: 'upgrades_addons_executions',
                          dataCyPrefix: 'upgrades-addons-executions',
                        }}
                        data={upgradesToShow || []}
                        rowLink={(upgr) =>
                          !showExampleData ||
                          upgr.id ===
                            'upgr_e9e674d1-6ad5-4a47-a300-6b3f515179f3' ||
                          upgr.id ===
                            'upgr_2cc1bf7a-727a-11ee-b962-0242ac120002' ||
                          upgr.id ===
                            'upgr_3b8e2ac2-ef15-4b06-bc75-df1731645b3e' ||
                          upgr.id ===
                            'upgr_e9e674d1-6ad5-4a47-a300-6b3f515179f5'
                            ? `${basePath}/addons/${upgr.id}`
                            : ''
                        }
                        rowSettings={{
                          preventClick: (upgr) =>
                            upgr.status === 'pending' ||
                            (showExampleData &&
                              upgr.id !==
                                'upgr_e9e674d1-6ad5-4a47-a300-6b3f515179f3' &&
                              upgr.id !==
                                'upgr_2cc1bf7a-727a-11ee-b962-0242ac120002' &&
                              upgr.id !==
                                'upgr_3b8e2ac2-ef15-4b06-bc75-df1731645b3e' &&
                              upgr.id !==
                                'upgr_e9e674d1-6ad5-4a47-a300-6b3f515179f5'),
                          preventClickToastMessage: showExampleData
                            ? 'This is a demonstration view only at this point - only some Upgrade Plans are available'
                            : 'An Upgrade Plan is being generated for you. You will receive a notification when the Plan is ready.',

                          rowTooltip: (upgr) =>
                            upgr.status === 'pending'
                              ? 'An Upgrade Plan is being generated for you. You will receive a notification when the Plan is ready.'
                              : '',
                        }}
                        noMatchingData={
                          !!upgrades?.length && !upgradesToShow?.length
                        }
                        columns={[
                          {
                            id: '1',
                            title: 'Plan',
                            titleStyle: { width: '25%' },
                            cellType: 'cell',
                            cellStyle: {
                              width: '30%',
                              height: '80px',
                              flex: '0 0 auto',
                            },
                            render: (upgr) => {
                              const resources = (
                                upgr.resources.addon_instances || []
                              ).map(
                                (res) =>
                                  addonInstanceIdToClusterNameMap.get(res.id) ||
                                  res.id,
                              );

                              return (
                                <div className="flex flex-col items-start gap-2">
                                  <div className="flex grow shrink-0 basis-0 items-center justify-center gap-2">
                                    <AtomicTooltip tooltipContent={upgr.name}>
                                      <div className="truncate-ellipsis">
                                        <span className="text-body-bold font-body-bold text-neutral-700">
                                          {upgr.name}
                                        </span>
                                      </div>
                                    </AtomicTooltip>
                                  </div>
                                  {upgr.current_version ? (
                                    <div className="flex items-start gap-2">
                                      {upgr.current_version ? (
                                        <span className="text-label font-label text-subtext-color">
                                          {`From: ${upgr.current_version}`}
                                        </span>
                                      ) : (
                                        <></>
                                      )}
                                      {upgr.desired_version ? (
                                        <span className="text-label font-label text-subtext-color">
                                          {`To: ${upgr.desired_version}`}
                                        </span>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  {resources.length > 0 ? (
                                    <div className="flex items-start gap-2">
                                      <span className="text-label font-label text-subtext-color">
                                        Clusters:{' '}
                                        {resources.slice(0, 1).join(', ')}
                                      </span>
                                      {resources.length > 1 && (
                                        <AtomicTooltip
                                          tooltipContent={moreTooltipContent(
                                            resources,
                                            1,
                                          )}
                                        >
                                          <span
                                            className={styles['text-cfa53551']}
                                          >
                                            +{Math.max(0, resources.length - 1)}{' '}
                                            more
                                          </span>
                                        </AtomicTooltip>
                                      )}
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              );
                            },
                          },
                          {
                            id: '2',
                            title: 'Status',
                            titleStyle: { width: '10%' },
                            cellType: 'badge-cell',
                            cellProperties: {
                              variant: (upgr) => {
                                return statusBadge[upgr.status].variant;
                              },
                            },
                            render: (upgr) => {
                              return statusBadge[upgr.status].title;
                            },
                          },
                          {
                            id: '3',
                            title: 'Add-Ons',
                            titleStyle: { width: '15%' },
                            cellType: 'cell',
                            render: (upgr) => {
                              const resources = (
                                upgr.resources.addon_instances || []
                              ).map(
                                (res) =>
                                  addonInstanceIdToAddonNameMap.get(res.id) ||
                                  res.id,
                              );

                              return (
                                <>
                                  <span
                                    className={styles['labelText']}
                                    style={{
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'break-spaces',
                                      overflow: 'hidden',
                                    }}
                                  >
                                    {resources.slice(0, 1).join(', ')}
                                  </span>
                                  {resources.length > 1 && (
                                    <AtomicTooltip
                                      tooltipContent={moreTooltipContent(
                                        resources,
                                        1,
                                      )}
                                    >
                                      <span className={styles['text-cfa53551']}>
                                        +{Math.max(0, resources.length - 1)}{' '}
                                        more
                                      </span>
                                    </AtomicTooltip>
                                  )}
                                </>
                              );
                            },
                          },
                          {
                            id: '5',
                            title: 'Requested by',
                            titleStyle: { width: '15%' },
                            cellType: 'cell',
                            render: (upgr) => {
                              return (
                                <>
                                  {upgr.requested_by?.name && upgr.created ? (
                                    <AtomicTooltip
                                      className="ph-no-capture"
                                      tooltipContent={getTooltipForUpgradeRequestedBy(
                                        upgr.requested_by?.name,
                                        upgr.created,
                                      )}
                                    >
                                      <span
                                        className={classNames(
                                          styles['bodyText'],
                                          'ph-no-capture',
                                        )}
                                      >
                                        {upgr.requested_by?.name || ''}
                                      </span>
                                    </AtomicTooltip>
                                  ) : (
                                    <span className={styles['bodyText']}></span>
                                  )}
                                </>
                              );
                            },
                          },
                          {
                            id: '6',
                            title: 'Marked Completed/ Skipped by',
                            titleStyle: { width: '15%' },
                            cellType: 'cell',
                            render: (upgr) => {
                              return (
                                <div className="flex grow shrink-0 basis-0 items-start gap-2">
                                  {upgr.completed_by?.name && upgr.updated ? (
                                    <AtomicTooltip
                                      tooltipContent={getTooltipForUpgradeCompletedBy(
                                        upgr.completed_by?.name,
                                        upgr.updated,
                                      )}
                                      className="ph-no-capture"
                                    >
                                      <span
                                        className={classNames(
                                          styles['bodyText'],
                                          'ph-no-capture',
                                        )}
                                      >
                                        {upgr.completed_by?.name || ''}
                                      </span>
                                    </AtomicTooltip>
                                  ) : (
                                    <span className={styles['bodyText']}></span>
                                  )}

                                  {upgr.status === 'completed' &&
                                    upgr.status_comment && (
                                      <CommentTooltip
                                        userName={upgr?.completed_by?.name}
                                        picture={upgr?.completed_by?.picture}
                                        time={
                                          upgr?.updated
                                            ? upgr?.updated / 1000
                                            : undefined
                                        }
                                        comment={upgr?.status_comment}
                                      />
                                    )}
                                </div>
                              );
                            },
                          },
                        ]}
                        actions={[
                          {
                            id: 'navigate',
                            render: (upgr) => {
                              return (
                                <div className={styles['stack-1b79c295']}>
                                  <div
                                    className={styles['stack-1c3bdfbc']}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      e.preventDefault();
                                    }}
                                    onContextMenu={(e) => {
                                      e.preventDefault();
                                    }}
                                  >
                                    {upgr.status === 'in-progress' && (
                                      <MarkUpgradePlanAsCompletedDialog
                                        upgradeId={upgr.id}
                                        mutatePlan={() => mutate()}
                                        isFromLandingPage={true}
                                      />
                                    )}
                                    <UpgradesMoreOptions
                                      upgrade={upgr}
                                      isTemplate={false}
                                      allowCancel={showCancel(upgr)}
                                      onSkip={() => {
                                        mutate();
                                      }}
                                      onConfirmEditName={async (newName) => {
                                        if (showExampleData) {
                                          enqueueSnackbar(
                                            'Example plans cannot be updated',
                                            {
                                              variant: 'warning',
                                              autoHideDuration:
                                                toastAutoHideDuration,
                                            },
                                          );
                                        } else {
                                          try {
                                            await updateUpgradePlan(
                                              upgr.id,
                                              { name: newName },
                                              {
                                                headers: {
                                                  Authorization: `Bearer ${account.token}`,
                                                },
                                              },
                                            );
                                            logEvent(
                                              'upgrades-addons-plan-name-change',
                                              {
                                                planId: upgr.id,
                                                name: newName,
                                              },
                                            );
                                            mutate();
                                            enqueueSnackbar(
                                              'Successfully edited requested properties of the Upgrade Plan',
                                              {
                                                variant: 'success',
                                                autoHideDuration:
                                                  toastAutoHideDuration,
                                              },
                                            );
                                          } catch (err) {
                                            enqueueSnackbar(
                                              somethingWentWrong.replace(
                                                '<action>',
                                                'updating requested properties of the Upgrade Plan',
                                              ),
                                              {
                                                variant: 'error',
                                                autoHideDuration:
                                                  toastAutoHideDuration,
                                              },
                                            );
                                            Sentry.captureException(err);
                                          }
                                        }
                                      }}
                                      onConfirmCancel={async () => {
                                        if (showExampleData) {
                                          enqueueSnackbar(
                                            'Example plans cannot be canceled',
                                            {
                                              variant: 'warning',
                                              autoHideDuration:
                                                toastAutoHideDuration,
                                            },
                                          );
                                        } else {
                                          try {
                                            await updateUpgradePlan(
                                              upgr.id,
                                              { status: 'cancelled' },
                                              {
                                                headers: {
                                                  Authorization: `Bearer ${account.token}`,
                                                },
                                              },
                                            );
                                            logEvent(
                                              'upgrades-addons-plan-cancel',
                                              {
                                                planId: upgr.id,
                                                name: upgr.name,
                                              },
                                            );
                                            mutate();
                                            enqueueSnackbar(
                                              'Successfully canceled Upgrade Plan',
                                              {
                                                variant: 'success',
                                                autoHideDuration:
                                                  toastAutoHideDuration,
                                              },
                                            );
                                          } catch (err) {
                                            enqueueSnackbar(
                                              somethingWentWrong.replace(
                                                '<action>',
                                                'canceling this Upgrade Plan',
                                              ),
                                              {
                                                variant: 'error',
                                                autoHideDuration:
                                                  toastAutoHideDuration,
                                              },
                                            );
                                            Sentry.captureException(err);
                                          }
                                        }
                                      }}
                                    />
                                  </div>

                                  {upgr.status !== 'pending' && (
                                    <Icon
                                      className={styles['bodyText']}
                                      name="FeatherChevronRight"
                                    />
                                  )}
                                  {upgr.status === 'pending' && (
                                    <Icon
                                      className={styles['icon-ef8c001b']}
                                      name="FeatherChevronRight"
                                    />
                                  )}
                                </div>
                              );
                            },
                          },
                        ]}
                      />
                    </div>
                  </>
                )}
              </div>
            </>
          )}
      </div>
    </Page>
  );
}
