import { useState } from 'react';
import { Dialog } from 'subframe/components/Dialog';
import { IconButton } from 'subframe/components/IconButton';
import { TextField } from 'subframe/components/TextField';
import { Button } from 'subframe/components/Button';
import * as Yup from 'yup';
import * as Sentry from '@sentry/browser';
import { AtomicTooltip } from 'components/design-system';
import {
  charLimitExceededErrorMessage,
  charLimits,
  fieldIsRequiredErrorMessage,
} from 'constants/input-validation';

export function EditCloudAccountNameDialog({
  name,
  onConfirm,
}: {
  name: string;
  onConfirm: (newName: string) => void;
}): JSX.Element {
  const [open, setOpen] = useState(false);
  const [updatedName, setUpdatedName] = useState(name);
  const [nameErrorText, setNameErrorText] = useState<string | undefined>(
    undefined,
  );
  const [isSubmitting, setisSubmitting] = useState(false);

  const ChangeNameSchema = Yup.object().shape({
    name: Yup.string()
      .required(fieldIsRequiredErrorMessage('Cloud Account Name'))
      .max(
        charLimits.Name,
        charLimitExceededErrorMessage('Cloud Account Name', charLimits.Name),
      ),
  });

  const handleChangeName = async () => {
    setisSubmitting(true);
    try {
      await ChangeNameSchema.validate({ name: updatedName });
      // if the name has changed, call the onConfirm callback
      if (updatedName && updatedName.localeCompare(name) !== 0) {
        onConfirm(updatedName);
      }
      handleOpenChange();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        setNameErrorText(error.message);
      } else {
        Sentry.captureException(error);
      }
    } finally {
      setisSubmitting(false);
    }
  };

  const handleOpenChange = (open = false) => {
    setUpdatedName(name);
    setNameErrorText(undefined);
    setOpen(open);
  };

  return (
    <div
      style={{
        display: 'contents',
      }}
    >
      <AtomicTooltip tooltipContent="Edit Cloud Account Name">
        <IconButton
          icon="FeatherPencil"
          size="medium"
          onClick={() => handleOpenChange(true)}
        />
      </AtomicTooltip>

      <Dialog
        open={open}
        onOpenChange={(openStatus) => handleOpenChange(openStatus)}
      >
        <Dialog.Content className="h-auto w-80 flex-none">
          <div className="flex w-full flex-col items-start gap-6 pt-6 pr-6 pb-6 pl-6">
            <div className="flex w-full items-center justify-between">
              <span className="text-subheader font-subheader text-default-font">
                Edit Cloud Account Name
              </span>
              <IconButton
                size="medium"
                icon="FeatherX"
                onClick={() => handleOpenChange()}
              />
            </div>
            <div className="flex w-full flex-col items-start gap-4">
              <TextField
                className="h-auto w-full flex-none"
                helpText={nameErrorText}
                error={!!nameErrorText}
              >
                <TextField.Input
                  value={updatedName}
                  onChange={(event) => setUpdatedName(event.target.value)}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      handleChangeName();
                    }
                  }}
                />
              </TextField>
            </div>
            <div className="flex w-full grow shrink-0 basis-0 items-end justify-end gap-2">
              <Button
                variant="brand-primary"
                size="large"
                icon="FeatherSave"
                onClick={handleChangeName}
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                Save
              </Button>
            </div>
          </div>
        </Dialog.Content>
      </Dialog>
    </div>
  );
}
