import {
  createUpgradePlanStageStepComment,
  createUpgradeTemplateStageStepComment,
  deleteUpgradePlanStageStepLastComment,
  deleteUpgradeTemplateStageStepLastComment,
  useListUpgradePlanStageStepComments,
  useListUpgradeTemplateStageStepComments,
} from 'api/frontend';
import {
  UpgradePlan,
  UpgradeStage,
  UpgradeStageStep,
  UpgradeTemplate,
} from 'api/models';
import useUserAccountState from 'hooks/useUserAccountState';
import { useSnackbar } from 'notistack';
import * as Sentry from '@sentry/browser';
import {
  Accordion,
  Avatar,
  Button,
  ChatList,
  IconButton,
  Loader,
  SeeAllButton,
  TextArea,
} from 'subframe/index';
import { useState } from 'react';
import { somethingWentWrong } from 'constants/toasts';
import { getHumanReadableDateAndTime } from 'utils/formatTime';
import { AtomicTooltip } from 'components/design-system';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider from 'components/FormProvider';
import {
  charLimitExceededErrorMessage,
  charLimits,
} from 'constants/input-validation';

export default function StepDrawerCommentsForTeam(props: {
  showExampleData: boolean;
  upgrade: UpgradePlan | UpgradeTemplate;
  stage: UpgradeStage;
  step: UpgradeStageStep;
  commentThreadId?: string;
  isTemplate?: boolean;
  onChange: () => void;
}) {
  const { account, user } = useUserAccountState();
  const { enqueueSnackbar } = useSnackbar();
  const [isDeleting, setIsDeleting] = useState(false);
  const [seeAllMessages, setSeeAllMessages] = useState(false);
  const { data: commentsData, mutate: commentsMutate } = props.isTemplate
    ? useListUpgradeTemplateStageStepComments(
        props.upgrade.id,
        props.stage.stage_number.toFixed(),
        props.step.step_number.toFixed(),
        {},
        {
          request: {
            headers: { Authorization: `Bearer ${account.token}` },
          },
        },
      )
    : useListUpgradePlanStageStepComments(
        props.upgrade.id,
        props.stage.stage_number.toFixed(),
        props.step.step_number.toFixed(),
        {},
        {
          request: {
            headers: { Authorization: `Bearer ${account.token}` },
          },
          swr: {
            enabled: !props.showExampleData,
          },
        },
      );
  const postCommentSchema = Yup.object().shape({
    comment: Yup.string()
      .optional()
      .max(
        charLimits.ActionDetails,
        charLimitExceededErrorMessage('Comments', charLimits.ActionDetails),
      ),
  });

  const defaultValues: { comment: string } = {
    comment: '',
  };

  const methods = useForm<{ comment: string }>({
    resolver: yupResolver(postCommentSchema),
    defaultValues: defaultValues,
  });

  const {
    handleSubmit,
    clearErrors,
    resetField,
    formState: { isSubmitting, errors },
  } = methods;
  const postComment = async (data: { comment: string }) => {
    try {
      if (props.isTemplate) {
        await createUpgradeTemplateStageStepComment(
          props.upgrade.id,
          props.stage.stage_number.toFixed(),
          props.step.step_number.toFixed(),
          { comment: data.comment },
          {
            headers: {
              Authorization: `Bearer ${account.token}`,
            },
          },
        );
      } else {
        await createUpgradePlanStageStepComment(
          props.upgrade.id,
          props.stage.stage_number.toFixed(),
          props.step.step_number.toFixed(),
          { comment: data.comment },
          {
            headers: {
              Authorization: `Bearer ${account.token}`,
            },
          },
        );
      }

      await commentsMutate();
      props.onChange();
    } catch (err) {
      Sentry.captureException(err);
      enqueueSnackbar(
        somethingWentWrong.replace('<action>', 'posting comment'),
        { variant: 'error' },
      );
    } finally {
      resetField('comment');
      clearErrors();
    }
  };

  const disableActionsString =
    props.upgrade.status === 'pending' ||
    props.upgrade.status === 'cancelled' ||
    props.upgrade.status === 'completed' ||
    props.upgrade.status === 'regenerate-in-progress'
      ? `The Upgrade ${
          props.isTemplate ? 'Template' : 'Plan'
        } is read-only and can no longer be commented on.`
      : undefined;

  const commentsList =
    commentsData?.data?.filter((item) => !item.is_for_chkk) || [];

  if (props.showExampleData) {
    return <></>;
  }

  return (
    <div className="flex w-full flex-col items-start gap-4 rounded-md border border-solid border-neutral-border bg-default-background shadow-sm">
      <div className="flex w-full flex-col items-start border-b border-solid border-neutral-border">
        <Accordion
          trigger={
            <div className="flex w-full items-center gap-2 border-t border-solid border-neutral-border px-6 py-5">
              <span className="grow shrink-0 basis-0 text-subheader font-subheader text-default-font">
                Leave Comment for your team
              </span>
              <Accordion.Chevron />
            </div>
          }
          defaultOpen={true}
        >
          <div className="flex w-full grow shrink-0 basis-0 flex-col items-start gap-4 px-6 pb-6">
            <div className="flex w-full flex-col items-start gap-4">
              {commentsData ? (
                <>
                  <div className="flex w-full items-end justify-end gap-2">
                    <div className="flex grow shrink-0 basis-0 flex-col items-start gap-4">
                      <ChatList className="h-auto w-full flex-none">
                        {commentsList
                          .slice(0, seeAllMessages ? commentsList.length : 2)
                          .map((item, idx) => {
                            return (
                              <ChatList.ChatListItem
                                key={idx}
                                name={item.user.name}
                                avatar={<Avatar image={item.user.picture} />}
                                time={getHumanReadableDateAndTime(item.time)} // same date time format as in Cluster Properties
                                message={item.comment}
                              />
                            );
                          })}
                      </ChatList>
                    </div>
                    <div className="flex w-10 flex-none flex-col items-end justify-end gap-2 px-1 py-1">
                      {commentsList.length && props.commentThreadId ? (
                        <AtomicTooltip tooltipContent="Delete the last comment">
                          <IconButton
                            icon="FeatherTrash"
                            loading={isDeleting}
                            disabled={isDeleting}
                            onClick={async () => {
                              // if last comment user is not the currently active user, then do not allow deletion
                              if (
                                commentsList[commentsList.length - 1].user
                                  .id !== user.userId
                              ) {
                                enqueueSnackbar(
                                  'The comment can only be deleted by the user who posted it',
                                  { variant: 'error' },
                                );
                                return;
                              }
                              try {
                                setIsDeleting(true);
                                if (props.isTemplate) {
                                  await deleteUpgradeTemplateStageStepLastComment(
                                    props.upgrade.id,
                                    props.stage.stage_number.toFixed(),
                                    props.step.step_number.toFixed(),
                                    props.commentThreadId || '',
                                    {},
                                    {
                                      headers: {
                                        Authorization: `Bearer ${account.token}`,
                                      },
                                    },
                                  );
                                } else {
                                  await deleteUpgradePlanStageStepLastComment(
                                    props.upgrade.id,
                                    props.stage.stage_number.toFixed(),
                                    props.step.step_number.toFixed(),
                                    props.commentThreadId || '',

                                    {
                                      headers: {
                                        Authorization: `Bearer ${account.token}`,
                                      },
                                    },
                                  );
                                }
                                await commentsMutate();
                                props.onChange();
                              } catch (err) {
                                Sentry.captureException(err);
                                enqueueSnackbar(
                                  somethingWentWrong.replace(
                                    '<action>',
                                    'deleting comment',
                                  ),
                                  { variant: 'error' },
                                );
                              } finally {
                                setIsDeleting(false);
                              }
                            }}
                          />
                        </AtomicTooltip>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  {commentsList.length > 2 && (
                    <>
                      {seeAllMessages ? (
                        <SeeAllButton
                          text="See less..."
                          onClick={() => setSeeAllMessages(false)}
                        />
                      ) : (
                        <SeeAllButton
                          text={`See ${
                            commentsList.length - 2
                          } more comments...`}
                          onClick={() => setSeeAllMessages(true)}
                        />
                      )}
                    </>
                  )}
                </>
              ) : (
                <Loader />
              )}
              <FormProvider
                methods={methods}
                onSubmit={handleSubmit(postComment)}
                style={{ display: 'contents' }}
              >
                <div className="flex w-full flex-col items-start gap-4">
                  <div className="flex w-full flex-col items-end gap-2 py-4">
                    <div className="flex w-full items-start gap-2">
                      <Avatar image={user.profilePicture} />
                      <TextArea
                        className="h-auto grow shrink-0 basis-0"
                        helpText={errors.comment?.message}
                        error={methods.getFieldState('comment').invalid}
                      >
                        <TextArea.Input
                          placeholder="Post comment"
                          {...methods.register('comment')}
                          onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                              event.preventDefault();
                              handleSubmit(postComment)();
                            }
                          }}
                          disabled={!!disableActionsString || isSubmitting}
                        />
                      </TextArea>
                    </div>
                    {disableActionsString ? (
                      <AtomicTooltip
                        tooltipContent={`Action not allowed. ${disableActionsString}`}
                      >
                        <Button variant="neutral-secondary" disabled={true}>
                          Comment
                        </Button>
                      </AtomicTooltip>
                    ) : (
                      <Button
                        variant="neutral-secondary"
                        disabled={!!disableActionsString || isSubmitting}
                        loading={isSubmitting}
                        onClick={handleSubmit(postComment)}
                      >
                        Comment
                      </Button>
                    )}
                  </div>
                </div>
              </FormProvider>
            </div>
          </div>
        </Accordion>
      </div>
    </div>
  );
}
