import {
  useListUpgradePlanHistory,
  useListUpgradeTemplateHistory,
} from 'api/frontend';
import {
  UpgradePlan,
  UpgradeStage,
  UpgradeStageStep,
  UpgradeTemplate,
} from 'api/models';
import { formatDistanceToNow } from 'date-fns';
import useUserAccountState from 'hooks/useUserAccountState';
import { useState } from 'react';
import {
  Accordion,
  Feed,
  IconWithBackground,
  Loader,
  SeeAllButton,
} from 'subframe/index';
import { getHistoryAvatar, getHistoryText } from './UpgradeSidebar';

interface StepActivityFeedProps {
  upgrade: UpgradePlan | UpgradeTemplate;
  step: UpgradeStageStep;
  stage: UpgradeStage;
  isTemplate?: boolean;
  showExampleData?: boolean;
}
export const StepActivityFeed = (props: StepActivityFeedProps) => {
  const { account } = useUserAccountState();
  const [seeAllActivity, setSeeAllActivity] = useState(false);

  const { data: historyData } = props.isTemplate
    ? useListUpgradeTemplateHistory(
        props.upgrade.id,
        {},
        {
          request: {
            headers: { Authorization: `Bearer ${account.token}` },
          },
          swr: {
            enabled: !props.showExampleData,
          },
        },
      )
    : useListUpgradePlanHistory(
        props.upgrade.id,
        {},
        {
          request: {
            headers: { Authorization: `Bearer ${account.token}` },
          },
          swr: {
            enabled: !props.showExampleData,
          },
        },
      );

  const preparedData =
    historyData === undefined
      ? undefined
      : historyData.data
          .filter(
            (event) =>
              event.subject.stage?.number === props.stage.stage_number &&
              event.subject.step?.number === props.step.step_number,
          )
          // filter out comment events as they would look redundant with the comments section below
          .filter((event) => event.type !== 'step_commented')
          .sort((a, b) => b.time - a.time);
  return (
    <div className="flex w-full flex-col items-start gap-4 rounded-md border border-solid border-neutral-border bg-default-background shadow-sm">
      <div className="flex w-full flex-col items-start border-b border-solid border-neutral-border">
        <Accordion
          trigger={
            <div className="flex w-full items-center gap-2 border-t border-solid border-neutral-border px-6 py-5">
              <span className="grow shrink-0 basis-0 text-subheader font-subheader text-default-font">
                Activity Feed
              </span>
              <Accordion.Chevron />
            </div>
          }
          defaultOpen={true}
        >
          <div className="flex w-full grow shrink-0 basis-0 flex-col items-start gap-2 px-6 pb-6">
            <div className="flex w-full flex-col items-start gap-4">
              {!props.showExampleData && preparedData === undefined && (
                <Loader />
              )}
              {!props.showExampleData && preparedData !== undefined && (
                <Feed className="h-full w-full grow shrink-0 basis-0">
                  {preparedData?.length ? (
                    <>
                      {preparedData
                        .slice(0, seeAllActivity ? preparedData.length : 2)
                        .map((item, idx) => (
                          <Feed.Item
                            key={idx}
                            leftSlot={getHistoryAvatar(item)}
                            comment={
                              item.data.comment !== undefined &&
                              item.data.comment !== '' && (
                                <Feed.Comment>{item.data.comment}</Feed.Comment>
                              )
                            }
                            timestamp={formatDistanceToNow(
                              new Date(item.time * 1000),
                              {
                                addSuffix: true,
                              },
                            )}
                            isLast={idx === preparedData.length - 1}
                          >
                            <span className="text-body-bold font-body-bold text-default-font ph-no-capture">
                              {item.actor.user?.name || 'Chkk'}
                            </span>
                            <span className="text-body font-body text-subtext-color">
                              {getHistoryText(item, props.isTemplate)}
                            </span>
                          </Feed.Item>
                        ))}
                    </>
                  ) : (
                    <span className="text-body font-body text-subtext-color">
                      No activity yet.
                    </span>
                  )}
                </Feed>
              )}
              {props.showExampleData &&
                !props.isTemplate &&
                props.step.status === 'completed' && (
                  <Feed>
                    <Feed.Item
                      leftSlot={
                        <IconWithBackground
                          variant="success"
                          size="small"
                          icon="FeatherCheck"
                        />
                      }
                      timestamp={
                        props.upgrade.updated
                          ? formatDistanceToNow(
                              new Date(props.upgrade.updated),
                              {
                                addSuffix: true,
                              },
                            )
                          : ''
                      }
                      comment={null}
                      isLast={true}
                    >
                      <span className="text-body-bold font-body-bold text-default-font ph-no-capture">
                        {props.upgrade.completed_by?.name || 'Jane Doe'}
                      </span>
                      <span className="text-body font-body text-subtext-color">
                        completed this step
                      </span>
                    </Feed.Item>
                  </Feed>
                )}
              {preparedData?.length && preparedData.length > 2 ? (
                <>
                  {seeAllActivity ? (
                    <SeeAllButton
                      text="See less..."
                      onClick={() => setSeeAllActivity(false)}
                    />
                  ) : (
                    <SeeAllButton
                      text={`See more...`}
                      onClick={() => setSeeAllActivity(true)}
                    />
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </Accordion>
      </div>
    </div>
  );
};
