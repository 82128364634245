import { Cluster } from 'api/models';
import isExampleCluster from './isExampleCluster';

export const getTimeDiff = (timestamp: number) => {
  // calculate time difference between now and last timestamp
  let dateNow = new Date(Date.now());
  let lastScanDate = new Date(timestamp * 1000);
  let diff = dateNow.getTime() - lastScanDate.getTime();
  return diff / (1000 * 3600);
};

export const isClusterInactive = (
  timeDiff: number,
  cluster: Cluster,
): boolean => {
  return timeDiff > 48 && !isExampleCluster(cluster);
};

export const getClusterProvider = (cluster: Cluster): string => {
  if (cluster?.overrides?.infrastructure?.cloud_platform) {
    return cluster.overrides.infrastructure.cloud_platform;
  } else if (cluster?.cloud_provider) {
    return cluster?.cloud_provider;
  } else {
    return 'K8s';
  }
};

export const getClusterRegion = (cluster: Cluster): string => {
  if (cluster?.overrides?.infrastructure?.region) {
    return cluster.overrides.infrastructure.region;
  } else if (cluster?.platform?.region) {
    return cluster?.platform?.region;
  } else if (cluster?.region) {
    return cluster?.region;
  }
  return 'local';
};
