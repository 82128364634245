import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider from 'components/FormProvider';
import * as Sentry from '@sentry/browser';
import { useSnackbar } from 'notistack';
import { somethingWentWrong, toastAutoHideDuration } from 'constants/toasts';
import { updateUpgradeTemplate } from 'api/frontend';
import useUserAccountState from 'hooks/useUserAccountState';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { useState } from 'react';
import { AtomicTooltip } from 'components/design-system';
import { Button, Dialog, IconButton, TextArea } from 'subframe/index';
import {
  charLimitExceededErrorMessage,
  charLimits,
} from 'constants/input-validation';

interface MarkUpgradeTemplateAsApprovedProps {
  upgradeId: string;
  mutateTemplate: () => void;
  tooltipText?: string;
}

function MarkUpgradeTemplateAsApproved({
  upgradeId,
  mutateTemplate,
  tooltipText,
}: MarkUpgradeTemplateAsApprovedProps) {
  const { account } = useUserAccountState();
  const { logEvent } = AnalyticsEventLogger();
  const { enqueueSnackbar } = useSnackbar();
  const [openDialog, setOpenDialog] = useState(false);

  const markAsApprovedSchema = Yup.object().shape({
    comment: Yup.string()
      .optional()
      .max(
        charLimits.ActionDetails,
        charLimitExceededErrorMessage('Comments', charLimits.ActionDetails),
      ),
  });

  const defaultValues: { comment: string } = {
    comment: '',
  };

  const methods = useForm<{ comment: string }>({
    resolver: yupResolver(markAsApprovedSchema),
    defaultValues: defaultValues,
  });

  const {
    handleSubmit,
    clearErrors,
    resetField,
    formState: { isSubmitting, errors },
  } = methods;

  const SubmissionError = somethingWentWrong.replace(
    '<action>',
    'marking the Upgrade Template as Approved',
  );

  const MarkAsApproved = async (data: { comment: string }) => {
    try {
      await updateUpgradeTemplate(
        upgradeId,
        { status: 'approved', status_comment: data.comment },
        {
          headers: {
            Authorization: `Bearer ${account.token}`,
          },
        },
      );
      logEvent('upgrade-template-mark-as-approved', {
        templateId: upgradeId,
      });
      enqueueSnackbar('Successfully marked Upgrade Template as Approved', {
        variant: 'success',
        autoHideDuration: toastAutoHideDuration,
      });
      mutateTemplate();
    } catch (error) {
      Sentry.captureException(error);
      enqueueSnackbar(SubmissionError, {
        variant: 'error',
        autoHideDuration: toastAutoHideDuration,
      });
    }
    closeDialog();
  };

  const closeDialog = () => {
    setOpenDialog(false);
    resetField('comment');
    clearErrors();
  };

  return (
    <div style={{ display: 'contents' }}>
      <AtomicTooltip tooltipContent={tooltipText || 'Approve Upgrade Template'}>
        <IconButton
          size="medium"
          icon="FeatherThumbsUp"
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation();
            event.preventDefault();
            setOpenDialog(true);
          }}
        />
      </AtomicTooltip>

      <Dialog open={openDialog} onOpenChange={() => setOpenDialog(!openDialog)}>
        <Dialog.Content className="h-auto w-auto flex-none">
          <FormProvider
            methods={methods}
            onSubmit={handleSubmit(MarkAsApproved)}
          >
            <div className="flex w-full min-w-[384px] max-w-[448px] flex-col items-start gap-6 px-6 py-6">
              <div className="flex w-full items-start justify-between">
                <span className="text-subheader font-subheader text-default-font">
                  Ready to mark this Upgrade Template as approved?
                </span>
                <IconButton
                  size="medium"
                  icon="FeatherX"
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                    closeDialog()
                  }
                />
              </div>
              <span className="text-body font-body text-default-font">
                Once approved, the Upgrade Template can be used to Instantiate
                Cluster Upgrade Plans.
              </span>
              <div className="flex w-full flex-col items-start gap-2">
                <span className="text-body-bold font-body-bold text-subtext-color">
                  Comments (Optional)
                </span>
                <TextArea
                  className="h-auto w-full flex-none"
                  helpText={errors.comment?.message}
                  error={methods.getFieldState('comment').invalid}
                >
                  <TextArea.Input
                    className="h-auto min-h-[96px] w-full flex-none"
                    placeholder="Are there any additional details you would like to share?"
                    {...methods.register('comment')}
                  />
                </TextArea>
              </div>
              <div className="flex w-full items-end justify-end gap-4">
                <Button
                  variant="brand-primary"
                  size="large"
                  icon="FeatherThumbsUp"
                  onClick={handleSubmit(MarkAsApproved)}
                  disabled={isSubmitting}
                  loading={isSubmitting}
                >
                  Mark as Approved
                </Button>
              </div>
            </div>
          </FormProvider>
        </Dialog.Content>
      </Dialog>
    </div>
  );
}

export default MarkUpgradeTemplateAsApproved;
