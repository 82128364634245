import * as SubframeCore from '@subframe/core';
import { useState } from 'react';
import { IconButton } from 'subframe/components/IconButton';
import { RadioGroup } from 'subframe/components/RadioGroup';
import { ActivityFeedbackFrame, TextArea } from 'subframe/index';
import { Button } from 'subframe/components/Button';
import { Dialog } from 'subframe/components/Dialog';
import styles from './UpgradesSkipStepDialog.module.scss';
import { Account } from 'models/account';
import { UpgradePlan, UpgradeStageStepStatusDetailsReason } from 'api/models';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider from 'components/FormProvider';
import * as Sentry from '@sentry/browser';
import { useSnackbar } from 'notistack';
import { somethingWentWrong, toastAutoHideDuration } from 'constants/toasts';
import { isPlanActionDisabled } from '../helpers/UpgradesHelpers';
import { AtomicTooltip } from 'components/design-system';
import { updateUpgradePlanStageStep } from 'api/frontend';
import {
  charLimitExceededErrorMessage,
  charLimits,
  fieldIsRequiredErrorMessage,
} from 'constants/input-validation';

export interface UpgradesSkipStepDialogProps {
  account: Account;
  upgrade: UpgradePlan;
  stageNumber: number;
  stepNumber: number;
  onSuccess: () => void;
  isTemplate?: boolean;
}

export default function UpgradesSkipStepDialog(
  props: UpgradesSkipStepDialogProps,
) {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const skipReasonSchema = Yup.object().shape({
    comment: Yup.string()
      .max(
        charLimits.ActionDetails,
        charLimitExceededErrorMessage('Comments', charLimits.ActionDetails),
      )
      .optional(),
    reason: Yup.string()
      .oneOf(
        [...Object.values(UpgradeStageStepStatusDetailsReason)],
        'Select a reason',
      )
      .nullable()
      .required(fieldIsRequiredErrorMessage('Reason')),
  });

  const defaultValues = {
    reason: '',
    comment: '',
  };

  const methods = useForm({
    resolver: yupResolver(skipReasonSchema),
    defaultValues: defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    getValues,
    setValue,
    reset,
    clearErrors,
  } = methods;

  const onSubmit = async (data: { reason: string; comment: string }) => {
    setLoading(true);

    try {
      await updateUpgradePlanStageStep(
        props.upgrade.id,
        props.stageNumber.toFixed(),
        props.stepNumber.toFixed(),
        {
          status: 'skipped',
          status_details: {
            reason: data.reason as UpgradeStageStepStatusDetailsReason,
            comment: data.comment,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${props.account.token}`,
          },
        },
      );
      enqueueSnackbar('Upgrade Step was skipped successfully', {
        variant: 'success',
        autoHideDuration: toastAutoHideDuration,
      });
      setDialogOpen(false);
      props.onSuccess();
    } catch (error) {
      Sentry.captureException(error);
      enqueueSnackbar(
        somethingWentWrong.replace('<action>', 'skipping this Step'),
        { variant: 'error', autoHideDuration: toastAutoHideDuration },
      );
    } finally {
      setLoading(false);
    }
  };

  const resetToDefault = () => {
    reset(defaultValues);
  };
  if (props.isTemplate) {
    return <></>;
  }

  return (
    <>
      <ActivityFeedbackFrame variant={'default'}>
        {isPlanActionDisabled(props.upgrade.status) ? (
          <AtomicTooltip
            tooltipContent={`Action not allowed on ${props.upgrade.status} Upgrade Plan`}
          >
            <Button
              variant="brand-tertiary"
              size="medium"
              icon="FeatherSkipForward"
              style={{
                display: 'flex',
                pointerEvents: 'all',
              }}
              disabled={true}
            >
              Skip
            </Button>
          </AtomicTooltip>
        ) : (
          <Button
            variant="brand-tertiary"
            size="medium"
            icon="FeatherSkipForward"
            style={{
              display: 'flex',
              pointerEvents: 'all',
            }}
            onClick={(event) => {
              clearErrors();
              resetToDefault();

              setDialogOpen(true);
              event.preventDefault();
            }}
          >
            Skip
          </Button>
        )}
      </ActivityFeedbackFrame>

      <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
        <Dialog.Content>
          <FormProvider
            id={'skip-step-dialog-form'}
            methods={methods}
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className={styles['stack-e7cf0c04']}>
              <div className={styles['stack-50ff0504']}>
                <div className={styles['stack-c16dcb77']}>
                  <span className={styles['text']}>Skip this step</span>
                  <IconButton
                    icon="FeatherX"
                    size="medium"
                    onClick={(event) => {
                      setDialogOpen(false);
                      event.preventDefault();
                    }}
                    disabled={loading}
                  />
                </div>
              </div>
              <div className={styles['stack-60149222']}>
                <div className={styles['stack']}>
                  <span className={styles['text-a4bc2071']}>Reason</span>
                  <div className={styles['stack-d1049577']}>
                    <RadioGroup
                      className="h-auto w-full flex-none"
                      {...methods.register('reason')}
                      defaultValue={defaultValues.reason || ''}
                      onValueChange={(val: string) => {
                        setValue('reason', val as string, {
                          shouldValidate: true,
                        });
                      }}
                    >
                      <div className="flex w-full items-start gap-4">
                        <RadioGroup.Option
                          label="Will upgrade later"
                          value="WillUpgradeLater"
                          disabled={loading}
                        />
                        <RadioGroup.Option
                          label="Incorrect recommendation"
                          value="IncorrectRecommendation"
                          disabled={loading}
                        />
                        <RadioGroup.Option
                          label="Owned by another team"
                          value="OwnedByAnotherTeam"
                          disabled={loading}
                        />
                        <RadioGroup.Option
                          label="Other"
                          value="Other"
                          disabled={loading}
                        />
                      </div>
                    </RadioGroup>
                  </div>
                  {errors?.reason && (
                    <SubframeCore.Text style={{ color: 'var(--error-800)' }}>
                      {errors?.reason?.message}
                    </SubframeCore.Text>
                  )}
                </div>
                <TextArea
                  className="h-auto w-full flex-[0_0_auto]"
                  label="Comments (optional)"
                  helpText={errors.comment?.message}
                  error={methods.getFieldState('comment').invalid}
                  style={{ height: '5lh' }}
                >
                  <TextArea.Input
                    className="h-full min-h-[48px] w-full flex-[1_0_0]"
                    disabled={loading}
                    placeholder={
                      getValues('comment') ||
                      'Why do you think this step should be Skipped?'
                    }
                    {...methods.register('comment')}
                  />
                </TextArea>
              </div>
              <div className={styles['stack-d3f36edb']}>
                <Button
                  size="large"
                  type="submit"
                  variant="brand-primary"
                  icon="FeatherSkipForward"
                  loading={isSubmitting || loading}
                  disabled={isSubmitting || loading}
                >
                  Skip
                </Button>
              </div>
            </div>
          </FormProvider>
        </Dialog.Content>
      </Dialog>
    </>
  );
}
