import Page from 'components/Page';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import styles from '../AcmeUpgradesClustersCreatePlanLandingPage.module.scss';
import { createUpgradePlan, useListSubscriptions } from 'api/frontend';
import { ConsoleLoader } from 'components/Loader';
import { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useClusterIdToNameMap } from 'hooks/useClusterIdToNameMap';
import { Breadcrumbs } from 'subframe/index';
import { CardIntegration } from 'subframe/components/CardIntegration';
import { Badge } from 'subframe/components/Badge';
import { RadioGroup } from 'subframe/components/RadioGroup';
import { RouterLink } from 'components/RouterLink';
import useUserAccountState from 'hooks/useUserAccountState';
import { useAddonIdToNameMap } from 'hooks/useAddonIdToNameMap';
import { getHumanReadableImageTag } from 'src/data/version_register_addons';
import { InfoTooltip } from 'subframe/index';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { getPaginatedChunk } from 'components/design-system/Table/TablePagination';
import { Stack } from 'components/utils';
import { somethingWentWrong, toastAutoHideDuration } from 'constants/toasts';
import * as Sentry from '@sentry/browser';
import { useListAddonInstancesInfinite } from 'api/frontend-infinite';
import { flattenPages } from 'utils/arrays';
import { LockingWrapper } from 'components/LockingWrapper';
import { Searchbar } from 'components/design-system/Searchbar';
import { useDebounce } from 'react-use';
import BaseTable from 'components/design-system/Table/BaseTable';

type UpgradePlanType =
  | 'pick-for-me'
  | 'in-place'
  | 'blue-green'
  | 'rolling'
  | 'custom';

export default function UpgradesAddonCreate() {
  const { account, currentOrganization } = useUserAccountState();
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<UpgradePlanType | undefined>(
    undefined,
  );
  const [selectedAddonInstance, setSelectedAddonInstance] = useState<string>(
    searchParams.has('addon_instance_id')
      ? `${searchParams.get('addon_instance_id')}`
      : '',
  );
  const { logEvent } = AnalyticsEventLogger();

  const rowsPerPage = 25;
  const [page, setPage] = useState(0);
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  // scroll to deployment cards if an addon has already been selected
  useEffect(() => {
    // if not a hash link, scroll to top
    if ((searchParams.get('addon_instance_id') || '') === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const element = document.getElementById('deployment_strategy');
        element?.scrollIntoView();
      }, 0);
    }
    logEvent('upgrade-addons-plan-create-viewed');
  }, [searchParams]);

  const basePath = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/upgrades/addons',
  );

  const addonIdToNameMap = useAddonIdToNameMap({ token: account.token });
  const { data: addonInstanceData } = useListAddonInstancesInfinite(
    { filter: ['status:active'] },
    {
      request: {
        headers: { Authorization: `Bearer ${account.token}` },
      },
    },
  );

  const clusterIdToNameMap = useClusterIdToNameMap({
    token: account.token,
    useExampleData: false,
  });

  let tableData =
    addonInstanceData === undefined
      ? undefined
      : (flattenPages(addonInstanceData) || []).map((instance) => {
          return {
            id: instance.id,
            name: addonIdToNameMap.get(instance.addon_id) || instance.addon_id,
            tag: getHumanReadableImageTag(instance.primary_component?.image),
            cluster_ids: [instance.cluster_id],
            eol_date: Math.min(0, instance.eol_date || 0),
          };
        });
  if (searchParams.get('addon_instance_id')) {
    tableData = tableData?.filter(
      (addon) => addon.id === searchParams.get('addon_instance_id'),
    );
  }

  const sortedAddOns = Array.from(tableData || [].values()).sort((a, b) =>
    a.name.localeCompare(b.name),
  );
  const [searchAddOn, setSearchAddOn] = useState('');
  const [addOnsToShow, setAddOnsToShow] = useState(sortedAddOns);
  const handleOnChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: string,
  ) => {
    setSearchAddOn(newValue);
  };

  useDebounce(
    () => {
      if (searchAddOn) {
        logEvent('search-addons-to-upgrade', { searchAddOn });
      }
    },
    1000,
    [searchAddOn],
  );
  useEffect(() => {
    if (searchAddOn !== '') {
      const found = sortedAddOns.filter((item) =>
        item?.name.toLowerCase().includes(searchAddOn.toLowerCase()),
      );
      setAddOnsToShow(found);
    } else {
      setAddOnsToShow(sortedAddOns);
    }
  }, [addonInstanceData, searchAddOn]);

  const submitUpgradePlan = async function (type: UpgradePlanType) {
    if (loading) {
      // avoid double requests
      return;
    }

    logEvent('upgrade-plan-request-submit', {
      addon_instance_id: selectedAddonInstance,
      deployment_strategy: type || 'pick-for-me',
    });

    setLoading(type);
    if (type === 'custom') {
      enqueueSnackbar(
        'Custom Upgrade Plans are not available just yet - stay tuned',
        { variant: 'warning', autoHideDuration: toastAutoHideDuration },
      );
      setLoading(undefined);
      return;
    }
    if (selectedAddonInstance.length === 0) {
      enqueueSnackbar('Select at least one Add-on to create an Upgrade Plan', {
        variant: 'error',
        autoHideDuration: toastAutoHideDuration,
      });
      setLoading(undefined);
      return;
    }

    try {
      await createUpgradePlan(
        {
          deployment_strategy: type || 'pick-for-me',
          resources: {
            addon_instances: [{ id: selectedAddonInstance }],
          },
        },
        {
          headers: {
            Authorization: `Bearer ${account.token}`,
          },
        },
      );
      logEvent('upgrade-plan-create-success', {
        addon_instance_id: selectedAddonInstance,
        deployment_strategy: type || 'pick-for-me',
      });
      enqueueSnackbar(
        "Successfully requested an Upgrade Plan. This step usually takes 1 week. We'll notify you when the Upgrade Plan is ready",
        {
          variant: 'success',
          autoHideDuration: toastAutoHideDuration,
        },
      );
      navigate(basePath);
    } catch (err) {
      enqueueSnackbar(
        somethingWentWrong.replace('<action>', 'requesting this Upgrade Plan'),
        {
          variant: 'error',
          autoHideDuration: toastAutoHideDuration,
        },
      );
      Sentry.captureException(err);
      logEvent('upgrade-plan-create-error', {
        addon_instance_id: selectedAddonInstance,
        deployment_strategy: type || 'pick-for-me',
      });
    }
    setLoading(undefined);
  };
  const displayResourcesPerPage = useMemo(() => {
    if (addOnsToShow && addonIdToNameMap.size > 0)
      return getPaginatedChunk(page, addOnsToShow, rowsPerPage);
    return [];
  }, [page, tableData]);
  const { data: Subscriptions } = useListSubscriptions(
    currentOrganization.slug,
    {},
    {
      request: { headers: { Authorization: `Bearer ${account.token}` } },
    },
  );
  // by default show lock is true
  const showLock = Subscriptions
    ? !Subscriptions?.total_entitlement?.features?.includes(
        'request_upgrade_plan',
      )
    : true;

  return (
    <Page title="Upgrades">
      <div
        className={styles['pageContents']}
        style={{ marginLeft: '32px', width: 'calc(100% - 32px)' }}
      >
        <Breadcrumbs>
          <Breadcrumbs.Item>Upgrade Copilot</Breadcrumbs.Item>
          <Breadcrumbs.Divider name="FeatherChevronRight" />
          <RouterLink to={basePath}>
            <Breadcrumbs.Item>Add-on Upgrades</Breadcrumbs.Item>
          </RouterLink>
          <Breadcrumbs.Divider name="FeatherChevronRight" />
          <Breadcrumbs.Item
            active={true}
            onClick={() => logEvent('upgrade-plan-create-breadcrumb-clicked')}
          >
            Create new
          </Breadcrumbs.Item>
        </Breadcrumbs>
        <div className={styles['header']}>
          <div className={styles['stack']}>
            <div className={styles['stack-1ca75b0a']}>
              <div className={styles['stack-2a88a143']}>
                <span className={styles['sectionHeaderText']}>
                  Create Preverified Add-on Upgrade Plan
                </span>
                <span className={styles['text-88a11dba']}>
                  Pick a template Upgrade Plan or create one with custom
                  resources
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles['selectionHeader']}>
          <span className={styles['subheaderText']}>
            Select Add-ons to Upgrade
          </span>
          {sortedAddOns.length && !searchParams.get('addon_instance_id') ? (
            <Searchbar onChange={handleOnChange} />
          ) : (
            <></>
          )}
        </div>

        <div className={styles['stack-2a86f599']}>
          {tableData !== undefined &&
          tableData.length < 1 &&
          addonIdToNameMap.size > 0 ? (
            <Stack alignItems="center" justifyContent="center" width="fill">
              <span className={styles['bodyText']}>No Add-ons found</span>
            </Stack>
          ) : (
            <>
              {(tableData === undefined || addonIdToNameMap.size < 1) && (
                <div style={{ alignSelf: 'center' }}>
                  <ConsoleLoader />
                </div>
              )}
              {tableData && addonIdToNameMap.size > 0 && (
                <BaseTable
                  settings={{
                    localStorageKey: 'upgrades_addons_create',
                    dataCyPrefix: 'upgrades-addons-create',
                  }}
                  data={addOnsToShow}
                  onRowClick={(addonInstance) => {
                    setSelectedAddonInstance(addonInstance.id);
                  }}
                  noMatchingData={tableData && !addOnsToShow.length}
                  columns={[
                    {
                      id: '1',
                      title: 'Add-On',
                      titleStyle: { width: '40%' },
                      cellType: 'cell',
                      render: (addonInstance) => {
                        return (
                          <div className={styles['stack-0ea3b6a2']}>
                            <RadioGroup>
                              <RadioGroup.Option
                                checked={
                                  selectedAddonInstance === addonInstance.id
                                }
                                label={addonInstance.name}
                                value={addonInstance.id}
                              />
                            </RadioGroup>
                          </div>
                        );
                      },
                    },
                    {
                      id: '2',
                      title: (
                        <Stack direction="row" spacing={1}>
                          Tag
                          <InfoTooltip tooltipText="The Tags that have been locally applied to an Add-On's container images" />{' '}
                        </Stack>
                      ),
                      titleStyle: { width: '25%' },
                      cellType: 'cell',
                      render: (addonInstance) => {
                        return (
                          <span className={styles['text-88a11dba']}>
                            {addonInstance.tag}
                          </span>
                        );
                      },
                    },
                    {
                      id: '3',
                      title: 'Clusters',
                      titleStyle: { width: '35%' },
                      cellType: 'cell',
                      render: (addonInstance) => {
                        return (
                          <span className={styles['text-88a11dba']}>
                            {addonInstance.cluster_ids
                              .slice(0, 2)
                              .map((id) => clusterIdToNameMap.get(id) || id)
                              .join(', ')}
                            {addonInstance.cluster_ids.length > 2 && (
                              <span className={styles['text-4ed4444f']}>
                                +
                                {Math.max(
                                  0,
                                  addonInstance.cluster_ids.length - 2,
                                )}{' '}
                                more
                              </span>
                            )}
                          </span>
                        );
                      },
                    },
                  ]}
                  actions={[]}
                />
              )}
            </>
          )}
        </div>

        <span className={styles['subheaderText']} id="deployment_strategy">
          Select an Upgrade Template
        </span>
        <div className={styles['stack-feb51da1']}>
          <LockingWrapper
            feature="request_upgrade_plan"
            upgradePlanType={'addon'}
            tooltipText={
              tableData?.length
                ? undefined //default, handled in LockingWrapper
                : 'Add a cluster to get Upgrade Plans'
            }
            showLock={showLock}
            defaultAction={() => submitUpgradePlan('pick-for-me')}
          >
            <CardIntegration
              loading={loading === 'pick-for-me'}
              selected={loading === 'pick-for-me'}
              cardTitle="Pick for me"
              text="Let Chkk create the safest Upgrade Plan for you"
              badge={<Badge variant="success">Recommended</Badge>}
              logo={
                <img
                  className={styles['image']}
                  src="https://res.cloudinary.com/demo/image/upload/v1674762771/Logo_ndnxzk.png"
                />
              }
            />
          </LockingWrapper>
        </div>
      </div>
    </Page>
  );
}
