import { useState, SyntheticEvent, useEffect, useMemo } from 'react';

import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { useDebounce } from 'react-use';

import { ClusterDetailsTable } from './ClusterDetailsTable';
import {
  clearSensitivity,
  ClusterDetailsFilterView,
  DetectedTerm,
  getDetectedTooltip,
  NotDetectedTerm,
} from 'utils/filters';

import { clusterDetailsTableView } from 'utils/filters';
import {
  IconWithBackground,
  InformationalHeader,
  Select,
  SkeletonText,
} from 'subframe/index';
import {
  Cluster,
  ClusterDetectedLar,
  ClusterScan,
  ClusterScannedSignature,
  LARStatus,
} from 'api/models';
import { CATEGORY_DETAILS_TABS_TYPE } from '../models';
import { Searchbar } from 'components/design-system/Searchbar';

interface Props {
  scan: ClusterScan | undefined;
  cluster: Cluster;
  ARSigs: ClusterScannedSignature[] | undefined;
  unignoredLARs: ClusterDetectedLar[] | undefined;
  ignoredLARs: ClusterDetectedLar[] | undefined;
  title: string;
  hideSeverity?: boolean;
  larStatus: LARStatus[];
  skeletonLoading?: boolean;
}
import * as SubframeCore from '@subframe/core';
import { AtomicTooltip } from 'components/design-system';

export default function ClusterScannedSignatures({
  scan,
  cluster,
  ARSigs,
  unignoredLARs,
  ignoredLARs,
  title,
  hideSeverity,
  larStatus,
  skeletonLoading,
}: Props) {
  const [filterClustersType, setFilterClustersType] = useState('');
  const [filterSelected, setFilterSelected] = useState(false);
  const [searchSigName, setSearchSigName] = useState('');

  const getBestPracticesInitialFilter = (title: string) => {
    return clearSensitivity(title) === 'guardrails'
      ? ClusterDetailsFilterView.Detected
      : ClusterDetailsFilterView.NeedAttention;
  };

  const [tableView, setTableView] = useState<clusterDetailsTableView>(
    getBestPracticesInitialFilter(title),
  );

  const [showTableView, setShowTableView] = useState<clusterDetailsTableView>(
    getBestPracticesInitialFilter(title),
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const tabCount = {
    NeedsAttention: unignoredLARs?.filter((sig) => sig.need_attention).length,
    Detected: unignoredLARs?.length,
    UnDetected: ARSigs?.filter((sig) => !sig.detected).length,
    Ignored: ignoredLARs?.length,
  };

  const displayTableData = useMemo(() => {
    switch (tableView) {
      case ClusterDetailsFilterView.NeedAttention:
        return {
          data: unignoredLARs
            ?.filter((sig) => sig.need_attention)
            .map((sig) => ({
              ...sig,
              detected: true,
            })) as ClusterScannedSignature[],
          filteredView: ClusterDetailsFilterView.NeedAttention,
        };

      case ClusterDetailsFilterView.Detected:
        return {
          data: unignoredLARs?.map((sig) => ({
            ...sig,
            detected: true,
          })) as ClusterScannedSignature[],
          filteredView: ClusterDetailsFilterView.Detected,
        };

      case ClusterDetailsFilterView.UnDetected:
        return {
          data: ARSigs?.filter((sig) => !sig.detected),
          filteredView: ClusterDetailsFilterView.UnDetected,
        };

      case ClusterDetailsFilterView.Ignored:
        return {
          data: ignoredLARs?.map((sig) => ({
            ...sig,
            detected: true,
          })) as ClusterScannedSignature[],
          filteredView: ClusterDetailsFilterView.Ignored,
        };

      default:
        return {
          data: unignoredLARs
            ?.filter((sig) => sig.need_attention)
            .map((sig) => ({
              ...sig,
              detected: true,
            })) as ClusterScannedSignature[],
          filteredView: ClusterDetailsFilterView.NeedAttention,
        };
    }
  }, [tableView, unignoredLARs, ignoredLARs, ARSigs]);
  useEffect(() => {
    if (
      tableView === showTableView &&
      showTableView === displayTableData.filteredView
    ) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }

    if (tableView !== showTableView) {
      if (tableView === 'NeedsAttention' && title === 'Guardrails') {
        setTableView('Detected');
      } else {
        setShowTableView(tableView);
      }
    }
  }, [displayTableData, showTableView, tableView, unignoredLARs]);

  const { logEvent } = AnalyticsEventLogger();

  const handleOnChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: string,
  ) => {
    setSearchSigName(newValue);
  };

  useDebounce(
    () => {
      if (searchSigName) {
        logEvent('search-sig', {
          clusterId: cluster.id,
        });
      }
    },
    1000,
    [searchSigName],
  );

  const handleFilterSubmit = (severity: string) => {
    setFilterClustersType(severity);
    if (severity === '' || severity === 'clear') {
      setFilterSelected(false);
    } else {
      setFilterSelected(true);
    }
  };
  let headerTitle = '';

  //subheader
  const noIssuesMessage = () => {
    switch (tableView) {
      case 'NeedsAttention':
        return 'No Issues Need Attention';

      case 'Ignored':
        return 'No Issues Ignored';

      case 'Detected':
        return 'No Issues Detected';

      default:
        return 'No Issues Found';
    }
  };
  //body
  const emptyTableMessage = () => {
    let totalDetectedCount = unignoredLARs?.length;

    headerTitle.split(' ')[headerTitle.split(' ').length - 1];

    switch (tableView) {
      case 'NeedsAttention':
        if (title !== 'System Requirements')
          return `Chkk did not find any ${title} that Need Attention in this cluster.`;
        else return `This cluster meets all ${title} scanned by Chkk.`;

      case 'Ignored':
        if (title !== 'System Requirements')
          return `No ${title} have been Ignored in this cluster.`;
        else return `No unmet ${title} have been Ignored in this cluster.`;

      case 'Detected':
        if (title !== 'System Requirements')
          return `Chkk did not find any ${title} in this cluster.`;
        else return `This cluster meets all ${title} scanned by Chkk.`;

      case 'UnDetected':
        if (title === 'System Requirements')
          return `Chkk detected ${totalDetectedCount} ${title} that are not met in this cluster. Once you meet these ${title}, they will automatically appear in this table.`;
        else
          return `Chkk detected ${totalDetectedCount} ${title} in this cluster. Once you fix these ${title}, they will automatically appear in this table.`;
    }
  };

  const CategoryDetailsTab: CATEGORY_DETAILS_TABS_TYPE = [
    {
      key: 'need_attention',
      id: ClusterDetailsFilterView.NeedAttention,
      title: 'Need Attention',
      trackerEvent: '',
    },
    {
      key: 'detected',
      id: ClusterDetailsFilterView.Detected,
      title: `${
        DetectedTerm(title) === 'Detected'
          ? 'All Detected'
          : DetectedTerm(title)
      }`,
      trackerEvent: '',
    },
    {
      key: 'ignored',
      id: ClusterDetailsFilterView.Ignored,
      title: 'Ignored',
      trackerEvent: '',
    },
  ].filter(
    (tab) =>
      (tab.id !== ClusterDetailsFilterView.NeedAttention &&
        clearSensitivity(title) === 'guardrails') ||
      clearSensitivity(title) !== 'guardrails',
  );

  const handleChange = (nextView: clusterDetailsTableView) => {
    if (nextView !== null) {
      logEvent(`${nextView}-filtered-view`, { clusterId: cluster?.id });
      setIsLoading(true);
      setTableView(nextView);
      setSearchSigName('');
    }
  };

  useEffect(() => {
    if (tableView === 'NeedsAttention' && title === 'Guardrails') {
      setTableView('Detected');
    } else {
      setTableView(ClusterDetailsFilterView.NeedAttention);
    }
    setFilterClustersType('');
    setSearchSigName('');
  }, [title]);

  const getPrefixedTitle =
    title === 'Guardrails'
      ? 'not followed Guardrails'
      : title === 'System Requirements'
      ? 'unmet System Requirements'
      : title;

  return (
    <>
      {ARSigs?.length || skeletonLoading ? (
        <>
          <div className="flex w-full items-start justify-between">
            <div className="flex flex-col items-start justify-center gap-2">
              <div className="flex items-start gap-2">
                <InformationalHeader>
                  <InformationalHeader.Item
                    title={DetectedTerm(title)}
                    headingSlot={
                      <AtomicTooltip
                        tooltipContent={
                          skeletonLoading
                            ? undefined
                            : getDetectedTooltip(title)
                        }
                      >
                        <SubframeCore.Icon
                          className="text-body font-body text-subtext-color group-hover/e8c76626:text-default-font "
                          name={'FeatherInfo'}
                        />
                      </AtomicTooltip>
                    }
                    bodySlot={
                      skeletonLoading ? (
                        <SkeletonText className="w-[20px] h-[20px]" />
                      ) : (
                        <span
                          className="text-body-bold font-body-bold text-default-font"
                          data-cy={'detected-count'}
                        >
                          {tabCount.Detected}
                        </span>
                      )
                    }
                  />
                  <InformationalHeader.Item
                    title="Ignored"
                    headingSlot={
                      <AtomicTooltip
                        tooltipContent={
                          skeletonLoading
                            ? undefined
                            : `Detected ${getPrefixedTitle} that have been marked as Ignored`
                        }
                      >
                        <SubframeCore.Icon
                          className="text-body font-body text-subtext-color group-hover/e8c76626:text-default-font "
                          name={'FeatherInfo'}
                        />
                      </AtomicTooltip>
                    }
                    bodySlot={
                      skeletonLoading ? (
                        <SkeletonText className="w-[20px] h-[20px]" />
                      ) : (
                        <span
                          className="text-body-bold font-body-bold text-default-font"
                          data-cy={'ignored-count'}
                        >
                          {tabCount.Ignored}
                        </span>
                      )
                    }
                  />
                  <InformationalHeader.Item
                    title={NotDetectedTerm(title)}
                    headingSlot={
                      <AtomicTooltip
                        tooltipContent={
                          skeletonLoading
                            ? undefined
                            : `${title} that were scanned but ${NotDetectedTerm(
                                title,
                              )}.`
                        }
                      >
                        <SubframeCore.Icon
                          className="text-body font-body text-subtext-color group-hover/e8c76626:text-default-font "
                          name={'FeatherInfo'}
                        />
                      </AtomicTooltip>
                    }
                    bodySlot={
                      skeletonLoading ? (
                        <SkeletonText className="w-[20px] h-[20px]" />
                      ) : (
                        <span
                          className="text-body-bold font-body-bold text-default-font"
                          data-cy={'not-detected-count'}
                        >
                          {ARSigs?.filter((sig) => !sig.detected).length}
                        </span>
                      )
                    }
                  />
                </InformationalHeader>
              </div>
            </div>
            <div className="flex items-center justify-between gap-1">
              {skeletonLoading ? (
                <SkeletonText className="h-[30px] w-[180px] mr-1" />
              ) : (
                <>
                  {displayTableData?.data?.length ? (
                    <Searchbar
                      resetText={searchSigName === ''}
                      onChange={handleOnChange}
                    />
                  ) : (
                    <></>
                  )}
                </>
              )}
              <div className="flex grow shrink-0 basis-0 items-center gap-4">
                {skeletonLoading ? (
                  <SkeletonText className="h-[30px] w-[100px]" />
                ) : (
                  <Select
                    key={tableView}
                    data-cy="cluster-details-status-filter"
                    label=""
                    placeholder={
                      CategoryDetailsTab.find(
                        (categoryDetails) => categoryDetails.id === tableView,
                      )?.title || 'Select Status'
                    }
                    helpText=""
                    onValueChange={handleChange}
                    className={'text-default-font'}
                  >
                    {title !== 'Guardrails' && (
                      <Select.Item
                        value="NeedsAttention"
                        data-cy="select-need-attention"
                        data-state={
                          tableView === ClusterDetailsFilterView.NeedAttention
                            ? 'checked'
                            : 'unchecked'
                        }
                      >
                        Need Attention
                      </Select.Item>
                    )}
                    <Select.Item
                      value="Detected"
                      data-cy="select-all-detected"
                      data-state={
                        tableView === ClusterDetailsFilterView.Detected
                          ? 'checked'
                          : 'unchecked'
                      }
                    >
                      {DetectedTerm(title) === 'Detected'
                        ? 'All Detected'
                        : DetectedTerm(title)}
                    </Select.Item>
                    <Select.Item
                      value="Ignored"
                      data-cy="select-ignored"
                      data-state={
                        tableView === ClusterDetailsFilterView.Ignored
                          ? 'checked'
                          : 'unchecked'
                      }
                    >
                      Ignored
                    </Select.Item>
                  </Select>
                )}
              </div>
              {!hideSeverity && (
                <div className="flex grow shrink-0 basis-0 items-center gap-4">
                  {skeletonLoading ? (
                    <SkeletonText className="h-[30px] w-[100px]" />
                  ) : (
                    <div className="flex grow shrink-0 basis-0 items-center gap-4">
                      <Select
                        key={filterClustersType}
                        label=""
                        placeholder={
                          ['', 'clear'].includes(filterClustersType.trim())
                            ? 'Severity'
                            : filterClustersType
                        }
                        helpText=""
                        onValueChange={handleFilterSubmit}
                        className={'capitalize'}
                      >
                        <Select.Item value="critical">Critical </Select.Item>
                        <Select.Item value="high">High </Select.Item>
                        <Select.Item value="medium">Medium </Select.Item>
                        <Select.Item value="low">Low </Select.Item>
                        <div className="flex w-full flex-col items-start pt-0.5 pb-0.5">
                          <div className="flex h-px w-full flex-none flex-col items-center gap-2 bg-neutral-300" />
                        </div>
                        <Select.Item value="clear">Clear Selection</Select.Item>
                      </Select>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <>
            {(tabCount[tableView] && displayTableData.data?.length) ||
            skeletonLoading ? (
              <ClusterDetailsTable
                key={'table-details-' + displayTableData?.data?.length}
                cluster={cluster}
                scan={scan}
                tableData={displayTableData?.data || []}
                filterClustersType={filterClustersType}
                searchSigName={searchSigName}
                hideSeverity={hideSeverity || false}
                title={title}
                isLoading={isLoading || skeletonLoading || false}
                larStatus={larStatus}
                tableView={tableView}
              />
            ) : (
              <div
                className="flex min-h-[320px] w-full grow shrink-0 basis-0 flex-col items-center justify-center gap-2 px-1 py-1"
                id="chkk-empty-message"
              >
                <IconWithBackground variant="success" size="large" />
                <span className="text-subheader font-subheader text-default-font">
                  {noIssuesMessage()}
                </span>
                <span className="text-body font-body text-subtext-color">
                  {emptyTableMessage()}
                </span>
              </div>
            )}
          </>
        </>
      ) : (
        <div
          className="flex min-h-[320px] w-full grow shrink-0 basis-0 flex-col items-center justify-center gap-2 px-1 py-1"
          data-cy="no-issues-found-message"
        >
          <SubframeCore.Icon
            className="text-header font-header text-subtext-color"
            name="FeatherFolderX"
          />
          <span className="text-body font-body text-default-font">
            No Issues Found
          </span>
          <span className="text-body font-body text-subtext-color">
            {`Chkk did not find any ${title} relevant to this
                  cluster.`}
          </span>
        </div>
      )}
    </>
  );
}
