import React, { useState, useEffect } from 'react';

import { createIngestionToken } from 'api/frontend';
import { useSnackbar } from 'notistack';
import useUserAccountState from 'hooks/useUserAccountState';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import { useForm } from 'react-hook-form';

import { CreateIngestionTokenRequest, IngestionToken } from 'api/models';

import {
  Breadcrumbs,
  Button,
  CtaCard,
  IconButton,
  IconWithBackground,
  InfoTooltip,
  StepBase,
  TextField,
  Tooltip,
} from 'subframe/index';
import FormProvider from 'components/FormProvider';
import Page from 'components/Page';
import { CopyToClipboard } from 'components/design-system/CopyToClipboard';
import { RouterLink } from 'components/RouterLink';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Sentry from '@sentry/browser';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { somethingWentWrong, toastAutoHideDuration } from 'constants/toasts';
import { AtomicTooltip } from 'components/design-system';
import {
  charLimitExceededErrorMessage,
  charLimits,
  fieldIsRequiredErrorMessage,
  ingestionTokenNameRegex,
} from 'constants/input-validation';

type ProcessingState = 'pending' | 'processing' | 'completed' | 'failed';

interface WorkflowState {
  // creation form
  tokenName: string;
  token?: string;

  // processing state
  creationState: ProcessingState;

  // UI folding state
  firstOpen: boolean;
  lastOpen: boolean;
  isTokenVisible: boolean;
}

function creationStateToStepVariant(
  state: 'pending' | 'processing' | 'completed' | 'failed',
): 'default' | 'success' | 'loading' | 'error' {
  if (state === 'pending') {
    return 'default';
  }
  if (state === 'processing') {
    return 'loading';
  }
  if (state === 'completed') {
    return 'success';
  }
  if (state === 'failed') {
    return 'error';
  }

  return 'default';
}

export function SettingsAddToken() {
  const { logEvent } = AnalyticsEventLogger();
  const { enqueueSnackbar } = useSnackbar();
  const { account } = useUserAccountState();
  const [workflowState, setWorkflowState] = useState<WorkflowState>({
    tokenName: '',
    firstOpen: true,
    lastOpen: false,
    creationState: 'pending',
    isTokenVisible: false,
  });
  const basePathSettings = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/settings',
  );
  const AddTokenSuccess = 'Token added successfully';
  const AddTokenRequestError = somethingWentWrong.replace(
    '<action>',
    'adding Token',
  );

  const CopyToClipboardText =
    'For security purposes, your token is hidden. Click “Unhide” to view its content';
  const CompleteFirstStepText = 'Complete Step 1 first';

  const FormSchema = yup.object().shape({
    token_name: yup
      .string()
      .required(fieldIsRequiredErrorMessage('Token Name'))
      .max(
        charLimits.RFC1123Label,
        charLimitExceededErrorMessage('Token Name', charLimits.RFC1123Label),
      )
      .matches(ingestionTokenNameRegex, {
        message:
          'Token name must start and end with lowercase alphanumeric characters, and can be separated by hyphens.',
      }),
  });

  const methods = useForm<CreateIngestionTokenRequest>({
    resolver: yupResolver(FormSchema),
    defaultValues: { token_name: '' },
  });

  const {
    handleSubmit,
    formState: { errors },
    trigger: validateForm,
  } = methods;

  useEffect(() => {
    setWorkflowState((prev) => ({
      ...prev,
      firstOpen: true,
    }));
  }, [errors]);

  const addToken = async function (token: CreateIngestionTokenRequest) {
    logEvent('ingestion-token-creation', token);
    setWorkflowState((prev) => ({ ...prev, creationState: 'processing' }));
    try {
      const createdToken: IngestionToken = await createIngestionToken(
        'default',
        token,
        {
          headers: { Authorization: `Bearer ${account?.token}` },
        },
      );
      logEvent('ingestion-token-creation-success', {
        tokenID: createdToken.token_id,
        tokenName: createdToken.token_name,
        creationTime: createdToken.created,
      });
      enqueueSnackbar(AddTokenSuccess, {
        variant: 'success',
        autoHideDuration: toastAutoHideDuration,
      });
      setWorkflowState((prev) => ({
        ...prev,
        creationState: 'completed',
        token: createdToken.token,
        firstOpen: false,
        lastOpen: true,
      }));
    } catch (error) {
      const response = error.response;
      enqueueSnackbar(response?.data?.message || AddTokenRequestError, {
        variant: 'error',
        autoHideDuration: toastAutoHideDuration,
      });
      Sentry.captureException(error);
      setWorkflowState((prev) => ({
        ...prev,
        creationState: 'failed',
        firstOpen: true,
      }));
    }
  };
  return (
    <Page title="Create Token">
      <div className="flex h-full w-[calc(100%-64px)] flex-col items-start gap-6 pt-8 pb-0 px-3 ml-8">
        <Breadcrumbs>
          <Breadcrumbs.Item>Configure</Breadcrumbs.Item>
          <Breadcrumbs.Divider />
          <RouterLink to={basePathSettings}>
            <Breadcrumbs.Item>Settings</Breadcrumbs.Item>
          </RouterLink>
          <Breadcrumbs.Divider />
          <RouterLink to={`${basePathSettings}?page=token-tab`}>
            <Breadcrumbs.Item>Tokens</Breadcrumbs.Item>
          </RouterLink>
          <Breadcrumbs.Divider />
          <Breadcrumbs.Item active={true}>Create Token</Breadcrumbs.Item>
        </Breadcrumbs>
        <div className="flex w-full flex-col items-center justify-center gap-6">
          <div className="flex w-full max-w-[1280px] flex-col items-center justify-center gap-6">
            <div className="flex w-full flex-col items-start justify-center gap-4 py-4">
              <div className="flex w-full justify-between py-1">
                <span className="text-subheader font-subheader text-default-font">
                  Create Token
                </span>
              </div>
              <FormProvider
                methods={methods}
                onSubmit={handleSubmit(addToken)}
                style={{ width: '100%' }}
              >
                <StepBase
                  stepTitle="Name Your Token"
                  stepBody="Provide a name to identify this token."
                  variant={creationStateToStepVariant(
                    workflowState.creationState,
                  )}
                  stepNumber="1"
                  open={workflowState.firstOpen}
                  onOpenChange={(open) => {
                    setWorkflowState((prev) => ({
                      ...prev,
                      firstOpen: open,
                    }));
                    open && logEvent('ingestion-token-enter-token-name-opened');
                  }}
                  actionButtons={
                    <Button
                      disabled={
                        workflowState.creationState === 'processing' ||
                        workflowState.creationState === 'completed'
                      }
                      variant="brand-secondary"
                      size="medium"
                      icon="FeatherCornerDownRight"
                      loading={false}
                      type="submit"
                      onClick={() => {
                        logEvent('ingestion-token-name-marked-as-done');
                        if (!methods.formState.isValid) {
                          validateForm();
                          setWorkflowState((prev) => ({
                            ...prev,
                            firstOpen: true,
                          }));
                        }
                      }}
                    >
                      Mark as Done
                    </Button>
                  }
                >
                  <div className="flex w-full items-center gap-6 rounded shadow-default">
                    <div className="flex items-center gap-1">
                      <span className="text-body font-body text-default-font">
                        Enter Token Name
                      </span>
                      <InfoTooltip tooltipText="Provide a name to identify this Token" />
                    </div>
                    <TextField
                      className="h-auto grow shrink-0 basis-0"
                      error={methods.getFieldState('token_name').invalid}
                      helpText={errors?.token_name?.message}
                    >
                      <TextField.Input
                        {...methods.register('token_name')}
                        disabled={
                          workflowState.creationState === 'processing' ||
                          workflowState.creationState === 'completed'
                        }
                      />
                    </TextField>
                  </div>
                </StepBase>
              </FormProvider>
              <StepBase
                stepTitle="Copy Token"
                stepBody=""
                lastStep={true}
                open={workflowState.lastOpen}
                onOpenChange={(open) => {
                  setWorkflowState((prev) => ({
                    ...prev,
                    lastOpen: open,
                  }));
                  open && logEvent('ingestion-token-copy-opened');
                }}
                stepNumber="2"
              >
                <div className="flex w-full items-start">
                  <span className="w-28 flex-none text-body-bold font-body-bold text-default-font">
                    Token
                  </span>
                  <div className="flex grow shrink-0 basis-0 items-start justify-between">
                    <CopyToClipboard
                      className="h-auto grow shrink-0 basis-0 sensitive select-none cursor-pointer"
                      text={
                        workflowState.creationState !== 'completed'
                          ? CompleteFirstStepText
                          : workflowState.isTokenVisible
                          ? workflowState.token
                          : CopyToClipboardText
                      }
                      clipboardText={workflowState.token}
                    />
                    {workflowState.creationState == 'completed' && (
                      <AtomicTooltip
                        tooltipContent={
                          workflowState.isTokenVisible
                            ? 'Hide Token'
                            : 'Unhide Token'
                        }
                      >
                        <IconButton
                          className="h-8 w-8 flex-none"
                          size="small"
                          icon="FeatherEyeOff"
                          onClick={() => {
                            setWorkflowState((prev) => ({
                              ...prev,
                              isTokenVisible: !prev.isTokenVisible,
                            }));
                          }}
                        />
                      </AtomicTooltip>
                    )}
                  </div>
                </div>
              </StepBase>
              {workflowState.creationState === 'completed' && (
                <RouterLink
                  className="h-auto w-full flex-none"
                  to={`${basePathSettings}?page=token-tab`}
                >
                  <CtaCard
                    className="h-auto w-full flex-none"
                    leftSlot={
                      <IconWithBackground
                        variant="success"
                        size="medium"
                        icon="FeatherCoffee"
                      />
                    }
                  >
                    <span className="text-subheader font-subheader text-default-font">
                      🎉 Your Token is Active!
                    </span>
                    <span className="text-body font-body text-default-font">
                      Return to the Tokens view to manage and view all active
                      tokens.
                    </span>
                  </CtaCard>
                </RouterLink>
              )}
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}
