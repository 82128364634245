import Page from 'components/Page';
import * as SubframeCore from '@subframe/core';
import styles from '../AcmeUpgradesClusters.module.scss';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import { UpcomingUpgradeInstance } from 'src/data/upgradeTypes';
import { useSnackbar } from 'notistack';
import {
  updateUpgradePlan,
  useListClusters,
  useListSubscriptions,
  useListUpgradePlans,
} from 'api/frontend';
import {
  Cluster,
  ClusterSupportLevelType,
  ListClustersResponse,
  UpgradePlan,
} from 'api/models';
import { differenceInDays } from 'date-fns';
import { UPCOMING_CLUSTER_UPGRADES, UPGRADES } from 'src/data/upgrades';
import useLocalStorage from 'hooks/useLocalStorage';
import { ConsoleLoader } from 'components/Loader';
import { CLUSTERS } from 'src/data/version_register_cp';
import { AtomicTooltip } from 'components/design-system';
import { Breadcrumbs } from 'subframe/index';
import { Button } from 'subframe/components/Button';
import { Switch } from 'subframe/components/Switch';
import { InformationalHeader } from 'subframe/components/InformationalHeader';
import { RouterLink } from 'components/RouterLink';
import useUserAccountState from 'hooks/useUserAccountState';
import {
  getTooltipForUpgradeCompletedBy,
  getTooltipForUpgradeRequestedBy,
  showCancel,
  statusBadge,
} from '../helpers/UpgradesHelpers';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { SyntheticEvent, useEffect, useState } from 'react';
import { Alert, Tooltip } from 'subframe/index';

import { somethingWentWrong, toastAutoHideDuration } from 'constants/toasts';
import * as Sentry from '@sentry/browser';

import { useDebounce } from 'react-use';
import { UPGRADE_PLAN_CLUSTERS_PROD_USWEST1 } from 'src/data/upgrades_clusters_prod-uswest2';
import { useConfig } from 'statsig-react';
import { UPGRADE_PLAN_CLUSTERS_LULU } from 'src/data/upgrades_clusters_lululemon';
import { getCurrentlyApplicableSupportLevel } from 'utils/clusterSupportLevelAndEol';
import { ChangeClusterNameEnvironmentDialog } from 'pages/clusters/dialogs/ChangeClusterNameEnvironmentDialog';
import BaseTable from 'components/design-system/Table/BaseTable';
import { Searchbar } from 'components/design-system/Searchbar';
import classNames from 'classnames';
import MarkUpgradePlanAsCompleted from '../components/MarkUpgradePlanAsCompletedDialog';
import { CommentTooltip } from 'components/design-system/CommentTooltip';
import UpgradesMoreOptions from '../components/UpgradesMoreOptions';
import { getClusterProvider, getClusterRegion } from 'utils/clusterStates';

function createUpcomingUpgrades(
  plannedUpgrades: UpgradePlan[],
  clusters: Cluster[] | undefined,
): UpcomingUpgradeInstance[] {
  if (clusters === undefined) {
    return [];
  }
  const plannedClusterIds = new Set(
    plannedUpgrades
      .map((u) => (u.resources.clusters || []).map((c) => c.id))
      .reduce((accumulator, value) => accumulator.concat(value), []),
  );
  return clusters
    .filter(
      (c) =>
        !plannedClusterIds.has(c.id) &&
        c.internal_k8s_ref !== 'example-cluster-ref' &&
        c.status == 'active' &&
        (getCurrentlyApplicableSupportLevel(c)?.end_date ?? 0) > 0 &&
        (differenceInDays(
          new Date(
            (getCurrentlyApplicableSupportLevel(c)?.end_date ?? 0) * 1000,
          ),
          new Date(),
        ) < 90 ||
          getCurrentlyApplicableSupportLevel(c)?.type ===
            ClusterSupportLevelType.LongTermSupport),
    )
    .map((c) => {
      return {
        Id: c.id,
        Name: c.name || '',
        Environment: c.environment,
        CurrentVersions: [c.version],
        Provider: getClusterProvider(c),
        Region: getClusterRegion(c),
        DueDate: getCurrentlyApplicableSupportLevel(c)?.end_date ?? 0,
      };
    });
}

export default function UpgradesClusterIndex() {
  const { account, currentOrganization } = useUserAccountState();
  const { logEvent } = AnalyticsEventLogger();
  const exampleConfig = useConfig('additonal_upgrade_cluster_example');

  const showCreateExamplePlanToast = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    const examplePlanCreateToast =
      'No new Upgrade Plans can be instantiated from example data';
    event.stopPropagation();
    event.preventDefault();
    enqueueSnackbar(examplePlanCreateToast, {
      variant: 'warning',
      autoHideDuration: toastAutoHideDuration,
    });
  };

  const basePath = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/upgrades',
  );
  const newClusterPath = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/clusters/new',
  );
  const { enqueueSnackbar } = useSnackbar();

  const [showExampleData, setShowExampleData] = useLocalStorage<boolean>(
    'example-data',
    true,
  );
  useEffect(() => {
    logEvent('upgrades-clusters-page-view', {
      showExampleData,
    });
  }, [showExampleData]);

  const { data: clusterData, mutate: mutateClusters } = useListClusters(
    {},
    {
      request: { headers: { Authorization: `Bearer ${account.token}` } },
    },
  );
  const { data: upgradesData, mutate } = useListUpgradePlans(
    {},
    { request: { headers: { Authorization: `Bearer ${account.token}` } } },
  );

  const { data: Subscriptions } = useListSubscriptions(
    currentOrganization.slug,
    {},
    {
      request: { headers: { Authorization: `Bearer ${account.token}` } },
    },
  );

  // create example data
  const exampleClusterUpgradeIdsWithStages = [
    UPGRADE_PLAN_CLUSTERS_PROD_USWEST1.id,
  ];
  const exampleClusterUpgrades = UPGRADES;
  if (exampleConfig?.config?.value?.plans) {
    const planIds = exampleConfig.config.value.plans;
    if (planIds.includes(UPGRADE_PLAN_CLUSTERS_LULU.id)) {
      if (
        exampleClusterUpgrades.filter(
          (x) => x.id === UPGRADE_PLAN_CLUSTERS_LULU.id,
        ).length === 0
      ) {
        exampleClusterUpgrades.unshift(UPGRADE_PLAN_CLUSTERS_LULU);
      }
    }
  }
  exampleClusterUpgrades.sort((a, b) => {
    return (
      Number(exampleClusterUpgradeIdsWithStages.includes(b.id)) -
      Number(exampleClusterUpgradeIdsWithStages.includes(a.id))
    );
  });

  // by default show lock is true
  const showLock = Subscriptions
    ? !Subscriptions?.total_entitlement?.features?.includes(
        'request_upgrade_plan',
      )
    : true;
  const realClusters =
    clusterData === undefined
      ? []
      : ((clusterData as ListClustersResponse).data || []).filter(
          (c) => c.internal_k8s_ref !== 'example-cluster-ref',
        );
  const clusterNameMap = new Map(
    (showExampleData
      ? CLUSTERS
      : (clusterData as ListClustersResponse)?.data || []
    ).map((c) => [c.id, c.name]),
  );

  useEffect(() => {
    if (clusterData && localStorage.getItem('example-data') === null) {
      setShowExampleData(!realClusters.length);
    }
  }, [clusterData]);

  const upgrades: UpgradePlan[] | undefined = showExampleData
    ? exampleClusterUpgrades
    : upgradesData?.data?.sort((a, b) => {
        return a.created > b.created ? -1 : 1; // Latest requested Upgrade Plan should appear on top
      });
  const clusterUpgrades =
    upgrades === undefined
      ? undefined
      : upgrades.filter(
          (u) =>
            (u.resources.addons || []).length +
              (u.resources.addon_instances || []).length ===
            0,
        );

  const [searchUpgrade, setSearchUpgrade] = useState('');
  const handleUpgradesOnChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: string,
  ) => {
    setSearchUpgrade(newValue);
  };

  useDebounce(
    () => {
      if (searchUpgrade) {
        logEvent('search-upgrades', { searchUpgrade });
      }
    },
    1000,
    [searchUpgrade],
  );
  const upgradesToShow = searchUpgrade
    ? clusterUpgrades?.filter((item) =>
        item?.name.toLowerCase().includes(searchUpgrade.toLowerCase()),
      )
    : clusterUpgrades;

  const upcoming = showExampleData
    ? UPCOMING_CLUSTER_UPGRADES
    : createUpcomingUpgrades(
        (upgrades || []).filter(
          (u) => u.status === 'pending' || u.status === 'in-progress',
        ),
        realClusters,
      );

  const [clusterToChangeEnvironment, setClusterToChangeEnvironment] =
    useState<Cluster>();

  return (
    <Page title="Cluster Upgrades">
      <div
        className={styles['pageContents']}
        style={{ marginLeft: '32px', width: 'calc(100% - 32px' }}
      >
        <Breadcrumbs>
          <Breadcrumbs.Item>Upgrade Copilot</Breadcrumbs.Item>
          <Breadcrumbs.Divider name="FeatherChevronRight" />
          <Breadcrumbs.Item active={true}>Cluster Upgrades</Breadcrumbs.Item>
        </Breadcrumbs>
        <div className={styles['header']}>
          <div className={styles['stack']}>
            <span
              className={styles['sectionHeaderText']}
              data-cy="upgrade-plan-section-header"
            >
              Cluster Upgrade Plans
            </span>
          </div>
          <div className={styles['stack-a1f5a859']}>
            <span className={styles['text-79dfdadc']}>
              In Progress and Completed Cluster Upgrade Plans
            </span>
            <div className={styles['stack-a4c06eb0']}>
              <span className={styles['text-af9ab9cb']}>Show example data</span>
              <AtomicTooltip
                data-cy="show-example-data-tooltip"
                tooltipContent={
                  showExampleData
                    ? 'Show results from your onboarded clusters'
                    : 'Show example data'
                }
              >
                <Switch
                  data-cy="switch"
                  checked={showExampleData}
                  onCheckedChange={() => {
                    setShowExampleData(!showExampleData);
                    setSearchUpgrade('');
                  }}
                />
              </AtomicTooltip>
            </div>
          </div>
        </div>
        {showExampleData && (
          <Alert
            variant="warning"
            title="You are currently viewing example data"
            description={`To view results from your onboarded clusters, toggle the "Show example data" button`}
          />
        )}
        {!showExampleData && clusterData === undefined && <ConsoleLoader />}
        {!showExampleData &&
          clusterData !== undefined &&
          realClusters.length < 1 && (
            <div className={styles['stack-435f1a02']}>
              <span className={styles['bodyText']}>
                No clusters onboarded so far. Click here to onboard your first
                cluster
              </span>
              <RouterLink to={newClusterPath}>
                <Button
                  size="small"
                  icon="FeatherPlus"
                  onClick={() =>
                    logEvent('upgrades-clusters-onboard-cluster-btn-click')
                  }
                >
                  Add Cluster
                </Button>
              </RouterLink>
            </div>
          )}
        {(showExampleData || realClusters.length > 0) && (
          <>
            <div className={styles['stack-3227bc77']}>
              <span className={styles['subheaderText']}>Executions</span>
              {clusterUpgrades === undefined && <ConsoleLoader />}
              {clusterUpgrades !== undefined && clusterUpgrades.length < 1 && (
                <div className={styles['stack-f4735681']}>
                  <span className={styles['text-0b94c931']}>
                    No Upgrade Plans executed so far.
                  </span>
                </div>
              )}
              {(clusterUpgrades || []).length > 0 && (
                <>
                  <div className={styles['executionsHeader']}>
                    <InformationalHeader>
                      <InformationalHeader.Item
                        data-cy="total-count"
                        title="Total"
                        bodySlot={
                          <span className="text-body-bold font-body-bold text-default-font">
                            {(clusterUpgrades || []).length}
                          </span>
                        }
                      />
                      {(clusterUpgrades || []).filter(
                        (upgr) => upgr.status === 'pending',
                      ).length > 0 && (
                        <InformationalHeader.Item
                          data-cy="pending-count"
                          title="Waiting for Plan"
                          bodySlot={
                            <span className="text-body-bold font-body-bold text-default-font">
                              {
                                (clusterUpgrades || []).filter(
                                  (upgr) => upgr.status === 'pending',
                                ).length
                              }
                            </span>
                          }
                        />
                      )}
                      {(clusterUpgrades || []).filter(
                        (upgr) => upgr.status === 'in-progress',
                      ).length > 0 && (
                        <InformationalHeader.Item
                          data-cy="inprogress-count"
                          title="In Progress"
                          bodySlot={
                            <span className="text-body-bold font-body-bold text-default-font">
                              {
                                (clusterUpgrades || []).filter(
                                  (upgr) => upgr.status === 'in-progress',
                                ).length
                              }
                            </span>
                          }
                        />
                      )}
                      {(clusterUpgrades || []).filter(
                        (upgr) => upgr.status === 'outdated',
                      ).length > 0 && (
                        <InformationalHeader.Item
                          title="Outdated"
                          bodySlot={
                            <span className="text-body-bold font-body-bold text-default-font">
                              {
                                (clusterUpgrades || []).filter(
                                  (upgr) => upgr.status === 'outdated',
                                ).length
                              }
                            </span>
                          }
                        />
                      )}
                      {(clusterUpgrades || []).filter(
                        (upgr) => upgr.status === 'update-in-progress',
                      ).length > 0 && (
                        <InformationalHeader.Item
                          title="Refreshing Plan"
                          bodySlot={
                            <span className="text-body-bold font-body-bold text-default-font">
                              {
                                (clusterUpgrades || []).filter(
                                  (upgr) =>
                                    upgr.status === 'update-in-progress',
                                ).length
                              }
                            </span>
                          }
                        />
                      )}
                      {(clusterUpgrades || []).filter(
                        (upgr) => upgr.status === 'completed',
                      ).length > 0 && (
                        <InformationalHeader.Item
                          data-cy="completed-count"
                          title="Completed"
                          bodySlot={
                            <span className="text-body-bold font-body-bold text-default-font">
                              {
                                (clusterUpgrades || []).filter(
                                  (upgr) => upgr.status === 'completed',
                                ).length
                              }
                            </span>
                          }
                        />
                      )}
                      {(clusterUpgrades || []).filter(
                        (upgr) => upgr.status === 'cancelled',
                      ).length > 0 && (
                        <InformationalHeader.Item
                          data-cy="cancelled-count"
                          title="Canceled"
                          bodySlot={
                            <span className="text-body-bold font-body-bold text-default-font">
                              {
                                (clusterUpgrades || []).filter(
                                  (upgr) => upgr.status === 'cancelled',
                                ).length
                              }
                            </span>
                          }
                        />
                      )}
                      {(clusterUpgrades || []).filter(
                        (upgr) => upgr.status === 'failed',
                      ).length > 0 && (
                        <InformationalHeader.Item
                          data-cy="failed-count"
                          title="Failed"
                          bodySlot={
                            <span className="text-body-bold font-body-bold text-default-font">
                              {
                                (clusterUpgrades || []).filter(
                                  (upgr) => upgr.status === 'failed',
                                ).length
                              }
                            </span>
                          }
                        />
                      )}
                      {(clusterUpgrades || []).filter(
                        (upgr) => upgr.status === 'rolled-back',
                      ).length > 0 && (
                        <InformationalHeader.Item
                          data-cy="rolledback-count"
                          title="Rolled Back"
                          bodySlot={
                            <span className="text-body-bold font-body-bold text-default-font">
                              {
                                (clusterUpgrades || []).filter(
                                  (upgr) => upgr.status === 'rolled-back',
                                ).length
                              }
                            </span>
                          }
                        />
                      )}
                    </InformationalHeader>

                    {clusterUpgrades?.length ? (
                      <Searchbar
                        onChange={handleUpgradesOnChange}
                        view={showExampleData ? 'example' : 'real'}
                      />
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className={styles['stack-1b1228c7']}>
                    <BaseTable
                      settings={{
                        localStorageKey: 'upgrades_clusters_executions',
                        dataCyPrefix: 'upgrades-clusters-executions',
                      }}
                      data={upgradesToShow || []}
                      rowLink={(upgr) =>
                        !showExampleData ||
                        exampleClusterUpgradeIdsWithStages.includes(upgr.id)
                          ? `${basePath}/clusters/${upgr.id}`
                          : ''
                      }
                      rowSettings={{
                        preventClick: (upgr) =>
                          (showExampleData &&
                            !exampleClusterUpgradeIdsWithStages.includes(
                              upgr.id,
                            )) ||
                          upgr.status === 'pending',
                        preventClickToastMessage: showExampleData
                          ? 'This is a demonstration view only at this point - only some Upgrade Plans are available'
                          : 'An Upgrade Plan is being generated for you. You will receive a notification when the Plan is ready.',

                        rowTooltip: (upgr) =>
                          upgr.status === 'pending'
                            ? 'An Upgrade Plan is being generated for you. You will receive a notification when the Plan is ready.'
                            : '',
                      }}
                      noMatchingData={
                        !!clusterUpgrades?.length && !upgradesToShow?.length
                      }
                      columns={[
                        {
                          id: '1',
                          title: 'Plan',
                          titleStyle: { width: '25%' },
                          cellStyle: {
                            width: '30%',
                            height: '80px',
                            flex: '0 0 auto',
                          },
                          cellType: 'cell',
                          render: (upgr) => {
                            return (
                              <div className="flex items-start gap-2 ">
                                <div className="flex flex-col items-start gap-2">
                                  <div className="flex grow shrink-0 basis-0 items-center justify-center gap-2">
                                    <AtomicTooltip tooltipContent={upgr.name}>
                                      <div className="truncate-ellipsis">
                                        <span className="text-body-bold font-body-bold text-neutral-700">
                                          {upgr.name}
                                        </span>
                                      </div>
                                    </AtomicTooltip>
                                  </div>
                                  <div className="flex items-start gap-2">
                                    {upgr.current_version ? (
                                      <span className="text-label font-label text-subtext-color">
                                        {`From: ${upgr.current_version}`}
                                      </span>
                                    ) : (
                                      <></>
                                    )}
                                    {upgr.desired_version ? (
                                      <span className="text-label font-label text-subtext-color">
                                        {`To: ${upgr.desired_version}`}
                                      </span>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>
                                {upgr.status === 'completed' &&
                                  upgr.status_comment && (
                                    <CommentTooltip
                                      userName={upgr?.completed_by?.name}
                                      picture={upgr?.completed_by?.picture}
                                      time={
                                        upgr?.updated
                                          ? upgr?.updated / 1000
                                          : undefined
                                      }
                                      comment={upgr?.status_comment}
                                    />
                                  )}
                              </div>
                            );
                          },
                        },
                        {
                          id: '2',
                          title: 'Status',
                          titleStyle: { width: '10%' },
                          cellType: 'badge-cell',
                          cellProperties: {
                            variant: (upgr) => {
                              return statusBadge[upgr.status].variant;
                            },
                          },
                          render: (upgr) => {
                            return statusBadge[upgr.status].title;
                          },
                        },
                        {
                          id: '3',
                          title: 'Clusters',
                          titleStyle: { width: '15%' },
                          cellType: 'cell',
                          render: (upgr) => {
                            return (
                              <div className={styles['stack-8f363c44']}>
                                <span className={styles['bodyText']}>
                                  {upgr.resources.clusters
                                    ?.slice(0, 2)
                                    ?.map(
                                      (res) =>
                                        clusterNameMap.get(res.id) || res.id,
                                    )
                                    ?.join(', ')}
                                </span>
                                {(upgr.resources.clusters || []).length > 2 && (
                                  <SubframeCore.Tooltip.Provider>
                                    <SubframeCore.Tooltip.Root>
                                      <SubframeCore.Tooltip.Trigger
                                        asChild={true}
                                      >
                                        <span
                                          className={styles['text-cfa53551']}
                                        >
                                          +3 more
                                        </span>
                                      </SubframeCore.Tooltip.Trigger>
                                      <SubframeCore.Tooltip.Portal>
                                        <SubframeCore.Tooltip.Content
                                          side="bottom"
                                          align="center"
                                          sideOffset={8}
                                          asChild={true}
                                        >
                                          <Tooltip className="flex-[0_0_auto] w-auto h-auto max-w-[240px]">
                                            {upgr.resources.clusters
                                              ?.slice(2)
                                              ?.map(
                                                (res) =>
                                                  clusterNameMap.get(res.id) ||
                                                  res.id,
                                              )
                                              ?.join(', ')}
                                          </Tooltip>
                                        </SubframeCore.Tooltip.Content>
                                      </SubframeCore.Tooltip.Portal>
                                    </SubframeCore.Tooltip.Root>
                                  </SubframeCore.Tooltip.Provider>
                                )}
                              </div>
                            );
                          },
                        },
                        {
                          id: '5',
                          title: 'Requested by',
                          titleStyle: { width: '15%' },
                          cellType: 'cell',
                          render: (upgr) => {
                            return (
                              <>
                                {upgr.requested_by?.name && upgr.created ? (
                                  <AtomicTooltip
                                    className="ph-no-capture"
                                    tooltipContent={getTooltipForUpgradeRequestedBy(
                                      upgr.requested_by?.name,
                                      upgr.created,
                                    )}
                                  >
                                    <span
                                      className={classNames(
                                        styles['bodyText'],
                                        'ph-no-capture',
                                      )}
                                    >
                                      {upgr.requested_by?.name || ''}
                                    </span>
                                  </AtomicTooltip>
                                ) : (
                                  <span className={styles['bodyText']}></span>
                                )}
                              </>
                            );
                          },
                        },
                        {
                          id: '6',
                          title: 'Marked Completed by',
                          titleStyle: { width: '15%' },
                          cellType: 'cell',
                          render: (upgr) => {
                            return (
                              <div className="flex grow shrink-0 basis-0 items-start gap-2">
                                {upgr.completed_by?.name && upgr.updated ? (
                                  <>
                                    <AtomicTooltip
                                      tooltipContent={getTooltipForUpgradeCompletedBy(
                                        upgr.completed_by?.name,
                                        upgr.updated,
                                      )}
                                      className="ph-no-capture"
                                    >
                                      <span
                                        className={classNames(
                                          styles['bodyText'],
                                          'ph-no-capture',
                                        )}
                                      >
                                        {upgr.completed_by?.name || ''}
                                      </span>
                                    </AtomicTooltip>
                                  </>
                                ) : (
                                  <span className={styles['bodyText']}></span>
                                )}
                              </div>
                            );
                          },
                        },
                      ]}
                      actions={[
                        {
                          id: 'navigate',
                          render: (upgr) => {
                            return (
                              <div className={styles['stack-1b79c295']}>
                                <div
                                  className={styles['stack-1c3bdfbc']}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                  }}
                                  onContextMenu={(e) => {
                                    e.preventDefault();
                                  }}
                                >
                                  {upgr.status === 'in-progress' && (
                                    <MarkUpgradePlanAsCompleted
                                      upgradeId={upgr.id}
                                      mutatePlan={() => mutate()}
                                      isFromLandingPage={true}
                                    />
                                  )}
                                  <UpgradesMoreOptions
                                    upgrade={upgr}
                                    isTemplate={false}
                                    allowCancel={showCancel(upgr)}
                                    onConfirmEditName={async (newName) => {
                                      if (showExampleData) {
                                        enqueueSnackbar(
                                          'Example plans cannot be updated',
                                          {
                                            variant: 'warning',
                                            autoHideDuration:
                                              toastAutoHideDuration,
                                          },
                                        );
                                      } else {
                                        try {
                                          await updateUpgradePlan(
                                            upgr.id,
                                            { name: newName },
                                            {
                                              headers: {
                                                Authorization: `Bearer ${account.token}`,
                                              },
                                            },
                                          );
                                          logEvent(
                                            'upgrades-clusters-plan-name-change',
                                            {
                                              planId: upgr.id,
                                              name: newName,
                                            },
                                          );
                                          mutate();
                                          enqueueSnackbar(
                                            'Successfully edited requested properties of the Upgrade Plan',
                                            {
                                              variant: 'success',
                                              autoHideDuration:
                                                toastAutoHideDuration,
                                            },
                                          );
                                        } catch (err) {
                                          enqueueSnackbar(
                                            somethingWentWrong.replace(
                                              '<action>',
                                              'updating requested properties of the Upgrade Plan',
                                            ),
                                            {
                                              variant: 'error',
                                              autoHideDuration:
                                                toastAutoHideDuration,
                                            },
                                          );
                                          Sentry.captureException(err);
                                        }
                                      }
                                    }}
                                    onConfirmCancel={async () => {
                                      if (showExampleData) {
                                        enqueueSnackbar(
                                          'Example plans cannot be canceled',
                                          {
                                            variant: 'warning',
                                            autoHideDuration:
                                              toastAutoHideDuration,
                                          },
                                        );
                                      } else {
                                        try {
                                          await updateUpgradePlan(
                                            upgr.id,
                                            { status: 'cancelled' },
                                            {
                                              headers: {
                                                Authorization: `Bearer ${account.token}`,
                                              },
                                            },
                                          );
                                          logEvent(
                                            'upgrades-clusters-plan-cancel',
                                            {
                                              planId: upgr.id,
                                              name: upgr.name,
                                            },
                                          );
                                          mutate();
                                          enqueueSnackbar(
                                            'Successfully canceled Upgrade Plan',
                                            {
                                              variant: 'success',
                                              autoHideDuration:
                                                toastAutoHideDuration,
                                            },
                                          );
                                        } catch (err) {
                                          enqueueSnackbar(
                                            somethingWentWrong.replace(
                                              '<action>',
                                              'canceling this Upgrade Plan',
                                            ),
                                            {
                                              variant: 'error',
                                              autoHideDuration:
                                                toastAutoHideDuration,
                                            },
                                          );
                                          Sentry.captureException(err);
                                        }
                                      }
                                    }}
                                  />
                                </div>

                                {upgr.status !== 'pending' && (
                                  <SubframeCore.Icon
                                    className={styles['bodyText']}
                                    name="FeatherChevronRight"
                                  />
                                )}
                                {upgr.status === 'pending' && (
                                  <SubframeCore.Icon
                                    className={styles['icon-ef8c001b']}
                                    name="FeatherChevronRight"
                                  />
                                )}
                              </div>
                            );
                          },
                        },
                      ]}
                    />
                  </div>
                </>
              )}
            </div>
          </>
        )}
        {clusterToChangeEnvironment && (
          <ChangeClusterNameEnvironmentDialog
            clusterDetails={{
              id: clusterToChangeEnvironment.id,
              name: clusterToChangeEnvironment.name || '',
              environment: clusterToChangeEnvironment.environment || '',
            }}
            allowNameChange={
              clusterToChangeEnvironment.agent_managed_properties?.name ===
              undefined
            }
            allowEnvironmentChange={
              clusterToChangeEnvironment?.agent_managed_properties
                ?.environment === undefined
            }
            onClusterNameEnvironmentChange={() => {
              mutateClusters && mutateClusters();
            }}
            open={Boolean(clusterToChangeEnvironment?.id)}
            onClose={() => {
              mutateClusters && mutateClusters();
              setClusterToChangeEnvironment(undefined);
            }}
          />
        )}
      </div>
    </Page>
  );
}
