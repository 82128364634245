import { Loader } from '@subframe/core/dist/cjs';
import {
  createUpgradePlanStageStepComment,
  createUpgradeTemplateStageStepComment,
  useListUpgradePlanStages,
  useListUpgradePlanStageStepComments,
  useListUpgradeTemplateStages,
  useListUpgradeTemplateStageStepComments,
} from 'api/frontend';
import {
  UpgradePlan,
  UpgradeStage,
  UpgradeStageStatus,
  UpgradeStageStep,
  UpgradeTemplate,
} from 'api/models';
import { somethingWentWrong } from 'constants/toasts';
import useUserAccountState from 'hooks/useUserAccountState';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { UPGRADES, UPGRADE_STAGES, UPGRADE_TEMPLATES } from 'src/data/upgrades';
import {
  Avatar,
  ChatMessage,
  Conversation,
  LogoIcon,
  ReplyMessageBase,
} from 'subframe/index';
import { getHumanReadableDateAndTime } from 'utils/formatTime';
import styles from './StepSidebar.module.scss';
import * as Sentry from '@sentry/browser';
import StepDrawerCommentsForChkk from './StepDrawerCommentsForChkk';
import StepDrawerCommentsForTeam from './StepDrawerCommentsForTeam';
import { StepActivityFeed } from './StepActivityFeed';

function getStatusBadgeVariant(
  status: UpgradeStageStatus,
): 'brand' | 'neutral' | 'error' | 'warning' | 'success' {
  switch (status) {
    case 'active':
    case 'blocked':
      return 'neutral';
    case 'skipped':
      return 'warning';
    case 'completed':
      return 'success';
    case 'in-progress':
      return 'neutral';
  }
}

interface UpgradeStepSidebarProps {
  upgrade: UpgradePlan;
  stage: UpgradeStage;
  step: UpgradeStageStep;
  isTemplate?: boolean;
}

export function UpgradeStepSidebar(props: UpgradeStepSidebarProps) {
  const { user, account } = useUserAccountState();
  const showExampleData =
    props.upgrade?.id &&
    (UPGRADES.map((upgr) => upgr.id).includes(props.upgrade?.id) ||
      UPGRADE_TEMPLATES.map((upgr) => upgr.id).includes(props.upgrade?.id))
      ? true
      : false;
  // TODO (laiba): fix DrawerProvider context handling, to avoid processing stages data again for threadId,
  // (had to do it since Sidebar doesn't re-renders on /stages mutate in main UpgradesView)
  const { data: upgradeStagesData, mutate } = props.isTemplate
    ? useListUpgradeTemplateStages(
        props.upgrade.id || '',
        { page_size: 100 },
        {
          request: { headers: { Authorization: `Bearer ${account.token}` } },
          swr: {
            enabled: !showExampleData,
          },
        },
      )
    : useListUpgradePlanStages(
        props.upgrade.id || '',
        { page_size: 100 },
        {
          request: { headers: { Authorization: `Bearer ${account.token}` } },
          swr: {
            enabled: !showExampleData,
          },
        },
      );

  const stages = (
    (showExampleData
      ? UPGRADE_STAGES.get(props.upgrade.id || '')
      : upgradeStagesData?.data) || []
  ).sort((a, b) => a.stage_number - b.stage_number);
  const currentStage = stages.find(
    (stage) => stage.stage_number === props.stage.stage_number,
  );
  const currentStep = currentStage?.steps.find(
    (step) => step.step_number === props.step.step_number,
  );
  const stepCommentThreadId = currentStep?.comments.thread_id || '';

  return (
    <div className={styles['stack-d78aa573']}>
      <div className="flex items-start gap-2 px-1 py-1">
        <Avatar image={user.profilePicture} />
        <span className="text-subheader font-subheader text-default-font">
          {`${user.name} : ${user.email}`}
        </span>
      </div>
      <StepActivityFeed
        upgrade={props.upgrade}
        stage={props.stage}
        step={props.step}
        isTemplate={props.isTemplate}
        showExampleData={showExampleData}
      />
      <StepDrawerCommentsForTeam
        upgrade={props.upgrade}
        stage={props.stage}
        step={props.step}
        showExampleData={showExampleData}
        isTemplate={props.isTemplate}
        commentThreadId={stepCommentThreadId}
        onChange={() => {
          mutate();
        }}
      />
      {props.isTemplate && (
        <StepDrawerCommentsForChkk
          upgrade={props.upgrade as UpgradeTemplate}
          stage={props.stage}
          step={props.step}
          showExampleData={showExampleData}
          commentThreadId={stepCommentThreadId}
          onChange={() => {
            mutate();
          }}
        />
      )}
    </div>
  );
}

interface StepCommentsProps {
  showExampleData: boolean;
  upgrade: UpgradePlan;
  stage: UpgradeStage;
  step: UpgradeStageStep;
  isTemplate?: boolean;
}

function StepComments(props: StepCommentsProps) {
  const { account } = useUserAccountState();
  const { enqueueSnackbar } = useSnackbar();
  const { data: commentsData, mutate: commentsMutate } = props.isTemplate
    ? useListUpgradeTemplateStageStepComments(
        props.upgrade.id,
        props.stage.stage_number.toFixed(),
        props.step.step_number.toFixed(),
        {},
        {
          request: {
            headers: { Authorization: `Bearer ${account.token}` },
          },
        },
      )
    : useListUpgradePlanStageStepComments(
        props.upgrade.id,
        props.stage.stage_number.toFixed(),
        props.step.step_number.toFixed(),
        {},
        {
          request: {
            headers: { Authorization: `Bearer ${account.token}` },
          },
          swr: {
            enabled: !props.showExampleData,
          },
        },
      );
  const [isSending, setIsSending] = useState(false);

  return (
    <div
      style={{
        overflow: 'auto',
        maxHeight: 'calc(99vh - 300px)',
        width: '100%',
        paddingRight: '8px',
      }}
    >
      {!props.showExampleData && commentsData === undefined && <Loader />}
      {!props.showExampleData && commentsData !== undefined && (
        <Conversation
          replyElements={
            <ReplyMessageBase
              uploadBadge={undefined}
              prompts={<></>}
              disableUpload={true}
              isSending={isSending}
              disableActionsString={
                props.upgrade.status === 'pending' ||
                props.upgrade.status === 'cancelled' ||
                props.upgrade.status === 'completed'
                  ? `The Upgrade ${
                      props.isTemplate ? 'Template' : 'Plan'
                    } has concluded and can no longer be commented on.`
                  : undefined
              }
              onSend={async (message) => {
                try {
                  setIsSending(true);
                  if (props.isTemplate) {
                    await createUpgradeTemplateStageStepComment(
                      props.upgrade.id,
                      props.stage.stage_number.toFixed(),
                      props.step.step_number.toFixed(),
                      { comment: message, is_for_chkk: true },
                      {
                        headers: {
                          Authorization: `Bearer ${account.token}`,
                        },
                      },
                    );
                  } else {
                    await createUpgradePlanStageStepComment(
                      props.upgrade.id,
                      props.stage.stage_number.toFixed(),
                      props.step.step_number.toFixed(),
                      { comment: message },
                      {
                        headers: {
                          Authorization: `Bearer ${account.token}`,
                        },
                      },
                    );
                  }
                  await commentsMutate();
                } catch (err) {
                  Sentry.captureException(err);
                  enqueueSnackbar(
                    somethingWentWrong.replace('<action>', 'posting comment'),
                    { variant: 'error' },
                  );
                } finally {
                  setIsSending(false);
                }
              }}
            />
          }
          className="border-none p-0"
        >
          {commentsData.data.map((item, idx) => (
            <ChatMessage
              key={idx}
              name={item.user?.name || 'Chkk'}
              message={item.comment}
              time={getHumanReadableDateAndTime(item.time)} // same date time format as in Cluster Properties
              avatar={
                item.user === undefined ? (
                  <Avatar variant="brand" size="small">
                    C
                  </Avatar>
                ) : (
                  <Avatar
                    size="small"
                    image={item.user.picture}
                    className="ph-no-capture"
                  />
                )
              }
            />
          ))}
        </Conversation>
      )}
      {props.showExampleData && (
        <Conversation
          replyElements={
            <ReplyMessageBase
              uploadBadge={undefined}
              prompts={<></>}
              disableUpload={true}
              disableActionsString="This is demonstration data and cannot be commented on."
              isSending={false}
            />
          }
          className="border-none p-0"
        >
          <ChatMessage
            name="Eva Eeg"
            message="Do you have any certificates managed outside cert-manager?"
            avatar={
              <LogoIcon
                size="medium"
                image="https://res.cloudinary.com/demo/image/upload/v1674762771/Logo_ndnxzk.png"
              />
            }
          />
          <ChatMessage
            time="4:20pm"
            message="No, all our certs are in cert-manager"
            name="Katy Smith"
            avatar={<Avatar>KS</Avatar>}
          />
          <ChatMessage
            name="Eva Eeg"
            time="5:12pm"
            message="Can you take some downtime when updating cert-manager?"
            avatar={
              <LogoIcon
                size="medium"
                image="https://res.cloudinary.com/demo/image/upload/v1674762771/Logo_ndnxzk.png"
              />
            }
          />
          <ChatMessage
            time="7:55pm"
            message="Let me share our cluster locations"
            name="Katy Smith"
            avatar={<Avatar>KS</Avatar>}
          />
          <ChatMessage
            name="Eva Eeg"
            time="8:22pm"
            message="Are you ready to fix this now?"
            avatar={
              <LogoIcon
                size="medium"
                image="https://res.cloudinary.com/demo/image/upload/v1674762771/Logo_ndnxzk.png"
              />
            }
          />
          <ChatMessage
            time="8:52pm"
            message="I am seeing something suspicious in the logs. Can you have a
                look?"
            name="Katy Smith"
            avatar={<Avatar>KS</Avatar>}
          />
        </Conversation>
      )}
    </div>
  );
}
