import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';

import { ClusterDetailsBreadCrumbsHeader } from 'components/BreadCrumbsHeaders';
import { AtomicTooltip } from 'components/design-system';
import { IconButton, SkeletonText } from 'subframe/index';

export type ClusterTabsType = {
  key: string;
  id: string;
  title: string;
  trackerEvent: string;
  component: JSX.Element | undefined;
  icon: string;
  skeleton: JSX.Element | undefined;
}[];

type Props = {
  title: string | undefined;
  clusterId: string;
  clusterStatus: string;
  lastScanText: string;
  rescanComplete: boolean | null;
  triggerRescan: () => Promise<void>;
  isRescanButtonDisabled: boolean;
};

// ---------------------------------------

export default function ClusterDetailsSectionHeader({
  title,
  lastScanText,
  rescanComplete,
  triggerRescan,
  isRescanButtonDisabled,
  clusterId,
  clusterStatus,
}: Props) {
  const { logEvent } = AnalyticsEventLogger();

  const isRescanLoading =
    (!lastScanText || rescanComplete === false) &&
    clusterStatus.toLowerCase() === 'active';

  const isRescanDisabled =
    clusterStatus.toLowerCase() !== 'active' || isRescanButtonDisabled;
  return (
    <div className="flex w-full items-start justify-between">
      <ClusterDetailsBreadCrumbsHeader
        clusterName={title}
        clusterId={clusterId}
      />

      <div className="flex items-start gap-2" id="cluster-details-last-scan">
        {lastScanText ? (
          <span className="text-body font-body text-subtext-color">
            {lastScanText}
          </span>
        ) : (
          <SkeletonText className="h-5 w-28 flex-none" />
        )}

        <AtomicTooltip
          tooltipContent={
            clusterStatus.toLowerCase() !== 'active'
              ? 'Rescan is not possible on Deactivated clusters.'
              : rescanComplete === false || !lastScanText
              ? 'Rescanning Cluster'
              : 'Rescan Cluster'
          }
        >
          <IconButton
            size="small"
            icon="FeatherRotateCw"
            onClick={(event) => {
              if (event?.stopPropagation) {
                event.stopPropagation();
                triggerRescan();
                logEvent('rescan-cluster', {
                  clusterId,
                });
              }
            }}
            disabled={isRescanLoading || isRescanDisabled}
            loading={isRescanLoading}
          />
        </AtomicTooltip>
      </div>
    </div>
  );
}
