import React, { useEffect, useState } from 'react';
// hooks
import useAccountIdRoute from 'hooks/useAccountIdRoute';
// types
import { AxiosRequestConfig } from 'axios';
import { ClusterCardStats } from 'components/ClusterCard';
// constants
import { isArray, isEmpty } from 'lodash';
import { Badge, Button, ClusterCard } from 'subframe/index';
import { listClusterScans, updateCluster, useListClusters } from 'api/frontend';
import * as Sentry from '@sentry/browser';
import { RouterLink } from 'components/RouterLink';
import useUserAccountState from 'hooks/useUserAccountState';

import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { Loader } from '@subframe/core';
import { Cluster, ClusterStatus } from 'api/models';
import isExampleCluster from 'utils/isExampleCluster';
import { useSnackbar } from 'notistack';
import { formatDistanceToNow, fromUnixTime } from 'date-fns';
import { AtomicTooltip } from 'components/design-system';
import DeactivateClusterConfirmationDialog from 'components/dialogs/DeactivateClusterConfirmationDialog';
import { TabsWithContent } from 'components/design-system/Tabs';
import { somethingWentWrong, toastAutoHideDuration } from 'constants/toasts';
import { getClusterProvider } from 'utils/clusterStates';

interface ClustersSettingProps {
  view: string;
}

const SAMPLE_ROUTE = 'clusters/new';

const enum ClusterStatusActions {
  ACTIVATE,
  DEACTIVATE,
}

const ClustersSetting: React.FC<ClustersSettingProps> = ({ view }) => {
  const { account } = useUserAccountState();
  const { logEvent } = AnalyticsEventLogger();
  const { enqueueSnackbar } = useSnackbar();
  const [showLoading, setShowLoading] = useState<boolean>(true);
  const [statsMap, setStatsMap] = useState<Record<string, ClusterCardStats[]>>(
    {},
  );

  const [currentTab, setCurrentTab] = useState(
    view ? `${view}-clusters` : 'active-clusters',
  );

  const path = useAccountIdRoute('/orgs/:orgId/accounts/:accountId/clusters');
  const basePath = useAccountIdRoute('/orgs/:orgId/accounts/:accountId/');
  const newPath = `${basePath}${SAMPLE_ROUTE}`;
  const [deactivateClusterDialogOpen, setDeactivateClusterDialogOpen] =
    useState(false);
  const [currentCluster, setCurrentCluster] = useState<Cluster | null>(null);

  const config = {} as AxiosRequestConfig;
  config.headers = {
    Authorization: `Bearer ${account?.token}`,
  };
  const options = {
    swr: {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
    request: config,
  };

  const defaultStats: ClusterCardStats = {
    totalSignatures: 0,
    lars: 0,
    timestamp: 0,
    status: '',
  };

  const { data: clusters, mutate } = useListClusters({}, options);

  const statsToShow = (stats) => {
    //finds the first completed scan
    if (stats?.length) {
      const s = stats.find((s) => s.status === 'complete'); // find first complete scan
      return s || defaultStats;
    } else {
      return defaultStats;
    }
  };
  const getAllPrechecksRuns = (data: Cluster[], token: string) => {
    const rs = data.map((c: Cluster) => {
      return listClusterScans(
        c.id,
        { page_size: 1 },
        {
          headers: { authorization: 'Bearer ' + token },
        },
      );
    });
    return rs;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (clusters && clusters?.data?.length) {
          const precheckRuns = await getAllPrechecksRuns(
            clusters.data,
            account?.token,
          );
          if (precheckRuns.length) {
            const m: { [key: string]: ClusterCardStats[] } = {};
            const res = await Promise.all(precheckRuns);
            res.forEach((r) => {
              if (r.data.length) {
                m[r.data[0].cluster_id] = r.data.map((d) => ({
                  totalSignatures: d.signature_count,
                  lars: d.lar_count,
                  timestamp: Number(d.created),
                  status: d.status,
                }));
              }
            });
            setStatsMap({ ...m });
            setShowLoading(false);
          }
        }
      } catch (error) {
        Sentry.captureException(error);
        setShowLoading(false);
      }
    };
    fetchData();
  }, [clusters, account?.token]);
  const sortClusters = (clusters: Cluster[]) => {
    return clusters.sort((a, b) => {
      if (isExampleCluster(a)) {
        return -1;
      } else if (isExampleCluster(b)) {
        return 1;
      } else {
        return a.created > b.created ? -1 : 1;
      }
    });
  };

  const updateClusterStatus = async (
    cluster: Cluster,
    newStatus: ClusterStatus,
  ) => {
    const action =
      newStatus === 'active'
        ? ClusterStatusActions.ACTIVATE
        : ClusterStatusActions.DEACTIVATE;
    logEvent('update-cluster-status', {
      clusterId: cluster.id,
      newStatus: newStatus,
    });
    try {
      const headers = {
        Authorization: `Bearer ${account?.token}`,
      };
      await updateCluster(
        cluster.id,
        {
          status: newStatus,
        },
        { headers },
      );

      enqueueSnackbar(
        `Cluster ${
          action === ClusterStatusActions.ACTIVATE ? 'activated' : 'deactivated'
        } successfully`,
        {
          variant: 'success',
          autoHideDuration: toastAutoHideDuration,
        },
      );
      await mutate();
    } catch (error) {
      enqueueSnackbar(
        somethingWentWrong.replace(
          '<action>',
          action === ClusterStatusActions.ACTIVATE
            ? 'activating this cluster'
            : 'deactivating this cluster',
        ),
        {
          variant: 'error',
          autoHideDuration: toastAutoHideDuration,
        },
      );
      Sentry.captureException(error);
    }
  };
  const activeClusters =
    clusters?.data?.filter(
      (c: Cluster) => c.status?.toLowerCase() === 'active',
    ) || [];

  const deactivatedClusters =
    clusters?.data?.filter(
      (c: Cluster) => c.status?.toLowerCase() === 'deactivated',
    ) || [];

  const CLUSTER_TABS = [
    {
      id: 'active-clusters',
      title: 'Active Clusters',
      content: showLoading ? (
        <div>
          {/* todo (laiba): replace with skeleton */}
          <Loader />
        </div>
      ) : (
        <>
          {isArray(activeClusters) && !isEmpty(activeClusters) ? (
            sortClusters(activeClusters).map((cluster: Cluster) => {
              return (
                <RouterLink
                  key={cluster?.id}
                  to={`${path}/${cluster?.id}?view=properties`}
                >
                  <ClusterCard
                    id="settings-cluster-card"
                    className="w-144"
                    title={cluster?.name}
                    description={`${getClusterProvider(cluster)} | ${
                      cluster?.version
                    } | Last scan ${
                      statsToShow(statsMap[cluster?.id])?.timestamp
                        ? formatDistanceToNow(
                            fromUnixTime(
                              statsToShow(statsMap[cluster?.id])?.timestamp,
                            ),
                            {
                              addSuffix: true,
                            },
                          )
                        : '-'
                    }`}
                    badges={
                      cluster.environment ? (
                        <AtomicTooltip tooltipContent={cluster.environment}>
                          <Badge variant="neutral" className="max-w-[100px]">
                            {cluster.environment}
                          </Badge>
                        </AtomicTooltip>
                      ) : (
                        <></>
                      )
                    }
                    body={<></>}
                    buttons={
                      <Button
                        variant="destructive-tertiary"
                        icon="FeatherX"
                        size="medium"
                        loading={
                          deactivateClusterDialogOpen &&
                          currentCluster?.id === cluster.id
                        }
                        disabled={
                          deactivateClusterDialogOpen &&
                          currentCluster?.id === cluster.id
                        }
                        onClick={(event) => {
                          event.stopPropagation();
                          event.preventDefault();
                          setCurrentCluster(cluster);
                          setDeactivateClusterDialogOpen(true);
                        }}
                      >
                        Deactivate
                      </Button>
                    }
                  />
                </RouterLink>
              );
            })
          ) : (
            <div>
              <span className="text-subheader text-default-font">
                No Active clusters found
              </span>
            </div>
          )}
        </>
      ),
    },
    {
      id: 'deactivated-clusters',
      title: 'Deactivated Clusters',
      content: showLoading ? (
        <div>
          {/* todo (laiba): replace with skeleton */}
          <Loader />
        </div>
      ) : (
        <>
          {isArray(deactivatedClusters) && !isEmpty(deactivatedClusters) ? (
            sortClusters(deactivatedClusters).map((cluster: Cluster) => {
              return (
                <RouterLink
                  key={cluster?.id}
                  to={`${path}/${cluster?.id}?view=properties`}
                >
                  <ClusterCard
                    id="settings-cluster-card"
                    className="w-144"
                    title={cluster?.name}
                    description={`${getClusterProvider(cluster)} | ${
                      cluster?.version
                    } | Last scan ${
                      statsToShow(statsMap[cluster?.id])?.timestamp
                        ? formatDistanceToNow(
                            fromUnixTime(
                              statsToShow(statsMap[cluster?.id])?.timestamp,
                            ),
                            {
                              addSuffix: true,
                            },
                          )
                        : '-'
                    }`}
                    badges={
                      cluster.environment ? (
                        <AtomicTooltip tooltipContent={cluster.environment}>
                          <Badge variant="neutral" className="max-w-[100px]">
                            {cluster.environment}
                          </Badge>
                        </AtomicTooltip>
                      ) : (
                        <></>
                      )
                    }
                    body={<></>}
                    buttons={
                      <Button
                        variant="brand-tertiary"
                        icon={null}
                        size="medium"
                        loading={
                          showLoading || currentCluster?.id === cluster.id
                        }
                        disabled={
                          showLoading || currentCluster?.id === cluster.id
                        }
                        onClick={(event) => {
                          event.stopPropagation();
                          event.preventDefault();
                          setCurrentCluster(cluster);
                          updateClusterStatus(cluster, 'active');
                        }}
                      >
                        Activate Cluster
                      </Button>
                    }
                  />
                </RouterLink>
              );
            })
          ) : (
            <div>
              <span className="text-subheader text-default-font">
                No Deactivated clusters found
              </span>
            </div>
          )}
        </>
      ),
    },
  ];

  return (
    <div className="flex w-full flex-col items-center justify-center gap-6">
      <div className="flex w-full max-w-xl flex-col items-center justify-center gap-6">
        <div className="flex w-full items-center justify-between px-0 py-1">
          <span className="text-subheader text-default-font">
            Your Clusters
          </span>
          <RouterLink to={newPath}>
            <Button
              id="add-cluster-button"
              variant="brand-primary"
              icon="FeatherPlus"
            >
              Add Cluster
            </Button>
          </RouterLink>
        </div>
        <TabsWithContent
          initialTabId={currentTab}
          onTabChange={(newTabId) => {
            logEvent('clusters-settings-tab-changed', {
              tab: newTabId,
            });
            setCurrentTab(newTabId);
          }}
          tabs={CLUSTER_TABS}
        />
        <DeactivateClusterConfirmationDialog
          open={deactivateClusterDialogOpen && Boolean(currentCluster?.id)}
          onClose={async () => {
            setDeactivateClusterDialogOpen(false);
            await mutate();
            setCurrentCluster(null);
          }}
          clusterId={currentCluster?.id || ''}
          isExampleCluster={
            currentCluster ? isExampleCluster(currentCluster) : false
          }
        />
      </div>
    </div>
  );
};

export default ClustersSetting;
