// This is for backward compatibility with the URL format being used in the email invitations.
import { TeamInvitation } from 'api/models';
import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSessionStorage } from 'react-use';

const AcceptRedirect = () => {
  const { id } = useParams(); // Get the :id parameter from the route
  const navigate = useNavigate();
  const [pendingInvitations, setPendingInvitations] = useSessionStorage<
    TeamInvitation[]
  >('pending_invitations', []);

  useEffect(() => {
    // Redirect to the new URL format
    if (id) {
      if (!pendingInvitations) {
        navigate(`/login?redirect_to=accept?id=${id}`);
      } else {
        navigate(`/accept?id=${id}`); // Replace the current entry in history
      }
    }
  }, [id, navigate]);

  return null; // Since this is a redirect, no need to render anything
};

export default AcceptRedirect;
