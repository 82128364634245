import * as SubframeCore from '@subframe/core';
import { AccountStats, Cluster } from 'api/models';
import { SetStateFunction } from 'models/react';
import { SyntheticEvent, useMemo, useState } from 'react';
import {
  Button,
  Checkbox,
  CheckboxGroup,
  IconButton,
  Select,
  SkeletonText,
  Tooltip,
} from 'subframe/index';
import { Searchbar } from 'components/design-system/Searchbar';
import { extractMajorMinor } from 'utils/extractMajorMinor';
import {
  ClusterFilters,
  ClustersViewLayout,
  ClustersViewLayoutValues,
} from '../models';
import RiskLedgerClustersViews from './RiskLedgerClustersViews';
import { useDebounce } from 'react-use';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { truncateAtLength } from 'utils/formatString';

interface RiskLedgerClustersDataProps {
  clusters: Cluster[];
  isLoadingClusters: boolean;
  showExampleData: boolean | undefined;
  setFilters: SetStateFunction<ClusterFilters | undefined>;
  filters: ClusterFilters | undefined;
  handleOnChange: (
    event: SyntheticEvent<Element, Event>,
    newValue: string,
  ) => void;
  mutateClusters: () => void;
  clusterViewLayout: ClustersViewLayoutValues;
  handleAlignment: (newAlignment: ClustersViewLayoutValues) => void;
  isTotalClustersLoading: boolean;
  clustersToShowFilterLoading: boolean;
  noClustersFound: boolean;
  accountStats: AccountStats | undefined;
  isLoadingAccountStats: boolean;
  noMatchingClustersFound: boolean;
}

function RiskLedgerActionHeader({
  clusters,
  isLoadingClusters,
  showExampleData,
  setFilters,
  filters,
  handleOnChange,
  mutateClusters,
  clusterViewLayout,
  handleAlignment,
  isTotalClustersLoading,
  clustersToShowFilterLoading,
  noClustersFound,
  accountStats,
  isLoadingAccountStats,
  noMatchingClustersFound,
}: RiskLedgerClustersDataProps) {
  const [clusterFilters, setClusterFilters] = useState<ClusterFilters>({
    environments: filters?.environments || ([] as string[]),
    versions: filters?.versions || ([] as string[]),
    providers: filters?.providers || ([] as string[]),
  });
  const { logEvent } = AnalyticsEventLogger();

  const clusterEnvironments =
    accountStats?.clusters_summary?.environments || [];
  const clusterVersions = accountStats?.clusters_summary?.versions || [];
  const clusterProviders = accountStats?.clusters_summary?.providers || [];

  useDebounce(
    () => {
      if (clusterFilters) {
        logEvent('filter-selected', filters);
        onFiltersChange();
      }
    },
    1000,
    [clusterFilters],
  );

  const onFiltersChange = () => {
    if (
      !!clusterFilters.environments ||
      !!clusterFilters.versions ||
      !!clusterFilters.providers
    ) {
      const filter = {
        environments: [] as string[],
        versions: [] as string[],
        providers: [] as string[],
      };

      // Only add environments if it's not empty
      if (
        clusterFilters?.environments &&
        clusterFilters?.environments.length > 0
      ) {
        filter.environments = clusterFilters?.environments;
      }

      // Only add versions if it's not empty
      if (clusterFilters?.versions && clusterFilters?.versions.length > 0) {
        filter.versions = clusterFilters?.versions;
      }

      // Only add providers if it's not empty
      if (clusterFilters?.providers && clusterFilters?.providers.length > 0) {
        filter.providers = clusterFilters?.providers;
      }

      // Call the setFilters function with the filters object
      setFilters(filter);
    }
  };

  const { uniqueEnvironments, uniqueVersions, uniqueProviders } =
    useMemo(() => {
      const environments = new Set<string>();
      const versions = new Set<string>();
      const providers = new Set<string>();

      clusterEnvironments.forEach((env) => {
        env !== '' && environments.add(env);
      });
      clusterVersions.forEach((ver) => {
        versions.add(extractMajorMinor(ver));
      });
      clusterProviders.forEach((provider) => {
        providers.add(provider);
      });

      return {
        uniqueEnvironments: Array.from(environments).sort((a, b) =>
          a.localeCompare(b),
        ),
        uniqueVersions: Array.from(versions).sort((a, b) => a.localeCompare(b)),
        uniqueProviders: Array.from(providers).sort((a, b) =>
          a.localeCompare(b),
        ),
      };
    }, [clusterEnvironments, clusterProviders, clusterVersions]);

  const isDropdownDisabled = isLoadingAccountStats || showExampleData;
  return (
    <>
      {(!noClustersFound || noMatchingClustersFound) && (
        <div className="flex w-full flex-col items-start gap-4">
          <div className="flex w-full items-center justify-end gap-2">
            {/* TODO: replace with Subframe Searchbar */}
            <Searchbar
              onChange={handleOnChange}
              view={showExampleData ? 'example' : 'real'}
            />
            {isLoadingAccountStats ? (
              <>
                <SkeletonText className="h-10 w-32 flex-none" />
                <SkeletonText className="h-full w-32 flex-none" />
                <SkeletonText className="h-full w-32 flex-none" />
              </>
            ) : (
              <>
                <Select
                  className="h-auto w-44 flex-none"
                  icon={null}
                  disabled={isDropdownDisabled}
                  placeholder={truncateAtLength(
                    filters?.environments?.join(',')
                      ? clusterFilters?.environments
                          ?.filter((env) => (env === '' ? 'default' : env))
                          .join(', ') || 'Environments'
                      : 'Environments',
                    16,
                  )}
                >
                  <div className="flex w-full items-center gap-2 pt-1 pr-1 pb-1 pl-3">
                    <CheckboxGroup label=" Filter by Environment" helpText="">
                      {uniqueEnvironments.map((environment) => (
                        <Checkbox
                          checked={clusterFilters.environments?.includes(
                            environment,
                          )}
                          key={environment}
                          label={environment}
                          value={environment}
                          onClick={() => {
                            const environments = filters?.environments || [];
                            const { environments: e, ...rest } = clusterFilters;
                            if (environments?.includes(environment)) {
                              setClusterFilters({
                                environments:
                                  clusterFilters?.environments?.filter(
                                    (env) => env !== environment,
                                  ),
                                ...rest,
                              });
                            } else {
                              const { environments: e, ...rest } =
                                clusterFilters;
                              setClusterFilters({
                                environments: e
                                  ? [...e, environment]
                                  : [environment],
                                ...rest,
                              });
                            }
                          }}
                        />
                      ))}
                    </CheckboxGroup>
                  </div>
                  <div className="flex w-full flex-col items-start pt-0.5 pb-0.5">
                    <div className="flex h-px w-full flex-none flex-col items-center gap-2 bg-neutral-300" />
                  </div>
                  <Button
                    className="h-8 w-full flex-none justify-start"
                    variant="neutral-tertiary"
                    onClick={(event) => {
                      event.preventDefault();
                      const { environments, ...rest } = filters;
                      setClusterFilters({
                        environments: [],
                        ...rest,
                      });

                      mutateClusters && mutateClusters();
                    }}
                  >
                    Clear Selection
                  </Button>
                </Select>

                <Select
                  className="h-auto w-44 flex-none"
                  icon={null}
                  disabled={isDropdownDisabled}
                  placeholder={truncateAtLength(
                    clusterFilters?.versions?.join(',') || 'Versions',
                    16,
                  )}
                >
                  <div className="flex w-full items-center gap-2 pt-1 pr-1 pb-1 pl-3">
                    <CheckboxGroup label="Filter by Version" helpText="">
                      {uniqueVersions.map((version) => (
                        <Checkbox
                          className="mx-1"
                          checked={clusterFilters?.versions?.includes(version)}
                          key={version}
                          label={version}
                          value={version}
                          onClick={() => {
                            const versions = filters?.versions || [];
                            const { versions: v, ...rest } = clusterFilters;
                            if (versions?.includes(version)) {
                              setClusterFilters({
                                versions: clusterFilters?.versions?.filter(
                                  (ver) => ver !== version,
                                ),
                                ...rest,
                              });
                            } else {
                              const { versions: v, ...rest } = clusterFilters;
                              setClusterFilters({
                                versions: v ? [...v, version] : [version],
                                ...rest,
                              });
                            }
                          }}
                        />
                      ))}
                    </CheckboxGroup>
                  </div>
                  <div className="flex w-full flex-col items-start pt-0.5 pb-0.5">
                    <div className="flex h-px w-full flex-none flex-col items-center gap-2 bg-neutral-300" />
                  </div>
                  <Button
                    className="h-8 w-full flex-none justify-start"
                    variant="neutral-tertiary"
                    onClick={(event) => {
                      event.preventDefault();
                      const { versions, ...rest } = filters;
                      setClusterFilters({
                        versions: [],
                        ...rest,
                      });

                      mutateClusters && mutateClusters();
                    }}
                  >
                    Clear Selection
                  </Button>
                </Select>

                <Select
                  className="h-auto w-44 flex-none"
                  icon={null}
                  disabled={isDropdownDisabled}
                  placeholder={truncateAtLength(
                    clusterFilters?.providers?.join(',') || 'Clouds',
                    16,
                  )}
                >
                  <div className="flex w-full items-center gap-2 pt-1 pr-1 pb-1 pl-3">
                    <CheckboxGroup label="Filter by Cloud Provider" helpText="">
                      {uniqueProviders.map((provider) => (
                        <Checkbox
                          checked={clusterFilters?.providers?.includes(
                            provider,
                          )}
                          key={provider}
                          label={provider}
                          value={provider}
                          onClick={() => {
                            const providers = filters?.providers || [];
                            const { providers: p, ...rest } = clusterFilters;
                            if (providers?.includes(provider)) {
                              setClusterFilters({
                                providers: clusterFilters?.providers.filter(
                                  (pro) => pro !== provider,
                                ),
                                ...rest,
                              });
                            } else {
                              const { providers: p, ...rest } = clusterFilters;
                              setClusterFilters({
                                providers: p ? [...p, provider] : [provider],
                                ...rest,
                              });
                            }
                          }}
                        />
                      ))}
                    </CheckboxGroup>
                  </div>
                  <div className="flex w-full flex-col items-start pt-0.5 pb-0.5">
                    <div className="flex h-px w-full flex-none flex-col items-center gap-2 bg-neutral-300" />
                  </div>
                  <Button
                    className="h-8 w-full flex-none justify-start"
                    variant="neutral-tertiary"
                    onClick={(event) => {
                      event.preventDefault();
                      const { providers, ...rest } = filters;
                      setClusterFilters({
                        providers: [],
                        ...rest,
                      });

                      mutateClusters && mutateClusters();
                    }}
                  >
                    Clear Selection
                  </Button>
                </Select>
              </>
            )}
            <SubframeCore.Tooltip.Provider>
              <SubframeCore.Tooltip.Root>
                <SubframeCore.Tooltip.Trigger asChild={true}>
                  <div className="flex items-center justify-end gap-2 rounded border border-solid border-neutral-border pt-1 pr-1 pb-1 pl-1">
                    <SubframeCore.Tooltip.Provider>
                      <SubframeCore.Tooltip.Root>
                        <SubframeCore.Tooltip.Trigger asChild={true}>
                          <IconButton
                            icon="FeatherLayoutGrid"
                            variant={
                              clusterViewLayout === ClustersViewLayout.GRID
                                ? 'neutral-primary'
                                : undefined
                            }
                            onClick={() =>
                              handleAlignment(ClustersViewLayout.GRID)
                            }
                          />
                        </SubframeCore.Tooltip.Trigger>
                        <SubframeCore.Tooltip.Portal>
                          <SubframeCore.Tooltip.Content
                            side="bottom"
                            align="center"
                            sideOffset={4}
                            asChild={true}
                          >
                            <Tooltip>Grid View</Tooltip>
                          </SubframeCore.Tooltip.Content>
                        </SubframeCore.Tooltip.Portal>
                      </SubframeCore.Tooltip.Root>
                    </SubframeCore.Tooltip.Provider>
                    <SubframeCore.Tooltip.Provider>
                      <SubframeCore.Tooltip.Root>
                        <SubframeCore.Tooltip.Trigger asChild={true}>
                          <IconButton
                            variant={
                              clusterViewLayout === ClustersViewLayout.LIST
                                ? 'neutral-primary'
                                : undefined
                            }
                            onClick={() =>
                              handleAlignment(ClustersViewLayout.LIST)
                            }
                            icon="FeatherList"
                          />
                        </SubframeCore.Tooltip.Trigger>
                        <SubframeCore.Tooltip.Portal>
                          <SubframeCore.Tooltip.Content
                            side="bottom"
                            align="center"
                            sideOffset={4}
                            asChild={true}
                          >
                            <Tooltip>List View</Tooltip>
                          </SubframeCore.Tooltip.Content>
                        </SubframeCore.Tooltip.Portal>
                      </SubframeCore.Tooltip.Root>
                    </SubframeCore.Tooltip.Provider>
                  </div>
                </SubframeCore.Tooltip.Trigger>
              </SubframeCore.Tooltip.Root>
            </SubframeCore.Tooltip.Provider>
          </div>
          <div
            className={`flex h-full w-full max-w-${
              clusterViewLayout === ClustersViewLayout.GRID
                ? '[1200px]'
                : 'full'
            } grow shrink-0 basis-0 flex-col items-start gap-2`}
            style={{
              maxWidth:
                clusterViewLayout === ClustersViewLayout.GRID
                  ? '1200px'
                  : '100%',
            }}
          >
            <RiskLedgerClustersViews
              clusters={clusters}
              isLoadingClusters={isLoadingClusters}
              showExampleData={showExampleData}
              mutateClusters={mutateClusters}
              handleAlignment={handleAlignment}
              clusterViewLayout={clusterViewLayout}
              isTotalClustersLoading={isTotalClustersLoading}
              clustersToShowFilterLoading={clustersToShowFilterLoading}
              filters={filters}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default RiskLedgerActionHeader;
