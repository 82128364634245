'use client';

import * as Sentry from '@sentry/browser';
import * as SubframeCore from '@subframe/core';
import { Icon } from '@subframe/core';
import { useListClusterScans } from 'api/frontend';
import { Cluster, ClusterScan } from 'api/models';
import { RouterLink } from 'components/RouterLink';
import { AtomicTooltip } from 'components/design-system';
import {
  TablePaginationUserSettings,
  getPaginatedChunk,
} from 'components/design-system/Table/TablePagination';
import { toastAutoHideDuration } from 'constants/toasts';
import { formatDistanceToNow, fromUnixTime } from 'date-fns';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import useLocalStorage from 'hooks/useLocalStorage';
import useUserAccountState from 'hooks/useUserAccountState';
import { ClustersViewLayout } from 'models/general';
import { useSnackbar } from 'notistack';
import {
  ChangeClusterNameEnvironmentDialog,
  errorAgentConfigAlreadySet,
} from 'pages/clusters/dialogs/ChangeClusterNameEnvironmentDialog';
import { useEffect, useMemo, useState } from 'react';
import { generatePath, useNavigate } from 'react-router';
import {
  Badge,
  Button,
  CardBase,
  Pagination,
  Select,
  SkeletonText,
  Table,
} from 'subframe/index';
import { extractMajorMinor } from 'utils/extractMajorMinor';
import { ClusterFilters, ClustersViewLayoutValues } from '../models';
import ClusterCardRisksCountTooltip from './ClusterCardRisksCountTooltip';
import {
  ClusterCardSkeletonLoading,
  ClusterRowSkeletonLoading,
} from './ClusterCardSkeletonLoading';
import {
  AlertButtonTray,
  MoreOptionsButton,
  RescanButton,
} from './ClusterInfoElements';

import { ClusterCardStats } from 'components/ClusterCard';
import { useGetClusterLARsStatus } from 'hooks/useGetClusterLARsStatus';
import {
  getClusterProvider,
  getClusterRegion,
  isClusterInactive,
} from 'utils/clusterStates';
import { ARSigsSeverityCounts } from 'utils/filters';
import { DIALOG_DROPDOWN_MENU_ITEMS_ZINDEX } from 'constants/z-index';
import { fNumber } from 'utils/formatNumber';
interface RiskLedgerClustersListViewProps {
  clusters: Cluster[];
  isLoadingClusters: boolean;
  showExampleData: boolean | undefined;
  mutateClusters: () => void;
  handleAlignment: (newAlignment: ClustersViewLayoutValues) => void;
  clusterViewLayout: ClustersViewLayoutValues;
  isTotalClustersLoading: boolean;
  clustersToShowFilterLoading: boolean;
  filters: ClusterFilters | undefined;
}
function RiskLedgerClustersViews({
  clusters,
  isLoadingClusters,
  showExampleData,
  mutateClusters,
  handleAlignment,
  clusterViewLayout,
  isTotalClustersLoading,
  clustersToShowFilterLoading,
  filters,
}: RiskLedgerClustersListViewProps) {
  const [currentPage, setCurrentPage] = useState(0);

  const defaultUserSettings = {
    pageSize: 25,
  };
  const rowsPerPageOptions = [5, 10, 15, 20, 25];

  const localStoragePaginationKey = 'clusters-pagination';
  const [userSettings, setUserSettings] =
    useLocalStorage<TablePaginationUserSettings>(
      localStoragePaginationKey,
      defaultUserSettings,
    );

  const displayResourcesPerPage = useMemo(() => {
    return (
      getPaginatedChunk(currentPage, clusters, userSettings?.pageSize) || []
    );
  }, [userSettings, currentPage, clusters]);

  useEffect(() => {
    setCurrentPage(0);
  }, [userSettings?.pageSize, filters, showExampleData]);

  const displayStart = currentPage * (userSettings?.pageSize || 5) + 1;
  const displayEnd = Math.min(
    (currentPage + 1) * (userSettings?.pageSize || 5),
    clusters?.length,
  );

  const paginationDisplayRightText = (
    <div className={'flex row'}>
      {isTotalClustersLoading || clustersToShowFilterLoading ? (
        <>
          Showing {displayStart} to{' '}
          {<SkeletonText className={'w-5 ml-1 mr-1'} />}
          of {<SkeletonText className={'w-5 ml-1 mr-1'} />} clusters
        </>
      ) : (
        `Showing ${displayStart} to ${displayEnd} of ${fNumber(
          clusters?.length ?? 0,
        )} clusters`
      )}
    </div>
  );

  return (
    <div className="flex h-full w-full grow shrink-0 basis-0 flex-col items-start gap-2">
      {clusters?.length > 5 && (userSettings?.pageSize || 5) > 5 && (
        <Pagination
          data-cy={`clusters-table-pagination`}
          text={`Clusters per Page`}
          rightText={paginationDisplayRightText}
          leftActions={
            <Select
              icon={null}
              placeholder={`${userSettings.pageSize}`}
              onValueChange={(value) =>
                setUserSettings((old) => {
                  return { ...old, pageSize: Number(value) };
                })
              }
            >
              {rowsPerPageOptions.map((option, idx) => (
                <Select.Item key={idx} value={`${option}`}>
                  {`${option}`}
                </Select.Item>
              ))}
            </Select>
          }
          nextButton={
            <Button
              variant="neutral-secondary"
              iconRight="FeatherChevronRight"
              size="medium"
              disabled={displayEnd === clusters?.length}
              onClick={() => setCurrentPage(currentPage + 1)}
            >
              Next
            </Button>
          }
          previousButton={
            <Button
              variant="neutral-secondary"
              icon="FeatherChevronLeft"
              size="medium"
              disabled={displayStart === 1}
              onClick={() => setCurrentPage(currentPage - 1)}
            >
              Previous
            </Button>
          }
        />
      )}

      {clusterViewLayout === ClustersViewLayout.LIST && (
        <Table
          header={
            <Table.HeaderRow>
              <Table.HeaderCell>Cluster</Table.HeaderCell>
              <Table.HeaderCell>Availability Risks</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.HeaderRow>
          }
        >
          {!isLoadingClusters &&
            clusters &&
            displayResourcesPerPage.map((cluster) => (
              <RiskLedgerCluster
                key={cluster.id}
                cluster={cluster}
                showExampleData={showExampleData}
                mutateClusters={mutateClusters}
              />
            ))}

          {(clustersToShowFilterLoading ||
            isLoadingClusters ||
            isTotalClustersLoading) && <ClusterRowSkeletonLoading />}
        </Table>
      )}
      {clusterViewLayout === ClustersViewLayout.GRID && (
        <div className="flex w-full flex-wrap items-center gap-6">
          {!isLoadingClusters &&
            clusters &&
            displayResourcesPerPage.map((cluster) => (
              <RiskLedgerCluster
                key={cluster.id}
                cluster={cluster}
                showExampleData={showExampleData}
                mutateClusters={mutateClusters}
                clusterViewLayout={ClustersViewLayout.GRID}
              />
            ))}
          {(isLoadingClusters ||
            clustersToShowFilterLoading ||
            isTotalClustersLoading) && <ClusterCardSkeletonLoading />}
        </div>
      )}

      {isLoadingClusters ||
        (clusters?.length ? (
          <Pagination
            data-cy={`clusters-table-pagination`}
            text={`Clusters per Page`}
            rightText={paginationDisplayRightText}
            leftActions={
              <Select
                icon={null}
                placeholder={`${userSettings.pageSize}`}
                onValueChange={(value) =>
                  setUserSettings((old) => {
                    return { ...old, pageSize: Number(value) };
                  })
                }
              >
                {rowsPerPageOptions.map((option, idx) => (
                  <Select.Item key={idx} value={`${option}`}>
                    {`${option}`}
                  </Select.Item>
                ))}
              </Select>
            }
            nextButton={
              <Button
                variant="neutral-secondary"
                iconRight="FeatherChevronRight"
                size="medium"
                disabled={displayEnd === clusters?.length}
                onClick={() => setCurrentPage(currentPage + 1)}
              >
                Next
              </Button>
            }
            previousButton={
              <Button
                variant="neutral-secondary"
                icon="FeatherChevronLeft"
                size="medium"
                disabled={displayStart === 1}
                onClick={() => setCurrentPage(currentPage - 1)}
              >
                Previous
              </Button>
            }
          />
        ) : (
          <></>
        ))}
    </div>
  );
}

interface RiskLedgerClusterProps {
  cluster: Cluster;
  showExampleData: boolean;
  mutateClusters: () => void;
  clusterViewLayout?: ClustersViewLayoutValues;
}

const defaultStats: ClusterCardStats = {
  totalSignatures: 0,
  lars: 0,
  timestamp: 0,
  status: '',
};

function RiskLedgerCluster({
  cluster,
  showExampleData,
  mutateClusters,
  clusterViewLayout,
}: RiskLedgerClusterProps) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [clusterNamingDialogOpen, setClusterNamingDialogOpen] = useState(false);

  const getScanStatus = (cluster: Cluster) => {
    if (cluster) {
      return (
        Boolean(cluster?.last_scan_summary?.id) &&
        (cluster.rescan === 'completed' || !cluster.rescan)
      );
    } else {
      return false;
    }
  };

  const [rescanComplete, setRescanComplete] = useState(getScanStatus(cluster));
  const { account } = useUserAccountState();
  const [lastScan, setLastScan] = useState<ClusterScan>();
  const [isInRescan, setIsInRescan] = useState<boolean>(false);

  const isLastScanSummaryAvailable = !!cluster?.last_scan_summary;
  const {
    data: clusterScans,
    isLoading: clusterScansLoading,
    error: clusterScansError,
    mutate: mutateClustersScans,
  } = useListClusterScans(
    cluster?.id,
    { page_size: 2 },
    {
      request: { headers: { Authorization: `Bearer ${account?.token}` } },

      swr: {
        enabled: !!!cluster?.last_scan_summary || isInRescan,
        refreshInterval: 10000,
        revalidateOnFocus: false,
      },
    },
  );

  if (
    clusterScans &&
    clusterScans.data?.length > 0 &&
    clusterScans.data[0].status === 'pending'
  ) {
    !isInRescan && setIsInRescan(true);
  }
  useEffect(() => {
    if (isLastScanSummaryAvailable) {
      if (
        (clusterScans?.data[0]?.status === 'complete' &&
          clusterScans?.data[1]?.id === cluster?.last_scan_summary?.id) ||
        (clusterScans &&
          !clusterScans?.data?.some(
            (s) => s?.id === cluster?.last_scan_summary?.id,
          ))
      ) {
        // This stops the scan request polling, which searches for new scans. This is needed because the cluster's last_scan_summary
        // object only provides the summary of completed scans, with no indication of any ongoing scans. Without this strategy,
        // every time user rescans a cluster, they'll need to refresh the page for the new scan to appear.
        setIsInRescan(false);
        mutateClusters();
      }
    } else if (isInRescan) {
      mutateClusters();
    }
  }, [clusterScans, isInRescan]);

  const {
    undetectedSignaturesCount,
    unignoredDefects,
    unignoredMisconfigurations,
    unignoredSystemRequirements,
    unignoredDeprecations,
    unignoredUnsupportedVersions,
    unignoredVersionIncompatibilities,
    rawLarsDataLoading: scanLarsLoading,
    rawSigsDataLoading: scanSigsLoading,
  } = useGetClusterLARsStatus(
    cluster.id,
    lastScan,
    clusterScans,
    account?.token,
    isLastScanSummaryAvailable,
  ) ?? {
    rawLarsDataLoading: false,
    rawSigsDataLoading: false,
    undetectedSignaturesCount: 0,
    unignoredDefects: [],
    unignoredMisconfigurations: [],
    unignoredSystemRequirements: [],
    unignoredDeprecations: [],
    unignoredUnsupportedVersions: [],
    unignoredVersionIncompatibilities: [],
  };

  useEffect(() => {
    if (clusterScans?.data && clusterScans?.data?.length) {
      const latestScan = clusterScans.data[0];
      const secondLatestScan = clusterScans.data[1];
      if (latestScan.status === 'complete') {
        setLastScan(latestScan);
      } else if (secondLatestScan) {
        if (secondLatestScan.status === 'complete') {
          setLastScan(secondLatestScan);
        } else {
          Sentry.captureException(new Error('Multiple scans in pending'), {
            level: 'log',
            extra: {
              clusterId: cluster?.id,
              accountId: account?.accountId,
              latestScanId: latestScan?.id,
              secondLatestScanId: secondLatestScan.id,
            },
          });
        }
      }
    }
    return () => {
      setLastScan(undefined);
    };
  }, [clusterScans]);

  const statsToShow = useMemo(() => {
    return lastScan
      ? {
          totalSignatures: lastScan.signature_count,
          lars: lastScan.lar_count,
          timestamp: Number(lastScan.created),
          status: lastScan.status,
        }
      : defaultStats;
  }, [lastScan]);

  useEffect(() => {
    setRescanComplete(getScanStatus(cluster));
  }, [cluster, cluster?.last_scan_summary?.id]);

  const clusterLastScanTime = useMemo(() => {
    if (statsToShow || !!cluster?.last_scan_summary) {
      let lastScan = !!cluster?.last_scan_summary
        ? formatDistanceToNow(
            fromUnixTime(cluster?.last_scan_summary?.updated || 0),
            {
              addSuffix: true,
            },
          )
        : formatDistanceToNow(fromUnixTime(statsToShow.timestamp), {
            addSuffix: true,
          });
      lastScan = lastScan.replace('about ', '~');

      return lastScan;
    } else {
      return '-';
    }
  }, [cluster?.last_scan_summary?.id, statsToShow]);

  const path = useAccountIdRoute('/orgs/:orgId/accounts/:accountId/clusters');

  const isRisksTooltipLoading =
    clusterScansLoading ||
    clusterScansError ||
    scanLarsLoading ||
    scanSigsLoading;

  const isFirstScanProcessing =
    !!!cluster?.last_scan_summary &&
    !lastScan &&
    !clusterScansLoading &&
    (!clusterScans?.data ||
      !clusterScans?.data?.some((s) => s.status === 'completed'));

  const isLastScanDataAvailable = () => {
    if (isLastScanSummaryAvailable) {
      return true;
    } else if (lastScan) {
      return true;
    } else {
      return false;
    }
  };

  const clusterMetaData =
    !isLastScanDataAvailable() || isFirstScanProcessing ? (
      <div className="flex w-full items-center gap-2">
        <span>
          {`${getClusterProvider(cluster)} | ${extractMajorMinor(
            cluster.version,
          )} | ${getClusterRegion(cluster)} | ${
            cluster?.last_scan_summary ? ' Scan in Progress' : ''
          }`}
        </span>
        <div className="flex h-full w-full grow shrink-0 basis-0 items-center gap-2">
          <SkeletonText className="h-[14px] w-8" />
        </div>
      </div>
    ) : (
      <span>
        {`${getClusterProvider(cluster)} | ${extractMajorMinor(
          cluster.version,
        )} | ${getClusterRegion(cluster)} | Last scan ${clusterLastScanTime}`}
      </span>
    );

  const needAttentionCount = {
    defects_count: isLastScanSummaryAvailable ? (
      cluster?.last_scan_summary?.need_attention_summary?.defects_count
    ) : !isRisksTooltipLoading ? (
      ARSigsSeverityCounts(unignoredDefects).NACount
    ) : (
      <SkeletonText className={'w-8'} />
    ),
    misconfigurations_count: isLastScanSummaryAvailable ? (
      cluster?.last_scan_summary?.need_attention_summary
        ?.misconfigurations_count
    ) : !isRisksTooltipLoading ? (
      ARSigsSeverityCounts(unignoredMisconfigurations).NACount
    ) : (
      <SkeletonText className={'w-8'} />
    ),
    system_requirements_count: isLastScanSummaryAvailable ? (
      cluster?.last_scan_summary?.need_attention_summary
        ?.system_requirements_count
    ) : !isRisksTooltipLoading ? (
      ARSigsSeverityCounts(unignoredSystemRequirements).NACount
    ) : (
      <SkeletonText className={'w-8'} />
    ),
    deprecations_count: isLastScanSummaryAvailable ? (
      cluster?.last_scan_summary?.need_attention_summary?.deprecations_count
    ) : !isRisksTooltipLoading ? (
      ARSigsSeverityCounts(unignoredDeprecations).NACount
    ) : (
      <SkeletonText className={'w-8'} />
    ),
    unsupported_versions_count: isLastScanSummaryAvailable ? (
      cluster?.last_scan_summary?.need_attention_summary
        ?.unsupported_versions_count
    ) : !isRisksTooltipLoading ? (
      ARSigsSeverityCounts(unignoredUnsupportedVersions).NACount
    ) : (
      <SkeletonText className={'w-8'} />
    ),
    version_incompatibilities_count: isLastScanSummaryAvailable ? (
      cluster?.last_scan_summary?.need_attention_summary
        ?.version_incompatibilities_count
    ) : !isRisksTooltipLoading ? (
      ARSigsSeverityCounts(unignoredVersionIncompatibilities || 0).NACount
    ) : (
      <SkeletonText className={'w-8'} />
    ),

    totalNeedAttention: <SkeletonText className={'w-8'} />,
  };

  needAttentionCount.totalNeedAttention =
    !isRisksTooltipLoading || isLastScanSummaryAvailable
      ? needAttentionCount?.version_incompatibilities_count +
        needAttentionCount?.unsupported_versions_count +
        needAttentionCount?.deprecations_count +
        needAttentionCount?.system_requirements_count +
        needAttentionCount?.misconfigurations_count +
        needAttentionCount?.defects_count
      : 0;

  const lastScanDiff = useMemo(() => {
    let dateNow = new Date(Date.now());
    if (isLastScanSummaryAvailable && cluster?.last_scan_summary?.updated) {
      let lastScanDate = new Date(cluster?.last_scan_summary?.updated * 1000);
      let diff = dateNow.getTime() - lastScanDate.getTime();
      return diff / (1000 * 3600);
    } else if (!isRisksTooltipLoading && statsToShow.timestamp) {
      let lastScanDate = new Date(statsToShow.timestamp * 1000);
      let diff = dateNow.getTime() - lastScanDate.getTime();
      return diff / (1000 * 3600);
    } else {
      return 0;
    }
  }, [cluster?.last_scan_summary?.id, isRisksTooltipLoading, lastScan]);

  return (
    <div style={{ display: 'contents' }}>
      <ChangeClusterNameEnvironmentDialog
        clusterDetails={{
          id: cluster.id,
          name: cluster.name || '',
          environment: cluster.environment || '',
        }}
        allowNameChange={cluster?.agent_managed_properties?.name === undefined}
        allowEnvironmentChange={
          cluster?.agent_managed_properties?.environment === undefined
        }
        onClusterNameEnvironmentChange={() => {
          mutateClusters && mutateClusters();
        }}
        open={clusterNamingDialogOpen}
        onClose={() => {
          setClusterNamingDialogOpen(false);
          navigate(
            generatePath(`${path}/:clusterId?view=arsigs-overview`, {
              clusterId: cluster?.id,
            }),
          );
        }}
      />
      <RouterLink
        to={`${path}/${cluster?.id}?view=arsigs-overview`}
        style={{ display: 'contents', padding: 0 }}
        onClick={(event) => {
          // Example cluster check is performed here
          if (
            !cluster?.environment &&
            cluster?.internal_k8s_ref !== 'example-cluster-ref'
          ) {
            event.preventDefault();
            event.stopPropagation();
            setClusterNamingDialogOpen(true);
            if (
              !cluster?.environment &&
              cluster?.agent_managed_properties?.environment &&
              cluster?.agent_managed_properties?.name
            ) {
              enqueueSnackbar(errorAgentConfigAlreadySet, {
                variant: 'warning',
                autoHideDuration: toastAutoHideDuration,
              });
              return;
            } else {
              setClusterNamingDialogOpen(!!cluster?.id);
            }
          }
        }}
      >
        {clusterViewLayout === ClustersViewLayout.GRID ? (
          <CardBase
            id="cluster-card"
            title={cluster.name || ''}
            subtitle={clusterMetaData || ''}
            actions={
              <div className="flex w-full grow shrink-0 basis-0 items-center justify-end gap-1">
                <AlertButtonTray
                  cluster={cluster}
                  showExampleData={showExampleData}
                  lastScanDiff={lastScanDiff}
                />
                {!showExampleData && (
                  <RescanButton
                    cluster={cluster}
                    showExampleData={showExampleData}
                    setIsInRescan={setIsInRescan}
                    isInRescan={isInRescan}
                    inactive={isClusterInactive(lastScanDiff, cluster)}
                    isFirstScanProcessing={isFirstScanProcessing}
                  />
                )}
                <MoreOptionsButton
                  cluster={cluster}
                  mutateClusters={mutateClusters}
                />
              </div>
            }
            metric1Children={
              <SubframeCore.HoverCard.Root>
                <SubframeCore.HoverCard.Trigger asChild={true}>
                  <div className="flex flex-col items-start gap-2">
                    <div className="flex items-center gap-2">
                      {isFirstScanProcessing ? (
                        <>
                          <span className="text-body font-body text-subtext-color">
                            Scan in Progress...
                          </span>
                          <AtomicTooltip
                            tooltipContent={
                              'Scan can take up to 15 minutes. Results will appear once the scan is complete.'
                            }
                          >
                            <Icon
                              name="FeatherInfo"
                              className="text-body font-body text-subtext-color"
                            />
                          </AtomicTooltip>
                        </>
                      ) : (
                        <>
                          <span className="text-body font-body text-subtext-color">
                            Need attention
                          </span>

                          <SubframeCore.Icon
                            name="FeatherInfo"
                            className="text-body font-body text-subtext-color need-attention-info-icon"
                          />
                        </>
                      )}
                    </div>
                    {!isFirstScanProcessing && (
                      <div className="flex items-center gap-2">
                        <span
                          className="text-section-header font-section-header text-default-font"
                          data-cy={
                            !isRisksTooltipLoading
                              ? 'need-attention-count'
                              : 'need-attention-skeleton'
                          }
                        >
                          {!isRisksTooltipLoading ? (
                            needAttentionCount?.totalNeedAttention
                          ) : (
                            <SkeletonText className="w-6 h-6" />
                          )}
                        </span>

                        <div className="flex items-center gap-2">
                          {isRisksTooltipLoading ? (
                            <Badge variant="neutral">
                              <div className="flex items-center">
                                <SkeletonText className="w-6 h-4" />
                                &nbsp;OK
                              </div>
                            </Badge>
                          ) : (
                            <Badge variant="neutral" className="ok-amount">{`${
                              isLastScanSummaryAvailable
                                ? cluster?.last_scan_summary
                                    ?.scanned_risks_count -
                                  cluster?.last_scan_summary
                                    ?.detected_risks_count
                                : undetectedSignaturesCount
                            } OK`}</Badge>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </SubframeCore.HoverCard.Trigger>
                <SubframeCore.HoverCard.Portal>
                  <SubframeCore.HoverCard.Content
                    side="bottom"
                    align="start"
                    sideOffset={4}
                    asChild={true}
                  >
                    {isFirstScanProcessing ? (
                      <></>
                    ) : (
                      <ClusterCardRisksCountTooltip
                        cluster={cluster}
                        needAttentionCount={needAttentionCount}
                      />
                    )}
                  </SubframeCore.HoverCard.Content>
                </SubframeCore.HoverCard.Portal>
              </SubframeCore.HoverCard.Root>
            }
            cardLabel={
              cluster?.environment && (
                <AtomicTooltip
                  tooltipContent={
                    cluster.environment.length > 8 ? cluster.environment : ''
                  }
                >
                  <Badge
                    variant="neutral"
                    className={'max-w-[100px] overflow-ellipsis'}
                  >
                    {cluster.environment}
                  </Badge>
                </AtomicTooltip>
              )
            }
          />
        ) : (
          <Table.Row clickable={true} id="cluster-card">
            <Table.Cell className="h-14 grow shrink-0 basis-0">
              <div className="flex flex-col items-start pt-1 pr-1 pb-1 pl-1">
                <div className="flex items-center gap-2">
                  <div className="flex items-start gap-2">
                    <span className="whitespace-nowrap text-body-bold font-body-bold text-neutral-700">
                      {cluster.name}
                    </span>
                    {cluster.environment && (
                      <AtomicTooltip
                        tooltipContent={
                          cluster.environment.length > 8
                            ? cluster.environment
                            : ''
                        }
                      >
                        <Badge
                          variant="neutral"
                          className={'max-w-[100px] overflow-ellipsis'}
                        >
                          {cluster.environment}
                        </Badge>
                      </AtomicTooltip>
                    )}
                  </div>
                  <AlertButtonTray
                    cluster={cluster}
                    showExampleData={showExampleData}
                    lastScanDiff={lastScanDiff}
                  />
                </div>
                <span className="text-body font-body text-subtext-color">
                  {clusterMetaData}
                </span>
              </div>
            </Table.Cell>

            <Table.Cell className="h-14 grow shrink-0 basis-0">
              <SubframeCore.HoverCard.Root>
                <SubframeCore.HoverCard.Trigger asChild={true}>
                  <div className="flex items-center justify-center gap-2 self-stretch pt-2 pr-2 pb-2 pl-2">
                    {isFirstScanProcessing ? (
                      <>
                        <span className="text-body font-body text-subtext-color">
                          Scan in Progress...
                        </span>
                        <AtomicTooltip
                          tooltipContent={
                            'Scan can take up to 15 minutes. Results will appear once the scan is complete.'
                          }
                        >
                          <Icon
                            name="FeatherInfo"
                            className="text-body font-body text-subtext-color"
                          />
                        </AtomicTooltip>
                      </>
                    ) : (
                      <>
                        {isRisksTooltipLoading ? (
                          <span className="text-body font-body text-subtext-color">
                            <div className="flex">
                              <SkeletonText className={'w-4 h-4'} />
                              &nbsp;Need Attention
                            </div>
                          </span>
                        ) : (
                          <span
                            className="text-body font-body text-subtext-color"
                            data-cy="need-attention-count"
                          >
                            {needAttentionCount?.totalNeedAttention} Need
                            Attention
                          </span>
                        )}

                        <Icon
                          name="FeatherInfo"
                          className="text-body font-body text-subtext-color need-attention-info-icon"
                        />

                        {isRisksTooltipLoading ? (
                          <Badge variant="neutral">
                            <div className="flex items-center ">
                              <SkeletonText className="w-6 h-4" />
                              &nbsp;OK
                            </div>
                          </Badge>
                        ) : (
                          <Badge variant="neutral" className="ok-amount">{`${
                            cluster?.last_scan_summary?.id
                              ? cluster?.last_scan_summary
                                  ?.scanned_risks_count -
                                cluster?.last_scan_summary?.detected_risks_count
                              : undetectedSignaturesCount
                          } OK`}</Badge>
                        )}
                      </>
                    )}
                  </div>
                </SubframeCore.HoverCard.Trigger>
                <SubframeCore.HoverCard.Portal>
                  <SubframeCore.HoverCard.Content
                    side="bottom"
                    align="center"
                    sideOffset={4}
                    asChild={true}
                  >
                    {isFirstScanProcessing ? (
                      <></>
                    ) : (
                      <ClusterCardRisksCountTooltip
                        cluster={cluster}
                        needAttentionCount={needAttentionCount}
                      />
                    )}
                  </SubframeCore.HoverCard.Content>
                </SubframeCore.HoverCard.Portal>
              </SubframeCore.HoverCard.Root>
            </Table.Cell>

            <Table.Cell className="h-14 grow shrink-0 basis-0">
              <div className="flex grow shrink-0 basis-0 items-center justify-end self-stretch">
                {!showExampleData && (
                  <RescanButton
                    cluster={cluster}
                    showExampleData={showExampleData}
                    inactive={isClusterInactive(lastScanDiff, cluster)}
                    isFirstScanProcessing={isFirstScanProcessing}
                  />
                )}

                <MoreOptionsButton
                  cluster={cluster}
                  mutateClusters={mutateClusters}
                />
              </div>
            </Table.Cell>

            <Table.Cell className="h-18 w-auto flex-none ">
              <SubframeCore.Icon
                className="text-body font-body text-default-font"
                name="FeatherChevronRight"
              />
            </Table.Cell>
          </Table.Row>
        )}
      </RouterLink>
    </div>
  );
}

export default RiskLedgerClustersViews;
