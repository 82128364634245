import { useEffect, useState } from 'react';

import useAccountIdRoute from 'hooks/useAccountIdRoute';
import {
  Cluster,
  ClusterScan,
  ClusterScannedSignature,
  LARStatus,
} from 'api/models';
import { capitalizeFirstLetter } from 'utils/stringOperations';
import { Icon } from '@subframe/core';
import { Badge, SkeletonText } from 'subframe/index';
import { markAsReasonDisplay } from 'pages/kba/models/markAs';
import { CommentTooltip } from 'components/design-system/CommentTooltip';
import BaseTable from 'components/design-system/Table/BaseTable';
import { severityBadgeMap } from 'models/general';

interface TableProps {
  cluster: Cluster;
  scan: ClusterScan | undefined;
  tableData: ClusterScannedSignature[];
  tableView: string;
  filterClustersType: string;
  searchSigName: string;
  title: string;
  hideSeverity?: boolean;
  isLoading: boolean;
  larStatus?: LARStatus[];
}

export const ClusterDetailsTable = ({
  cluster,
  tableData,
  tableView,
  filterClustersType,
  searchSigName,
  hideSeverity,
  larStatus,
  title,
  isLoading,
}: TableProps) => {
  const sortedData = tableData?.sort((a, b) => {
    if (a.detected === b.detected) {
      return 0;
    }
    if (a.detected) {
      return 1;
    }
    return -1;
  });

  const [displayData, setDisplayData] =
    useState<ClusterScannedSignature[]>(sortedData);

  useEffect(() => {
    if (!isLoading) {
      let found = sortedData;

      if (
        !searchSigName &&
        (!filterClustersType || filterClustersType === 'clear' || hideSeverity)
      ) {
        found = sortedData;
      }
      if (searchSigName) {
        found = sortedData.filter((item) =>
          item.title.toLowerCase().includes(searchSigName.toLowerCase()),
        );
      }
      if (filterClustersType && !hideSeverity) {
        found =
          filterClustersType === 'clear'
            ? found
            : found.filter(
                (item) =>
                  item.severity.toLowerCase() ===
                  filterClustersType?.toLowerCase(),
              );
      }
      setDisplayData(found);
    }
  }, [filterClustersType, searchSigName, tableView, title, isLoading]);

  const tableRowBasePath = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/clusters',
  );

  return (
    <div className="flex flex-col w-full gap-1">
      {isLoading ? (
        <BaseTable
          settings={{
            localStorageKey: 'cluster_details_table',
            dataCyPrefix: 'cluster-details-table-loading',
          }}
          data={[{}, {}, {}]} // Passing empty objects to represent rows
          columns={[
            {
              id: 'signature-id-loading',
              title: 'ID',
              titleStyle: { width: '15%' },
              cellType: 'cell',
              render: () => <SkeletonText className="flex h-50px w-50px" />,
            },
            {
              id: 'signature-title-loading',
              title: 'Title',
              titleStyle: { width: '70%' },
              cellType: 'cell',
              render: () => <SkeletonText className="flex h-50px w-50px" />,
            },
            {
              id: 'signature-severity-loading',
              title: 'Severity',
              cellType: 'cell',
              titleStyle: { width: '15%' },
              render: () => <SkeletonText className="flex h-50px w-50px" />,
            },
          ].filter(
            (col) => col.id !== 'signature-severity-loading' || !hideSeverity,
          )}
          actions={[]}
        />
      ) : (
        <BaseTable
          settings={{
            localStorageKey: 'cluster_details_table',
            dataCyPrefix: 'cluster-details-table',
          }}
          data={displayData || []}
          noMatchingData={!displayData.length}
          rowLink={(row) =>
            `${tableRowBasePath}/${cluster.id}/${
              row.detected ? 'lar' : 'arsig'
            }/${row.signature_id.toLowerCase()}`
          }
          columns={[
            {
              id: 'signature-id',
              title: 'ID',
              titleStyle: { width: '15%' },
              cellType: 'text-cell',
              render: (row: ClusterScannedSignature) => {
                return `${row?.signature_id}`;
              },
            },
            {
              id: 'signature-title',
              title: 'Title',
              titleStyle: { width: '70%' },
              cellType: 'cell',
              render: (row: ClusterScannedSignature) => {
                const markAsStatus = larStatus?.find(
                  (status) =>
                    status.lar_id.toLowerCase() ===
                      row?.signature_id.toLowerCase() &&
                    status.ignore_status?.reason,
                );

                return (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      gap: '8px',
                    }}
                  >
                    <span
                      style={{
                        font: 'var(--body)',
                        color: 'var(--neutral-500)',
                      }}
                    >
                      {row?.title}
                    </span>

                    {markAsStatus?.ignore_status?.reason && (
                      <Badge variant="neutral">
                        {`Marked as: ${
                          markAsReasonDisplay[markAsStatus.ignore_status.reason]
                        }`}
                      </Badge>
                    )}
                    {markAsStatus?.ignore_status?.comment && (
                      <CommentTooltip
                        userName={markAsStatus.ignore_status.user?.name}
                        picture={markAsStatus.ignore_status.user?.picture}
                        time={markAsStatus.ignore_status.updated}
                        comment={markAsStatus.ignore_status.comment}
                      />
                    )}
                  </div>
                );
              },
            },
            {
              id: 'signature-severity',
              title: 'Severity',
              cellType: 'cell',
              titleStyle: { width: '15%' },
              render: (row: ClusterScannedSignature) => {
                return (
                  <Badge
                    variant={
                      severityBadgeMap[row?.severity.toLowerCase()] || 'neutral'
                    }
                  >
                    {capitalizeFirstLetter(row?.severity)}
                  </Badge>
                );
              },
            },
          ].filter((col) => col.id !== 'signature-severity' || !hideSeverity)}
          actions={[
            {
              id: 'navigate',
              render: () => {
                return <Icon name="FeatherChevronRight" />;
              },
            },
          ]}
        />
      )}
    </div>
  );
};
