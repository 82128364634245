import { marked } from 'marked';
import DOMPurify from 'dompurify';
import * as asn1js from 'asn1js';
import * as pkijs from 'pkijs';
import { allowedHtmlTagsForMarkdown } from 'constants/input-validation';

// Normalize html to ensure consistency
const normalizeHtml = (inputHtml: string) =>
  inputHtml
    .trim()
    .replace(/\s+/g, ' ') // Remove excessive whitespace
    .replace(/>\s+</g, '><') // Trim spaces between tags
    .replace(/<br\s*\/?>/g, '<br />') // Normalize self closing tags (since sanitize may change <br/> to <br>)
    .replace(/<hr>|<hr\/>/g, '<hr />')
    .replace(/<\/?tbody>/g, '') // sanitize may add tbody and thead automatically, should be ignored from comparison
    .replace(/<\/?thead>/g, '');

export const isValidMarkdown = (markdown: string): boolean => {
  // Convert Markdown to HTML
  const html = marked(markdown);

  // Sanitize html
  const sanitizedHtml = DOMPurify.sanitize(String(html), {
    //
    ALLOWED_TAGS: allowedHtmlTagsForMarkdown,
    ALLOWED_ATTR: ['href', 'target', 'rel'], // Limit attributes to safe ones
  });

  // Normalize both the generated and sanitized HTML
  const normalizedHtml = normalizeHtml(String(html));
  const normalizedSanitizedHtml = normalizeHtml(sanitizedHtml);

  // If the sanitized version is the same as the generated HTML, it's valid
  return normalizedHtml === normalizedSanitizedHtml;
};

export async function isValidX509Certificate(cert: string) {
  // Helper function to convert PEM to ArrayBuffer
  function pemToArrayBuffer(pem: string) {
    const base64 = pem.replace(/(-----(BEGIN|END) CERTIFICATE-----|\s)/g, '');
    const binaryString = atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }

  try {
    // Convert PEM to ArrayBuffer
    const certBuffer = pemToArrayBuffer(cert);
    // Parse the certificate using asn1js
    const asn1 = asn1js.fromBER(certBuffer);
    if (asn1.offset === -1) {
      return false;
    }
    // Initialize the PKI.js Certificate object
    const certificate = new pkijs.Certificate({ schema: asn1.result });
    // Verify the certificate (basic verification)
    const verificationResult = await certificate.verify();
    return verificationResult;
  } catch (error) {
    return false;
  }
}

export const isValidXML = (value: string | undefined): boolean => {
  if (!value) return true; // Optional field, so skip validation if not provided
  try {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(value, 'application/xml');
    const parseError = xmlDoc.getElementsByTagName('parsererror');
    return parseError.length === 0;
  } catch (error) {
    return false;
  }
};
