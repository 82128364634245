import { useState } from 'react';

import { deleteIngestionToken } from 'api/frontend';
import { IngestionToken } from 'api/models';

import { useSnackbar } from 'notistack';
import useUserAccountState from 'hooks/useUserAccountState';

import { Dialog, IconButton, Button, Alert } from 'subframe/index';

import * as Sentry from '@sentry/browser';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { somethingWentWrong, toastAutoHideDuration } from 'constants/toasts';
import { differenceInSeconds, fromUnixTime, getUnixTime } from 'date-fns';

interface DeleteTokenProps {
  open?: boolean;
  token: IngestionToken;
  onClose: () => void;
  callRefetch: VoidFunction;
}

export default function DeleteTokenDialog({
  open,
  onClose,
  token,
  callRefetch,
}: DeleteTokenProps) {
  const { logEvent } = AnalyticsEventLogger();
  const { enqueueSnackbar } = useSnackbar();
  const { account, currentOrganization } = useUserAccountState();
  const [loading, setLoading] = useState(false);
  const DeleteTokenSuccess = 'Token deleted successfully';
  const DeleteTokenRequestError = somethingWentWrong.replace(
    '<action>',
    'deleting the token',
  );

  // Delete Handler
  const deleteTokenHandler = async function () {
    setLoading(true);
    logEvent('delete-token');
    try {
      await deleteIngestionToken('default', token.token_id, {
        headers: { Authorization: `Bearer ${account?.token}` },
      });
      logEvent('token_deleted', {
        token_id: token.token_id,
      });
      enqueueSnackbar(DeleteTokenSuccess, {
        variant: 'success',
        autoHideDuration: toastAutoHideDuration,
      });
      callRefetch();
    } catch (error) {
      enqueueSnackbar(DeleteTokenRequestError, {
        variant: 'error',
        autoHideDuration: toastAutoHideDuration,
      });
      Sentry.captureException(error);
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <div className="contents">
      <Dialog open={open} onOpenChange={onClose}>
        <Dialog.Content className="h-auto w-96 flex-none">
          <div className="flex w-full flex-col items-start gap-6 pt-6 pr-6 pb-6 pl-6">
            <div className="flex w-full items-start justify-between">
              <span className="text-subheader font-subheader text-default-font">
                Delete Token?
              </span>
              <IconButton size="medium" icon="FeatherX" onClick={onClose} />
            </div>
            <div className="flex flex-col items-start gap-4">
              <span className="text-body font-body text-default-font">
                Are you sure you want to delete &quot;
                {token.token_name ||
                  `token-${currentOrganization.name.toLowerCase()}`}
                &quot; token?
              </span>
              <span className="text-body font-body text-default-font">
                Deleted tokens will be permanently removed from Chkk. Ensure
                that at least one token is available. If all tokens are deleted,
                chkk will automatically create a token for future use.
              </span>
              <div className="flex w-full items-end justify-end gap-4">
                <Button
                  variant="neutral-tertiary"
                  size="large"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  variant="destructive-primary"
                  size="large"
                  icon="FeatherTrash"
                  onClick={() => deleteTokenHandler()}
                  disabled={loading}
                  loading={loading}
                >
                  Delete Token
                </Button>
              </div>
            </div>
          </div>
        </Dialog.Content>
      </Dialog>
    </div>
  );
}
