'use client';

import { useState } from 'react';
import { Account } from 'models/account';
import { UpgradeTemplate } from 'api/models';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider from 'components/FormProvider';
import { updateUpgradeTemplate } from 'api/frontend';
import { somethingWentWrong, toastAutoHideDuration } from 'constants/toasts';
import * as Sentry from '@sentry/browser';
import {
  ActivityFeedbackFrame,
  Alert,
  Button,
  Dialog,
  IconButton,
  TextArea,
} from 'subframe/index';
import { AtomicTooltip } from 'components/design-system';
import {
  charLimitExceededErrorMessage,
  charLimits,
} from 'constants/input-validation';
export interface UpgradesRegenerateTemplateDialogProps {
  account: Account;
  upgrade: UpgradeTemplate;
  onConfirm: () => void;
}

function RegenerateTemplateDialog(
  props: UpgradesRegenerateTemplateDialogProps,
) {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const regenerateTemplateSchema = Yup.object().shape({
    comment: Yup.string()
      .max(
        charLimits.ActionDetails,
        charLimitExceededErrorMessage('Comments', charLimits.ActionDetails),
      )
      .optional(),
  });
  const defaultValues = {
    comment: '',
  };

  const methods = useForm({
    resolver: yupResolver(regenerateTemplateSchema),
    defaultValues: defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    getValues,
    reset,
    clearErrors,
    trigger,
  } = methods;

  const onSubmit = async (data: { comment: string }) => {
    setLoading(true);

    try {
      await updateUpgradeTemplate(
        props.upgrade.id,
        {
          status: 'regenerate-in-progress',
          status_comment: data.comment,
          is_regenerate_pending: false,
        },
        {
          headers: {
            Authorization: `Bearer ${props.account.token}`,
          },
        },
      );
      enqueueSnackbar(
        'Successfully requested Regeneration of Upgrade Template',
        {
          variant: 'success',
          autoHideDuration: toastAutoHideDuration,
        },
      );
      setDialogOpen(false);
      props.onConfirm();
    } catch (error) {
      Sentry.captureException(error);
      enqueueSnackbar(
        somethingWentWrong.replace(
          '<action>',
          'requesting Regeneration of Upgrade Template',
        ),
        { variant: 'error', autoHideDuration: toastAutoHideDuration },
      );
    } finally {
      setLoading(false);
    }
  };
  const resetToDefault = () => {
    reset(defaultValues);
  };
  return (
    <>
      <ActivityFeedbackFrame variant={'default'}>
        <AtomicTooltip
          tooltipContent={
            'Regenerate the Upgrade Template with your feedback. Regenerate when all your feedback is published on all the steps that require changes.'
          }
        >
          <Button
            variant="brand-secondary"
            size="medium"
            icon="FeatherRotateCcw"
            className="flex"
            onClick={(event) => {
              clearErrors();
              resetToDefault();
              setDialogOpen(true);
              event.preventDefault();
            }}
          >
            Regenerate Template
          </Button>
        </AtomicTooltip>
      </ActivityFeedbackFrame>
      <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
        <Dialog.Content>
          <FormProvider
            id={'regenerate-template-dialog-form'}
            methods={methods}
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="flex w-192 grow shrink-0 basis-0 flex-col items-start justify-center gap-6 px-4 py-4">
              <div className="flex w-full items-center justify-between">
                <div className="flex grow shrink-0 basis-0 flex-col items-start gap-2">
                  <span className="w-full text-subheader font-subheader text-default-font">
                    Regenerate Upgrade Template?
                  </span>
                  <span className="text-body font-body text-subtext-color">
                    Regenerate the Upgrade Template with your feedback
                  </span>
                </div>
                <IconButton
                  className="h-8 w-8 flex-none"
                  size="medium"
                  icon="FeatherX"
                  onClick={(event) => {
                    setDialogOpen(false);
                    event.preventDefault();
                  }}
                  disabled={loading}
                />
              </div>
              <div className="flex w-full flex-col items-start justify-center gap-6">
                <Alert
                  variant="warning"
                  title="Before you regenerate this Upgrade Template..."
                  description="Note that the Upgrade Template will become Read-Only until regeneration is completed."
                />
                <div className="flex w-full flex-col items-start justify-center gap-1">
                  <span className="text-body-bold font-body-bold text-subtext-color">
                    Additional Notes (Optional)
                  </span>
                  <TextArea
                    className="h-auto w-full flex-none"
                    label=""
                    helpText={errors.comment?.message}
                    error={methods.getFieldState('comment').invalid}
                  >
                    <TextArea.Input
                      className="h-auto min-h-[96px] w-full flex-none"
                      placeholder={getValues('comment') || ''}
                      {...methods.register('comment')}
                    />
                  </TextArea>
                </div>
              </div>

              <div className="flex w-full items-end justify-end gap-4">
                <Button
                  variant="brand-primary"
                  size="large"
                  icon="FeatherRotateCcw"
                  type="submit"
                  loading={isSubmitting || loading}
                  disabled={isSubmitting || loading}
                  onClick={async () => {
                    const isValid = await trigger();
                    if (isValid) {
                      onSubmit(getValues() as { comment: string });
                    }
                  }}
                >
                  Regenerate Template
                </Button>
              </div>
            </div>
          </FormProvider>
        </Dialog.Content>
      </Dialog>
    </>
  );
}
export default RegenerateTemplateDialog;
