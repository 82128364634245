'use client';
/*
 * Documentation:
 * [DRAFT] Integrations — https://app.subframe.com/library?component=%5BDRAFT%5D+Integrations_a427197f-552b-4b0d-9c57-a8f7017f3f53
 * Breadcrumb — https://app.subframe.com/library?component=Breadcrumb_44b6fbd0-acf9-4944-8c86-0343ed858677
 * Card - CTA - Integration — https://app.subframe.com/library?component=Card+-+CTA+-+Integration_24d22211-65ef-41b6-86d7-ee74e020d3c4
 * Badge — https://app.subframe.com/library?component=Badge_97bdb082-1124-4dd7-a335-b14b822d0157
 * Card - Integration — https://app.subframe.com/library?component=Card+-+Integration_3d97e230-6f19-453f-a715-543b0e892987
 */

import { SyntheticEvent, useState } from 'react';
import { Breadcrumbs } from 'subframe/index';

import { useDebounce } from 'react-use';
import Page from 'components/Page';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { useListIntegrations } from 'api/frontend';
import useUserAccountState from 'hooks/useUserAccountState';
import { allIntegrations } from './model';
import { IntegrationCard } from './components/IntegrationCard';
import RequestSupportDialog from 'pages/explore/RequestSupportDialog';
import { Searchbar } from 'components/design-system/Searchbar';

export default function Integrations() {
  const { logEvent } = AnalyticsEventLogger();

  const { account } = useUserAccountState();
  const [searchInteg, setSearchInteg] = useState('');

  const sortedIntegrations = [
    allIntegrations[0],
    ...allIntegrations.slice(1).sort((a, b) => {
      if (b.name > a.name) {
        return -1;
      }
      return a.name.localeCompare(b.name);
    }),
  ];

  let integsToShow = sortedIntegrations;
  const { data } = useListIntegrations({
    request: {
      headers: { authorization: 'Bearer ' + account?.token },
    },
  });

  const handleOnChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: string,
  ) => {
    setSearchInteg(newValue);
  };

  useDebounce(
    () => {
      if (searchInteg) {
        logEvent('search-integration', { searchInteg });
      }
    },
    1000,
    [searchInteg],
  );

  if (searchInteg !== '') {
    const found = sortedIntegrations.filter((item) =>
      item?.name.toLowerCase().includes(searchInteg.toLowerCase()),
    );
    integsToShow = found;
  } else {
    integsToShow = sortedIntegrations;
  }

  return (
    <Page title="Integrations">
      <div className="h-full flex-col gap-6 items-start flex pl-3 pr-8 pt-8 pb-0 ml-8 bg-default-background">
        <Breadcrumbs>
          <Breadcrumbs.Item>Configure</Breadcrumbs.Item>
          <Breadcrumbs.Divider />
          <Breadcrumbs.Item active={true}>Integrations</Breadcrumbs.Item>
        </Breadcrumbs>
        <div className="w-full flex-col gap-2 items-start flex">
          <div className="w-full flex-col gap-2 items-start flex">
            <span className="text-default-font text-section-header">
              Integrations
            </span>
            <div className="justify-between items-start flex w-[1224px]">
              <span className="text-subtext-color text-label">
                Chkk installs in minutes and integrates into your existing tools
                and workflows
              </span>
              <div>
                <Searchbar onChange={handleOnChange} />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex-col gap-6 items-start flex">
          {integsToShow.length ? (
            <div className="grid grid-cols-[repeat(auto-fill,minmax(384px,1fr))] gap-5 w-[1235px]">
              {integsToShow.map((i) => {
                return (
                  <IntegrationCard
                    key={i.id}
                    id={i.id}
                    integration={i}
                    account={account}
                    data={data}
                  />
                );
              })}
            </div>
          ) : (
            <div className="flex-[1_0_0] h-full w-full flex-col gap-2 items-center justify-center flex pt-[25vh]">
              <div className="w-full gap-1 items-center justify-center flex">
                <span className="text-default-font text-subheader">
                  {`Could not find the Integration "${searchInteg}".`}
                </span>
              </div>
              <div className="w-full gap-1 items-center justify-center flex">
                <span className="text-default-font text-subheader">Click</span>
                <RequestSupportDialog
                  requestResource="Integration"
                  text="here"
                  requestedResourceName={searchInteg}
                  eventTitle={'RequestSupportIntegration'}
                />
                <span className="text-default-font text-subheader">
                  to request this integration
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </Page>
  );
}
