import React, { useState } from 'react';
// hooks
// types
// constants
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Sentry from '@sentry/browser';
import { somethingWentWrong, toastAutoHideDuration } from 'constants/toasts';
import { useSnackbar } from 'notistack';
import {
  SSOConfiguration,
  SSOConfigurationStatus,
  SSOProvider,
} from 'api/models';
import {
  createAccountSSOConfiguration,
  updateAccountSSOConfiguration,
} from 'api/frontend';
import useUserAccountState from 'hooks/useUserAccountState';
import * as SubframeCore from '@subframe/core';
import FormProvider from 'components/FormProvider';
import { CopyToClipboard } from 'components/design-system/CopyToClipboard';
import { SSOConfigurationStatusEnum } from 'pages/Settings/Security';
import {
  Button,
  Checkbox,
  CodeBlock,
  InfoTooltip,
  LearnMoreAccordion,
  StepBase,
  TextArea,
  TextField,
} from 'subframe/index';
import { isValidX509Certificate, isValidXML } from 'utils/inputValidation';
import { fieldIsRequiredErrorMessage } from 'constants/input-validation';

interface Props {
  onSubmit: (status: SSOConfigurationStatus) => void;
  ssoConfig: SSOConfiguration;
}

interface SchemaFields {
  /** The SSO URL for the Okta SAML configuration */
  ssoUrl: string;
  /** The Identity Provider Issuer for the Okta SAML configuration */
  idpIssuer: string;
  /** The latest X.509 certificate for the Okta SAML configuration */
  x509Certificate: string;
  /** The Identity Provider Metadata for the Okta SAML configuration */
  idpMetadata?: string;
}

type ProcessingState = 'pending' | 'in-progress' | 'completed';
const stepProgressToStepVariants = (
  state: ProcessingState,
): 'default' | 'success' | 'loading' | 'error' => {
  if (state === 'in-progress') {
    return 'default';
  }
  if (state === 'completed') {
    return 'success';
  }
  return 'default';
};

interface WorkflowState {
  // processing state
  preReqStep: ProcessingState;
  firstStep: ProcessingState;
  secondStep: ProcessingState;
  thirdStep: ProcessingState;
  fourthStep: ProcessingState;
  preReqOpen?: boolean;
  firstOpen?: boolean;
  secondOpen?: boolean;
  thirdOpen?: boolean;
  fourthOpen?: boolean;
}
const OktaSAML: React.FC<Props> = ({ onSubmit, ssoConfig }) => {
  const { logEvent } = AnalyticsEventLogger();
  const [loading, setLoading] = useState(false);
  const { account, currentOrganization } = useUserAccountState();
  const auth0Tenant = import.meta.env.VITE_AUTH0_TENANT || 'chkk';
  const auth0ConnectionName = currentOrganization.id.substring(4, 12);
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${account.token}`,
  };
  const SubmissionError = somethingWentWrong.replace(
    '<action>',
    'configuring Okta SSO',
  );

  const { enqueueSnackbar } = useSnackbar();
  const ssoConfigSchema = Yup.object().shape({
    ssoUrl: Yup.string()
      .required(fieldIsRequiredErrorMessage('IdP Single Sign-On URL'))
      .url('IdP Single Sign-On URL must be a valid URL'),
    idpIssuer: Yup.string().required(fieldIsRequiredErrorMessage('IdP Issuer')),
    x509Certificate: Yup.string()
      .required(fieldIsRequiredErrorMessage('X.509 Certificate'))
      .test(
        'is-valid-x509',
        'Must be a valid X.509 Certificate',
        async (cert) => (cert ? await isValidX509Certificate(cert) : false),
      ),
    idpMetadata: Yup.string()
      .optional()
      .test('is-valid-xml', 'IDP metadata must be a valid XML', (value) =>
        isValidXML(value),
      ),
  });
  const defaultValues: SchemaFields = {
    ssoUrl: '',
    idpIssuer: '',
    x509Certificate: '',
    idpMetadata: '',
  };
  const setDefaultValues = (config: any) => {
    if (config?.okta_saml) {
      return {
        ssoUrl: config?.okta_saml?.sso_url,
        idpIssuer: config?.okta_saml?.idp_issuer,
        x509Certificate: '',
        idpMetadata: config?.okta_saml?.idp_metadata,
      };
    }
    return defaultValues;
  };

  const [workFlowState, setWorkFlowState] = useState<WorkflowState>({
    preReqStep: 'in-progress',
    firstStep: 'pending',
    secondStep: 'pending',
    thirdStep: 'pending',
    fourthStep: 'pending',
    preReqOpen: true,
    firstOpen: true,
    secondOpen: false,
    thirdOpen: false,
    fourthOpen: false,
  });

  const methods = useForm<SchemaFields>({
    resolver: yupResolver(ssoConfigSchema),
    defaultValues: setDefaultValues(defaultValues),
  });
  const {
    handleSubmit: handleSSOSubmit,
    formState: { isSubmitting, errors, isValid },
    trigger: validateForm,
  } = methods;
  const upsertSSORequest = (data: SchemaFields) => {
    const reqBody: any = {
      provider: SSOProvider['okta-saml'],
      okta_saml: {
        sso_url: data.ssoUrl.trim(),
        idp_issuer: data.idpIssuer.trim(),
        x509_certificate: data.x509Certificate
          .trim()
          .replace(/(-----(BEGIN|END) CERTIFICATE-----|\s)/g, ''),
      },
    };
    if (data.idpMetadata && reqBody.okta_saml) {
      reqBody.okta_saml.idp_metadata = data.idpMetadata.trim();
    }
    return reqBody;
  };
  const submit = async (data: SchemaFields) => {
    try {
      setLoading(true);
      if (ssoConfig?.created) {
        await updateAccountSSOConfiguration('DEFAULT', upsertSSORequest(data), {
          headers,
        });
        logEvent('security-settings-sso-connection-request-updated', {
          provider: SSOProvider['okta-saml'],
        });
      } else {
        await createAccountSSOConfiguration('DEFAULT', upsertSSORequest(data), {
          headers,
        });
        logEvent('security-settings-sso-connection-request-created', {
          provider: SSOProvider['okta-saml'],
        });
      }
      setWorkFlowState((prev) => {
        return {
          ...prev,
          fourthStep: 'completed',
          fourthOpen: false,
        };
      });
      onSubmit(SSOConfigurationStatusEnum.PENDING);
    } catch (error) {
      onSubmit(SSOConfigurationStatusEnum.ERROR);
      Sentry.captureException(error, {
        extra: { data },
      });
      enqueueSnackbar(SubmissionError, {
        variant: 'error',
        autoHideDuration: toastAutoHideDuration,
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <LearnMoreAccordion
        title="Pre-requisites"
        open={workFlowState.preReqOpen}
        onOpenChange={(open) => {
          setWorkFlowState((prev) => {
            return {
              ...prev,
              preReqOpen: !prev.preReqOpen,
              preReqStep: 'completed',
            };
          });
        }}
      >
        <div className="flex w-full grow shrink-0 basis-0 flex-col items-start gap-2">
          <span className="text-body-bold font-body-bold text-default-font">
            Ask your Okta Administrator to set up the following configuration
            for an Okta SAML app integration application
          </span>
          <span className="text-body font-body text-default-font">
            Login to your Okta Admin dashboard. Navigate to
            &#39;Applications&#39; &gt; click &#39;Create an App
            Integration&#39; &gt; choose &#39;SAML 2.0&#39; then click
            &#39;Next&#39; which initiates the app integration wizard.
          </span>
          <div className="flex items-start gap-1">
            <span className="text-body font-body text-default-font">
              Read more on
            </span>
            <StepBase.TextWithLink
              title="App Integration Wizard"
              label=""
              urlTitle="App Integration Wizard"
              url="https://help.okta.com/en-us/content/topics/apps/apps_app_integration_wizard_saml.htm"
            >
              App Integration Wizard
            </StepBase.TextWithLink>
            <span className="text-body font-body text-default-font">
              around setting up a custom app integration.
            </span>
          </div>
        </div>
      </LearnMoreAccordion>
      <StepBase
        stepTitle="General Settings"
        stepNumber="1"
        open={workFlowState.firstOpen}
        onOpenChange={(open) => {
          setWorkFlowState((prev) => {
            return {
              ...prev,
              firstOpen: open,
            };
          });
        }}
        variant={stepProgressToStepVariants(workFlowState.firstStep)}
        actionButtons={
          workFlowState.firstStep !== 'completed' && (
            <Button
              variant="brand-secondary"
              size="medium"
              icon="FeatherCornerDownRight"
              loading={false}
              onClick={() => {
                validateForm();
                setWorkFlowState((prev) => {
                  return {
                    ...prev,
                    firstOpen: false,
                    firstStep: 'completed',
                    secondOpen: true,
                  };
                });
              }}
            >
              Mark as Done
            </Button>
          )
        }
      >
        <div className="flex w-full items-center gap-2">
          <span className="w-48 flex-none text-body-bold font-body-bold text-default-font">
            App name
          </span>
          <CodeBlock className="h-auto grow shrink-0 basis-0">
            Enter a connection name such as acme-chkk
          </CodeBlock>
        </div>
      </StepBase>
      <StepBase
        stepTitle="Configure SAML"
        stepNumber="2"
        open={workFlowState.secondOpen}
        onOpenChange={(open) => {
          setWorkFlowState((prev) => {
            return { ...prev, secondOpen: open };
          });
        }}
        variant={stepProgressToStepVariants(workFlowState.secondStep)}
        actionButtons={
          workFlowState.secondStep !== 'completed' && (
            <Button
              disabled={workFlowState.firstStep === 'pending'}
              variant="brand-secondary"
              size="medium"
              icon="FeatherCornerDownRight"
              loading={false}
              onClick={() => {
                validateForm();
                setWorkFlowState((prev) => {
                  return {
                    ...prev,
                    secondOpen: false,
                    secondStep: 'completed',
                    thirdOpen: true,
                  };
                });
              }}
            >
              Mark as Done
            </Button>
          )
        }
      >
        <span className="text-body font-body text-default-font">
          Populate following attributes in your application&#39;s SAML Settings
        </span>
        <div className="flex w-full flex-col items-start gap-3 pt-2">
          <span className="w-full text-label-bold font-label-bold text-default-font">
            General
          </span>
          <div className="flex w-full items-center gap-2">
            <span className="w-48 flex-none text-body-bold font-body-bold text-default-font">
              Single sign-on URL
            </span>
            <CopyToClipboard
              className="h-auto grow shrink-0 basis-0"
              text={`https://auth.us-east-1.aws.chkk.io/login/callback?connection=${auth0ConnectionName}`}
            />
          </div>
          <div className="flex w-full items-center gap-2">
            <span className="w-48 flex-none text-body-bold font-body-bold text-default-font">
              Enable Checkbox
            </span>
            <div className="flex grow shrink-0 basis-0 items-center bg-neutral-50">
              <div className="flex grow shrink-0 basis-0 items-center">
                <div className="flex items-center pl-2">
                  <Checkbox checked label={''} disabled={true} />
                </div>
                <CodeBlock className="h-auto grow shrink-0 basis-0">
                  &quot;Use this for Recipient URL and Destination URL&quot;
                </CodeBlock>
              </div>
            </div>
          </div>
          <div className="flex w-full items-center gap-2">
            <span className="w-48 flex-none text-body-bold font-body-bold text-default-font">
              Audience URI (SP Entity ID)
            </span>
            <CopyToClipboard
              text={`urn:auth0:${auth0Tenant}:${auth0ConnectionName}`}
            />
          </div>
          <div className="flex w-full items-center gap-2">
            <span className="w-48 flex-none text-body-bold font-body-bold text-default-font">
              Name ID Format
            </span>
            <CodeBlock className="h-auto grow shrink-0 basis-0">
              EmailAddress
            </CodeBlock>
          </div>
          <div className="flex w-full items-center gap-2">
            <span className="w-48 flex-none text-body-bold font-body-bold text-default-font">
              Application Username
            </span>
            <CodeBlock className="h-auto grow shrink-0 basis-0">
              Okta username
            </CodeBlock>
          </div>
        </div>
        <div className="flex w-full flex-col items-start gap-3 pt-2">
          <span className="w-full text-label-bold font-label-bold text-default-font">
            Attribute Statements
          </span>
          <div className="flex w-full flex-col items-start gap-3 pt-1">
            <span className="w-full text-body font-body text-default-font">
              Copy following attributes to Okta SAML app integration wizard as
              per their &#39;Name&#39; and &#39;Value&#39; specified below.
              Click &#39;Add Another&#39; to add more attributes.
            </span>
            <div className="flex w-full items-center gap-2">
              <span className="text-body-bold font-body-bold text-default-font">
                1.
              </span>
              <CopyToClipboard
                className="h-auto grow shrink-0 basis-0"
                text="FirstName"
              />
              <SubframeCore.Icon
                className="text-body font-body text-default-font"
                name="FeatherArrowRight"
              />
              <CopyToClipboard
                className="h-auto grow shrink-0 basis-0"
                text="user.firstName"
              />
            </div>
            <div className="flex w-full items-center gap-2">
              <span className="text-body-bold font-body-bold text-default-font">
                2.
              </span>
              <CopyToClipboard
                className="h-auto grow shrink-0 basis-0"
                text="LastName"
              />
              <SubframeCore.Icon
                className="text-body font-body text-default-font"
                name="FeatherArrowRight"
              />
              <CopyToClipboard
                className="h-auto grow shrink-0 basis-0"
                text="user.lastName"
              />
            </div>
            <div className="flex w-full items-center gap-2">
              <span className="text-body-bold font-body-bold text-default-font">
                3.
              </span>
              <CopyToClipboard
                className="h-auto grow shrink-0 basis-0"
                text="DisplayName"
              />
              <SubframeCore.Icon
                className="text-body font-body text-default-font"
                name="FeatherArrowRight"
              />
              <CopyToClipboard
                className="h-auto grow shrink-0 basis-0"
                text="user.displayName"
              />
            </div>
          </div>
        </div>
      </StepBase>
      <StepBase
        stepTitle="Finish Setup"
        stepNumber="3"
        open={workFlowState.thirdOpen}
        onOpenChange={(open) => {
          setWorkFlowState((prev) => {
            return { ...prev, thirdOpen: open };
          });
        }}
        variant={stepProgressToStepVariants(workFlowState.thirdStep)}
        actionButtons={
          workFlowState.thirdStep !== 'completed' && (
            <Button
              disabled={workFlowState.secondStep === 'pending'}
              variant="brand-secondary"
              size="medium"
              icon="FeatherCornerDownRight"
              loading={false}
              onClick={() => {
                setWorkFlowState((prev) => {
                  return {
                    ...prev,
                    thirdStep: 'completed',
                    thirdOpen: false,
                    fourthOpen: true,
                  };
                });
              }}
            >
              Mark as Done
            </Button>
          )
        }
      >
        <span className="text-body font-body text-default-font">
          At the final step of the Okta App Integration Wizard, you will find
          questions designed to help Okta Support better understand this app
          integration. You may choose the following options
        </span>
        <div className="flex w-full items-center gap-2">
          <span className="w-48 flex-none text-body-bold font-body-bold text-default-font">
            Select Radio Button
          </span>
          <div className="flex grow shrink-0 basis-0 items-center bg-neutral-50">
            <CodeBlock className="h-auto grow shrink-0 basis-0">
              I&#39;m an Okta customer adding an internal app
            </CodeBlock>
          </div>
        </div>
        <div className="flex w-full items-center gap-2">
          <span className="w-48 flex-none text-body-bold font-body-bold text-default-font">
            Enable Checkbox
          </span>
          <div className="flex grow shrink-0 basis-0 items-center bg-neutral-50">
            <CodeBlock className="h-auto grow shrink-0 basis-0">
              Contact App Vendor: “It&#39;s required to contact the vendor to
              enable SAML”
            </CodeBlock>
          </div>
        </div>
        <span className="text-body font-body text-default-font">
          On clicking &#39;Finish&#39; in Okta app integration wizard,
          you&#39;ll be directed to the Sign On page for your newly-created app.
          Click View Setup Instructions button to complete the process.{' '}
        </span>
        <span className="text-body font-body text-default-font">
          Note the IdP Single Sign-On URL, IdP Issuer, IdP metadata, and
          download a copy of the X.509 certificate which you will need for Step
          4 below.
        </span>
      </StepBase>
      <FormProvider
        methods={methods}
        onSubmit={handleSSOSubmit(submit)}
        style={{ width: '100%' }}
      >
        <StepBase
          stepTitle="Retrieve SAML setup instructions from Okta App"
          lastStep={true}
          stepNumber="4"
          open={workFlowState.fourthOpen}
          onOpenChange={(open) => {
            setWorkFlowState((prev) => {
              return { ...prev, fourthOpen: open };
            });
          }}
          variant={stepProgressToStepVariants(workFlowState.fourthStep)}
        >
          <div className="flex w-full flex-col items-start gap-3">
            <span className="text-body font-body text-default-font">
              Populate following information from the View SAML Instructions
              page below
            </span>
            <div className="flex w-full items-center gap-2">
              <span className="w-48 flex-none text-body-bold font-body-bold text-default-font">
                IdP Single Sign-On URL
              </span>
              <TextField
                className="h-auto grow shrink-0 basis-0"
                error={methods.getFieldState('ssoUrl').invalid}
                label=""
                helpText={errors?.ssoUrl?.message}
              >
                <TextField.Input {...methods.register('ssoUrl')} />
              </TextField>
            </div>
            <div className="flex w-full items-center gap-2">
              <span className="w-48 flex-none text-body-bold font-body-bold text-default-font">
                IdP Issuer
              </span>
              <TextField
                className="h-auto grow shrink-0 basis-0"
                error={methods.getFieldState('idpIssuer').invalid}
                helpText={errors?.idpIssuer?.message}
              >
                <TextField.Input {...methods.register('idpIssuer')} />
              </TextField>
            </div>
            <div className="flex w-full items-start gap-2">
              <div className="flex w-48 flex-none items-center gap-2">
                <span className="text-body-bold font-body-bold text-default-font">
                  X.509 Certificate
                </span>
                <InfoTooltip
                  tooltipText="Provide the most recently created SAML signing
                      certificate here"
                />
              </div>
              <TextArea
                className="h-auto grow shrink-0 basis-0"
                error={methods.getFieldState('x509Certificate').invalid}
                helpText={errors?.x509Certificate?.message}
              >
                <TextArea.Input
                  className="h-auto min-h-[96px] w-full flex-none"
                  {...methods.register('x509Certificate')}
                />
              </TextArea>
            </div>
            <div className="flex w-full items-center gap-2">
              <div className="flex grow shrink-0 basis-0 items-start gap-2">
                <span className="w-48 flex-none text-body-bold font-body-bold text-default-font">
                  IDP metadata
                </span>
                <TextArea
                  className="h-auto grow shrink-0 basis-0"
                  label=""
                  error={methods.getFieldState('idpMetadata').invalid}
                  helpText={errors?.idpMetadata?.message}
                >
                  <TextArea.Input
                    className="h-auto min-h-[96px] w-full flex-none"
                    {...methods.register('idpMetadata')}
                  />
                </TextArea>
              </div>
            </div>
          </div>
        </StepBase>
      </FormProvider>
      <div className="flex w-full flex-col items-start justify-center">
        <Button
          icon="FeatherPlus"
          loading={loading}
          disabled={loading || isSubmitting}
          onClick={() => {
            if (methods.formState.isValid) {
              handleSSOSubmit(submit)();
            } else {
              validateForm();
              setWorkFlowState((prev) => {
                return {
                  ...prev,
                  fourthOpen: true,
                  fourthStep: 'pending',
                };
              });
            }
          }}
        >
          Add SAML Connection
        </Button>
      </div>
    </>
  );
};

export default OktaSAML;
