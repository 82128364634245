import { StepBase } from 'subframe/index';

export const SystemRequirementsOpenShift = () => {
  return (
    <div data-cy="system-requirements" style={{ display: 'contents' }}>
      <StepBase.BulletedLine>{'Kubernetes >= v1.27'}</StepBase.BulletedLine>
      <StepBase.BulletedLine>{'OpenShift >= v1.14'}</StepBase.BulletedLine>
      <StepBase.BulletedLine>
        {'OS/Architecture: linux/amd64, linux/arm64'}
      </StepBase.BulletedLine>
      <StepBase.BulletedLine>{'oc >= v1.14'}</StepBase.BulletedLine>
      <StepBase.BulletedLine>{'Helm: >= version 2'}</StepBase.BulletedLine>
    </div>
  );
};
