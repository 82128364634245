import * as SubframeCore from '@subframe/core';
import { DropdownMenu, IconButton } from 'subframe/index';
import { ChangeNameDialog } from '../helpers/ChangeNameDialog';
import { UpgradePlan, UpgradeTemplate } from 'api/models';
import UpgradesCancelDialog from '../helpers/CancelEntryDialog';
import SkipUpgradeAddonDialog from 'pages/upgrades/components/SkipUpgradeAddonDialog';

export function UpgradesMoreOptions({
  isTemplate,
  upgrade,
  onConfirmEditName,
  allowCancel,
  onConfirmCancel,
  onSkip,
}: {
  isTemplate?: boolean;
  upgrade: UpgradeTemplate | UpgradePlan;
  onConfirmEditName: (newName: string) => void;
  allowCancel?: boolean;
  onConfirmCancel: () => void;
  onSkip: () => void;
}): JSX.Element {
  const isAddOnUpgradePlan =
    upgrade.resources.addons?.length ||
    upgrade.resources.addon_instances?.length;
  return (
    <div
      style={{
        display: 'contents',
      }}
    >
      <SubframeCore.DropdownMenu.Root>
        <SubframeCore.DropdownMenu.Trigger asChild={true}>
          <IconButton
            icon="FeatherMoreVertical"
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {}}
          />
        </SubframeCore.DropdownMenu.Trigger>
        <SubframeCore.DropdownMenu.Portal>
          <SubframeCore.DropdownMenu.Content
            side="bottom"
            align="start"
            sideOffset={4}
            asChild={true}
          >
            <DropdownMenu>
              <ChangeNameDialog
                isTemplate={isTemplate}
                name={upgrade.name}
                onConfirm={onConfirmEditName}
              />
              {!isTemplate &&
                upgrade.status === 'in-progress' &&
                isAddOnUpgradePlan && (
                  <SkipUpgradeAddonDialog
                    isFromLandingPage={true}
                    upgradeId={upgrade.id}
                    mutatePlan={onSkip}
                  ></SkipUpgradeAddonDialog>
                )}
              {allowCancel && (
                <div className="flex h-px w-full flex-none flex-col items-center gap-2 bg-neutral-border" />
              )}
              {allowCancel && (
                <UpgradesCancelDialog
                  isTemplate={isTemplate}
                  onConfirm={onConfirmCancel}
                />
              )}
            </DropdownMenu>
          </SubframeCore.DropdownMenu.Content>
        </SubframeCore.DropdownMenu.Portal>
      </SubframeCore.DropdownMenu.Root>
    </div>
  );
}

export default UpgradesMoreOptions;
