import { useCallback } from 'react';
// @ts-ignore
import { react } from '02strich-markdown';

import '../../../node_modules/02strich-markdown/dist/main.css';
import styles from './LatestPageKbaDetailsTab.module.scss';
import markdownStyles from './useMarkdownParser.module.scss';
import './markdown.css';
import classNames from 'classnames';
import { captureException } from '@sentry/browser';
import { CopyToClipboard } from 'components/design-system/CopyToClipboard';
import { StepBase } from 'subframe/index';

// for known type readme objects used by custom parser methods
type NodeChild = {
  type: string;
  value?: string;
  url?: string;
  title?: string;
  children?: NodeChild[];
};

export const useMarkdownParser = () => {
  const processDefaultNode = useCallback((node) => {
    return (
      <div
        className={classNames(
          'markdown-body',
          node.type === 'break' ? '' : markdownStyles['inlineDiv'],
        )}
      >
        {react(node)}
      </div>
    );
  }, []);

  const processHeadingNode = useCallback((node) => {
    const children = node.children?.map((child: NodeChild) => {
      try {
        if (child.type === 'text') {
          return child.value;
        } else if (child.type === 'link') {
          return (
            <>
              {' '}
              <a
                href={child.url}
                key={child.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {child.children?.length ? child.children[0].value || '' : ''}
              </a>
            </>
          );
        }
      } catch (err) {
        captureException('KBA markdown Parsing error: ' + err);
      }
    });

    return (
      <span className={styles['subheaderText']} key={JSON.stringify(node)}>
        {children}
      </span>
    );
  }, []);

  const processParagraphNode = useCallback((node) => {
    const children = node.children.map((child: NodeChild) => {
      try {
        if (child.type === 'text' || child.type === 'escape') {
          return child.value;
        } else if (child.type === 'link') {
          return (
            <a
              className={markdownStyles['link']}
              href={child.url}
              key={child.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {child.children?.length ? child.children[0].value || '' : ''}
            </a>
          );
        } else {
          return processDefaultNode(child);
        }
      } catch (err) {
        captureException('KBA markdown Parsing error: ' + err);
      }
    });
    return (
      <span className={styles['bodyText']} key={JSON.stringify(node)}>
        {children}
      </span>
    );
  }, []);

  const processListNode = useCallback(
    (node) => {
      const children = node.children?.map((child: NodeChild, idx) => {
        return (
          <StepBase.BulletedLine key={idx} className="pl-0">
            {child.children?.length && processParagraphNode(child.children[0])}
          </StepBase.BulletedLine>
        );
      });
      return (
        <span className={styles['bodyText']} key={JSON.stringify(node)}>
          <div style={{ display: 'contents' }}>{children}</div>
        </span>
      );
    },
    [processParagraphNode],
  );

  const processCodeNode = useCallback((node) => {
    return <CopyToClipboard text={node.value} />;
  }, []);
  const processNode = useCallback(
    (node) => {
      switch (node.type) {
        case 'paragraph':
          return processParagraphNode(node);
        case 'code':
          return processCodeNode(node);
        case 'list':
          return processListNode(node);
        case 'heading':
          return processHeadingNode(node);
        default:
          return processDefaultNode(node);
      }
    },
    [
      processParagraphNode,
      processHeadingNode,
      processDefaultNode,
      processListNode,
    ],
  );

  const parse = useCallback(
    (nodes) => {
      return nodes.map(processNode);
    },
    [processNode],
  );

  return { parse };
};
