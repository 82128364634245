'use client';
/* Release: 63880259 (Latest – unreleased) */

import * as SubframeCore from '@subframe/core';
import { useEffect, useState } from 'react';
import { Switch } from 'subframe/components/Switch';
import { InformationalHeader } from 'subframe/components/InformationalHeader';
import styles from './AcmeVersionRegisterAddOnsCertManagerV112.module.scss';
import { useParams } from 'react-router';
import useUserAccountState from 'hooks/useUserAccountState';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import useLocalStorage from 'hooks/useLocalStorage';
import { useAddonIdToDetailsMap } from 'hooks/useAddonIdToNameMap';
import {
  useListAddonInstancesInfinite,
  useListClustersInfinite,
} from 'api/frontend-infinite';
import { AddonInstance, Cluster } from 'api/models';
import {
  ADDONS,
  getAddonExpiryInWords,
  getAddonExpiryState,
  getHumanReadableImageTag,
} from 'src/data/version_register_addons';
import { clusterFlattenPages, flattenPages } from 'utils/arrays';
import Page from 'components/Page';
import { RouterLink } from 'components/RouterLink';
import { AtomicTooltip } from 'components/design-system';
import { Alert, Breadcrumbs, SkeletonText } from 'subframe/index';
import { ConsoleLoader } from 'components/Loader';
import BaseTable from 'components/design-system/Table/BaseTable';
import { CLUSTERS } from 'src/data/version_register_cp';
import { ExpiryBadge } from './utils';
import { useSearchParams } from 'react-router-dom';
import { Select } from 'subframe/components/Select';
import { getClusterProvider, getClusterRegion } from 'utils/clusterStates';
import { fNumber } from 'utils/formatNumber';

export default function VersionRegisterAddonsViewTag() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { addonId, version } = useParams();
  const { account } = useUserAccountState();
  const basePath = useAccountIdRoute('/orgs/:orgId/accounts/:accountId');
  const versionRegisterAddonBasePath = `${basePath}/artifact_register/addons`;
  const { logEvent } = AnalyticsEventLogger();
  const [showExampleData, setShowExampleData] = useLocalStorage<boolean>(
    'example-data',
    true,
  );
  const [filterCluster, setFilterCluster] = useState<string | undefined>(
    searchParams.get('cluster') || undefined,
  );

  const humanReadableVersion =
    version === undefined ? undefined : decodeURIComponent(version);

  const addonIdToDetailsMap = useAddonIdToDetailsMap({ token: account.token });
  const {
    data: listAddonInstancesResponse,
    isLoading: isLoadingAddonInstances,
  } = useListAddonInstancesInfinite(
    { filter: ['status:active'], addon_id: addonId, cluster_id: filterCluster },
    { request: { headers: { Authorization: `Bearer ${account.token}` } } },
  );

  const { data: clustersList, isLoading: isloadingClustersList } =
    useListClustersInfinite(
      '',
      { status: 'active' },
      {
        request: { headers: { Authorization: `Bearer ${account?.token}` } },
      },
      {
        initialSize: 100,
        revalidateFirstPage: false,
      },
    );

  const listClustersResponse = clusterFlattenPages(clustersList || []) || [];

  const realClusterData = listClustersResponse
    ? listClustersResponse?.filter(
        (c) => c.internal_k8s_ref !== 'example-cluster-ref',
      )
    : [];
  useEffect(() => {
    if (listClustersResponse && localStorage.getItem('example-data') === null) {
      setShowExampleData(!realClusterData.length);
    }
  }, [listClustersResponse]);

  const clusterData = showExampleData ? CLUSTERS : realClusterData;
  const clusterIdToDetailsMap: Map<string, Cluster> = new Map(
    clusterData.map((c) => [c.id, c]),
  );
  const data: AddonInstance[] = showExampleData
    ? ADDONS
    : flattenPages(listAddonInstancesResponse)?.filter(
        (c) => clusterIdToDetailsMap.get(c.cluster_id)?.status === 'active',
      ) || [];

  const tagInstances = data.filter(
    (a) =>
      a.addon_id == addonId &&
      getHumanReadableImageTag(a.primary_component?.image) ===
        humanReadableVersion,
  );
  const instances = tagInstances.filter(
    (instance) =>
      filterCluster === undefined || instance.cluster_id === filterCluster,
  );

  const clusterIdsWithAddons: Set<string> = new Set(
    tagInstances.map((a) => a.cluster_id),
  );
  const addonName = addonIdToDetailsMap.get(addonId || '')?.name || addonId;

  const expiryState = getAddonExpiryState(instances);

  useEffect(() => {
    if (addonName && addonId && instances.length) {
      logEvent('version-register-view-addon-tag', {
        addon_id: addonId,
        addon_name: addonName,
        tag: humanReadableVersion,
        num_instances: instances.length,
      });
    }
  }, []);

  const isDataLoading =
    !showExampleData &&
    (listAddonInstancesResponse === undefined ||
      isloadingClustersList ||
      isLoadingAddonInstances);

  return (
    <Page title={`Add-ons - ${addonName}`}>
      <div
        className={styles['pageContents']}
        style={{ marginLeft: '32px', width: 'calc(100% - 32px)' }}
      >
        <Breadcrumbs>
          <Breadcrumbs.Item>Artifact Register</Breadcrumbs.Item>
          <Breadcrumbs.Divider />
          <RouterLink
            to={`${versionRegisterAddonBasePath}${
              filterCluster === undefined ? '' : '?cluster=' + filterCluster
            }`}
          >
            <Breadcrumbs.Item data-cy="breadcrumb-addon">
              Add-ons
            </Breadcrumbs.Item>
          </RouterLink>
          <Breadcrumbs.Divider />
          <RouterLink
            to={`${versionRegisterAddonBasePath}/${addonId}${
              filterCluster === undefined ? '' : '?cluster=' + filterCluster
            }`}
          >
            <Breadcrumbs.Item data-cy="breadcrumb-addon-type">
              {addonIdToDetailsMap.size > 0 ? (
                addonName
              ) : (
                <SkeletonText className={'h-[20px] w-[50px]'} />
              )}{' '}
            </Breadcrumbs.Item>
          </RouterLink>
          <Breadcrumbs.Divider />
          <Breadcrumbs.Item active={true}>
            {humanReadableVersion}
          </Breadcrumbs.Item>
        </Breadcrumbs>

        <>
          <div className={styles['header']}>
            <div className={styles['stack-6106a76a']}>
              <div className={styles['stack-3430095f']}>
                <span className={styles['sectionHeaderText']}>
                  <div className="flex gap-2">
                    {addonIdToDetailsMap.size > 0 ? (
                      addonName
                    ) : (
                      <SkeletonText className={'h-[38px] w-[50px]'} />
                    )}{' '}
                    {humanReadableVersion}
                  </div>
                </span>

                {instances.length > 0 &&
                  instances[0].eol_date !== undefined &&
                  instances[0].eol_date > 0 && (
                    <ExpiryBadge
                      expiryState={expiryState}
                      expiryInWords={getAddonExpiryInWords(instances)}
                      eolSource={
                        addonIdToDetailsMap.get(addonId || '')?.eolSource
                      }
                    />
                  )}
              </div>
              <div className={styles['stack-fb907321']}>
                <span className={styles['text-6cec16ae']}>
                  <div className="flex gap-2">
                    Clusters running{' '}
                    {addonIdToDetailsMap.size > 0 ? (
                      addonName
                    ) : (
                      <SkeletonText className={'h-[20px] w-[50px]'} />
                    )}{' '}
                    {humanReadableVersion}
                  </div>
                </span>
                <div className={styles['stack-9a7e524a']}>
                  <span className={styles['text-a2b114ca']}>
                    Show example data
                  </span>
                  <AtomicTooltip
                    tooltipContent={
                      showExampleData
                        ? 'Show results from your onboarded clusters'
                        : 'Show example data'
                    }
                  >
                    <Switch
                      checked={showExampleData}
                      onClick={() => setShowExampleData(!showExampleData)}
                    />
                  </AtomicTooltip>
                </div>
              </div>
            </div>
          </div>
          {showExampleData && (
            <Alert
              variant="warning"
              title="You are currently viewing example data"
              description={`To view results from your onboarded clusters, toggle the "Show example data" button`}
            />
          )}
          {!showExampleData && listClustersResponse === undefined && (
            <ConsoleLoader />
          )}
          <>
            <div className={styles['stack-44d20649']}>
              <InformationalHeader className="flex-[0_0_auto] h-auto w-auto">
                <div className={styles['stack-9314591d']}>
                  <span className={styles['version-6cb1c4c5']}>Total</span>
                  <span
                    className={styles['bodyBoldText']}
                    data-cy="view-tag-total"
                  >
                    {isDataLoading ? (
                      <SkeletonText className="h-[20px] w-[20px]" />
                    ) : (
                      fNumber(instances.length)
                    )}
                  </span>
                </div>
                {addonIdToDetailsMap.get(addonId || '')?.eolSource !==
                  undefined && (
                  <InformationalHeader.Item
                    title="EOL Source"
                    iconName={null}
                    headingSlot={<></>}
                    bodySlot={
                      <a
                        data-cy="eol-source"
                        style={{
                          color: 'var(--subtext-color)',
                          font: 'var(--body)',
                        }}
                        href={addonIdToDetailsMap.get(addonId || '')?.eolSource}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Learn more
                      </a>
                    }
                  />
                )}
              </InformationalHeader>
              <div className={styles['stack-658d572b']}>
                {showExampleData || !isloadingClustersList ? (
                  <Select
                    icon={null}
                    className="w-auto max-w-[400px]"
                    placeholder={
                      filterCluster === undefined
                        ? 'All Clusters'
                        : clusterIdToDetailsMap.get(filterCluster)?.name ||
                          'Unknown'
                    }
                    onValueChange={(value) => {
                      if (value === 'all') {
                        setSearchParams({});
                        setFilterCluster(undefined);
                      } else {
                        setSearchParams({ cluster: value });
                        setFilterCluster(value);
                      }
                    }}
                  >
                    <Select.Item key="all" value="all">
                      All Clusters
                    </Select.Item>
                    {Array.from(clusterIdsWithAddons).map((clusterId) => (
                      <Select.Item key={clusterId} value={clusterId}>
                        {clusterIdToDetailsMap.get(clusterId)?.name ||
                          'Unknown'}
                      </Select.Item>
                    ))}
                  </Select>
                ) : (
                  <SkeletonText className="h-[30px] w-[150px] flex-none" />
                )}
              </div>
            </div>
            {isDataLoading ? (
              <BaseTable
                settings={{
                  localStorageKey: 'artifact_register_addons_view_tag_table',
                  dataCyPrefix: 'vr-addon-view-tag',
                }}
                data={[
                  <SkeletonText key="1" className={'h-[30px] w-[50px]'} />,
                ]}
                columns={[
                  {
                    id: 'cluster',
                    title: 'Cluster',
                    titleStyle: { width: '40%' },
                    cellType: 'cell',
                    render: (skeletonLoading) => {
                      return skeletonLoading;
                    },
                  },
                  {
                    id: 'environment',
                    title: 'Environment',
                    titleStyle: { width: '30%' },
                    cellType: 'cell',
                    render: (skeletonLoading) => {
                      return skeletonLoading;
                    },
                  },
                  {
                    id: 'provider',
                    title: 'Provider / Region',
                    titleStyle: { width: '30%' },
                    cellType: 'cell',
                    render: (skeletonLoading) => {
                      return skeletonLoading;
                    },
                  },
                ]}
                actions={[]}
              />
            ) : (
              <BaseTable
                settings={{
                  localStorageKey: 'artifact_register_addons_view_tag_table',
                  dataCyPrefix: 'vr-addon-view-tag',
                }}
                rowLink={(instance) =>
                  `${versionRegisterAddonBasePath}/${instance.addon_id}/${instance.id}`
                }
                data={instances}
                columns={[
                  {
                    id: 'cluster',
                    title: 'Cluster',
                    titleStyle: { width: '40%' },
                    cellType: 'text-cell',
                    cellProperties: { primary: true },
                    render: (row: AddonInstance) => {
                      return (
                        clusterIdToDetailsMap.get(row.cluster_id)?.name ||
                        row.cluster_id
                      );
                    },
                  },
                  {
                    id: 'environment',
                    title: 'Environment',
                    titleStyle: { width: '30%' },
                    cellType: 'badge-cell',
                    cellProperties: {
                      variant: (row) => {
                        return 'neutral';
                      },
                    },
                    render: (row: AddonInstance) => {
                      return (
                        clusterIdToDetailsMap.get(row.cluster_id)
                          ?.environment || 'default'
                      );
                    },
                  },
                  {
                    id: 'provider',
                    title: 'Provider / Region',
                    titleStyle: { width: '30%' },
                    cellType: 'text-cell',
                    render: (row: AddonInstance) => {
                      const details = clusterIdToDetailsMap.get(row.cluster_id);

                      if (details === undefined) {
                        return 'n/a';
                      } else {
                        return `${getClusterProvider(
                          details,
                        )} / ${getClusterRegion(details)}`;
                      }
                    },
                  },
                ]}
                actions={[
                  {
                    id: 'navigate',
                    render: () => {
                      return (
                        <SubframeCore.Icon
                          className={styles['bodyText']}
                          name="FeatherChevronRight"
                        />
                      );
                    },
                  },
                ]}
              />
            )}
          </>
        </>
      </div>
    </Page>
  );
}
